import * as Yup from "yup";
import { CreateProposalFormType } from "./CreateProposalForm";

export const CreateProposalValidationSchema =
  (): Yup.SchemaOf<CreateProposalFormType> => {
    return Yup.object().shape({
      title: Yup.string().max(30).required("Name is required"),
      targetAddress: Yup.string().default(""),
      function: Yup.string().max(30).required("Address is required"),
      params: Yup.array(),
      transactionValue: Yup.string().default(""),
    });
  };
