import { FileWithPath, useDropzone } from "react-dropzone";
import { useUiText } from "../../system/hooks/useUiText";
import { Box, ListItem, OrderedList, Text } from "@chakra-ui/react";

export type CsvUploadInputProps = {
  setFile: (file: FileWithPath) => void;
  csvArray: string[];
};

export const CsvUploadInput = ({ setFile, csvArray }: CsvUploadInputProps) => {
  const { uiText } = useUiText();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    accept:
      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
    maxFiles: 1,
  });

  return (
    <>
      <Box
        border={`1px ${!!acceptedFiles[0] ? "solid" : "dashed"} #939393`}
        borderRadius={"4px"}
        margin={"30px"}
        padding={"30px"}
        cursor="pointer"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!acceptedFiles[0] && (
          <Text fontWeight="bold" mb="5px">
            {uiText.allowListCsvUploadMessage}
          </Text>
        )}
        {acceptedFiles && (
          <Text fontWeight="bold">{acceptedFiles[0]?.name}</Text>
        )}

        {csvArray && (
          <OrderedList maxH="100px" overflowY="scroll">
            {csvArray.map((address, i) => (
              <ListItem
                key={address + i}
                w="250px"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {address}
              </ListItem>
            ))}
          </OrderedList>
        )}
      </Box>
    </>
  );
};
