import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import { WalletProvider } from "./system/context/WalletContext";
import { ConnectWalletProvider } from "./system/context/ConnectWalletContext";
import { AuthenticateFreeProvider } from "./system/context/AuthenticateFreeContext";
import { ModalProvider } from "./system/context/ModalContext";
import { LEGACYContractProvider } from "./system/context/LEGACYContractContext";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/montserrat/400.css";
import "@fontsource/open-sans/400.css";
import { CollectorProvider } from "./system/context/CollectorContext";
import { ApiProvider } from "./system/api/ApiContext";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ConnectWalletProvider>
        <WalletProvider>
          <LEGACYContractProvider>
            <ApiProvider>
              <AuthenticateFreeProvider>
                <BrowserRouter>
                  <CollectorProvider>
                    <ModalProvider>
                      <ColorModeScript
                        initialColorMode={theme.config.initialColorMode}
                      />
                      <App />
                    </ModalProvider>
                  </CollectorProvider>
                </BrowserRouter>
              </AuthenticateFreeProvider>
            </ApiProvider>
          </LEGACYContractProvider>
        </WalletProvider>
      </ConnectWalletProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
