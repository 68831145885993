import { Box, Flex, HStack, Text, Tooltip, Image } from "@chakra-ui/react";
import { PrimaryButton } from "../../../components/buttons";
import React, { useContext } from "react";
import { ModalContext } from "../../../system/context/ModalContext";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_IMAGE_PLACEHOLDER_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { scrollBarStyles } from "../../../theme/theme";
import { MyCollectionsSectionProps } from "./types";
import { Link } from "react-router-dom";
import { AuthenticateFreeContext } from "../../../system/context/AuthenticateFreeContext";
import { DeployButton } from "./DeployButton";
import { Collection } from "../../../system/api/types";
import { WalletContext } from "../../../system/context/WalletContext";

export const MyCollectionsSection = ({
  collections,
  setCollection,
  hasReceipt,
  goToCollectionError,
}: MyCollectionsSectionProps) => {
  const { uiText } = useUiText();
  const { hasFreeLicence } = useContext(AuthenticateFreeContext);
  const { switchNetwork, chainId } = useContext(WalletContext);

  const { noReceiptsModal, walletModal } = React.useContext(ModalContext);

  const handleSelectCollection = (id: string, collections: Collection[]) => {
    const collectionNetwork = collections.find((c) => c.id === id)?.network;
    if (parseInt(collectionNetwork || "") !== chainId) {
      switchNetwork(collectionNetwork || "");
      return;
    }
    setCollection(id, collections);
  };

  const renderCreateNewCollection = () => {
    if (hasReceipt || hasFreeLicence) {
      return (
        <Link
          to="/create-collection"
          style={{ textDecoration: "none" }}
          onClick={() => walletModal.onClose()}
        >
          <PrimaryButton text={uiText.startNewCollection} />
        </Link>
      );
    } else {
      return (
        <PrimaryButton
          text={uiText.startNewCollection}
          onClick={() => {
            walletModal.onClose();
            noReceiptsModal.onOpen();
          }}
        />
      );
    }
  };
  return (
    <Box
      h={"100%"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
    >
      <Flex alignItems="center" h="100%" justifyContent={"center"} flexDir="column">
        {!collections.length && (
          <Text color={APP_TEXT_INACTIVE_COLOR}>
            {uiText.noCollectionsMessage}
          </Text>
        )}
        {!!collections.length && (
          <HStack
            overflow="auto"
            maxW="100%"
            sx={scrollBarStyles}
            paddingBottom={"10px"}
          >
            {collections.map((c, index) => (
              <Flex
                flexDir="column"
                onClick={() =>
                  c.deployStatus === "PROCESSING" || !c.contractAddress
                    ? null
                    : handleSelectCollection(c.id, collections)
                }
                key={`collection-${c.id}`}
                _hover={{
                  opacity: !c.contractAddress ? 1 : 0.7,
                  cursor: "pointer",
                }}
              >
                <Box position={"relative"}>
                  <Image
                    h={"236px"}
                    minW={"210px"}
                    objectFit={"cover"}
                    borderRadius={"5px"}
                    bgColor={APP_IMAGE_PLACEHOLDER_COLOR}
                    src={c.collectionImage}
                  />
                  {!c.contractAddress && (
                    <Box
                      top={0}
                      h={"236px"}
                      minW={"210px"}
                      borderRadius={"5px"}
                      bg="rgba(18, 18, 18, 0.5)"
                      display={"flex"}
                      position="absolute"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <DeployButton
                        collection={c}
                        goToCollectionError={goToCollectionError}
                      />
                    </Box>
                  )}
                </Box>
                <Text
                  fontFamily={"heading"}
                  mt={"15px"}
                  fontSize="10px"
                  fontWeight={"medium"}
                >
                  {c.symbol}
                </Text>
                <Text
                  fontFamily={"heading"}
                  fontSize="14px"
                  fontWeight="semibold"
                >
                  {c.name}
                </Text>
              </Flex>
            ))}
          </HStack>
        )}
      </Flex>
      <Box placeSelf="center">
        <Tooltip
          label={uiText.getReceiptMessage}
          bg={APP_ERROR_COLOR}
          p="10px 20px 10px 20px"
          placement="top"
          mb={4}
          borderRadius="4px"
          shouldWrapChildren
          hasArrow
          isDisabled={hasReceipt || hasFreeLicence}
        >
          {renderCreateNewCollection()}
        </Tooltip>
      </Box>
    </Box>
  );
};
