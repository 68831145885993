import { Box, Flex, GridItem, Heading, Text } from "@chakra-ui/react";
import { ethers } from "ethers";
import { Result } from "ethers/lib/utils";
import React from "react";
import { Abi } from "./types";

type TransactionInfoCardProps = {
  target: string;
  value: string;
  data: string;
  signatures: string[];
  onClose: () => void;
  abi: Abi;
};

export const TransactionInfoCard = ({
  target,
  value,
  data,
  signatures,
  onClose,
  abi,
}: TransactionInfoCardProps) => {
  const [abiMethod, setAbiMethod] = React.useState<{
    function: ethers.utils.FunctionFragment;
    inputs: Result;
  }>();

  React.useEffect(() => {
    if (!abi.length || !data) return;

    const iface = new ethers.utils.Interface(abi);

    const abiMethod = iface.getFunction(ethers.utils.hexDataSlice(data, 0, 4));
    const inputs = iface.decodeFunctionData(abiMethod, data);

    setAbiMethod({ function: abiMethod, inputs });
  }, [abi, data]);

  return (
    <Flex
      backgroundColor="#D3D3D3"
      borderRadius="4px"
      margin="5px"
      color="black"
      p="10px"
      whiteSpace="nowrap"
      overflow="auto"
      gap={1}
    >
      <GridItem colSpan={1}>
        <Flex>
          <Heading fontSize="14px" fontWeight="bold" fontFamily="body">
            Transaction Info
          </Heading>
          <Box color="#D3D3D3" fontSize="14px">
            {"sadasd"}
          </Box>
        </Flex>
        <Flex mt="10px">
          <Box fontStyle="italic" fontSize="14px" mr="150px" w="50px">
            Target:
          </Box>
          <Box fontSize="14px">{target}</Box>
        </Flex>
        <Flex mt="10px">
          <Box fontStyle="italic" fontSize="14px" mr="150px" w="50px">
            Value:
          </Box>
          <Box fontSize="14px">
            {parseInt(value) / Math.pow(10, 18) || 0} ETH
          </Box>
        </Flex>
        <Flex mt="10px">
          <Box fontStyle="italic" fontSize="14px" mr="150px" w="50px">
            Data:
          </Box>
          <Box fontSize="14px" whiteSpace="nowrap">
            {data}
          </Box>
        </Flex>
        <Flex mt="10px">
          <Box fontSize="14px" mr="150px" w="50px">
            Decoded Data:
          </Box>
          <Box fontSize="14px">
            <Flex>
              <Text> {abiMethod?.function.name}</Text>(
              {abiMethod?.function.inputs.map((input, i) => (
                <Flex key={`funct-param-${i}`} flexWrap="nowrap">
                  <Text>{input.name}</Text>,<Text mr="3px">{input.type}</Text>
                  <Text fontWeight="bold">
                    {input.type === "uint256"
                      ? parseInt(abiMethod.inputs[i])
                      : abiMethod.inputs[i]}
                  </Text>
                </Flex>
              ))}
              )
            </Flex>
          </Box>
        </Flex>
        <Box mt="10px" fontSize="14px" fontWeight="bold" mr="150px">
          Signatures:
        </Box>
        <Box>
          {signatures.map((s, i) => (
            <Text key={`${s}-${i}`} fontSize="14px">
              {s}
            </Text>
          ))}
        </Box>
      </GridItem>
      <GridItem colSpan={4}></GridItem>
    </Flex>
  );
};
