import { Collection } from "../../../system/api/types";

export const isLegacyContract = (
  contractType: Collection["contractType"] | ""
) => {
  const isLegacy =
    contractType !== "Erc-721" &&
    contractType !== "Erc-721 DAO" &&
    contractType !== "Erc-721X";

  return isLegacy;
};
