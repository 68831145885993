import { Button, Spinner, Text } from "@chakra-ui/react";
import { Collection } from "../../../system/api/types";
import { useDeployContract } from "../../../system/hooks/ethereum/deploy/useDeployContract";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_WARNING_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
} from "../../../theme/colors";

type DeployButtonProps = {
  collection: Collection;
  goToCollectionError: () => void;
};

export const DeployButton = ({
  collection,
  goToCollectionError,
}: DeployButtonProps) => {
  const { uiText } = useUiText();
  const { deployAndVerify, deployRequest } = useDeployContract(
    collection.contractType
  );

  const deploy = async () => {
    deployAndVerify(
      {
        name: collection.name,
        symbol: collection.symbol,
        total: collection.total.toString(),
        cost: collection.cost,
        allowListMintPrice: collection.allowListMintPrice,
        maxAllowedMints: collection.maxAllowedMints?.toString(),
      },
      collection.id
    );
  };

  const renderDeployButton = () => {
    return (
      <>
        {deployRequest.pending ? (
          <Spinner size="sm" color={APP_PRIMARY_CONTRAST_COLOR} />
        ) : (
          <Text fontSize={"12px"} fontWeight={"bold"} color="#FFF">
            {deployRequest.success ? uiText.deployed : uiText.deployCollection}
          </Text>
        )}
      </>
    );
  };

  const renderCollectionErrorButton = () => {
    return (
      <Text fontSize={"12px"} fontWeight={"bold"} color="#FFF">
        {uiText.errorCreatingCollection}
      </Text>
    );
  };

  return (
    <Button
      bgColor={APP_WARNING_COLOR}
      w="145px"
      h="38px"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"6px"}
      zIndex={10}
      _hover={{
        opacity: 0.9,
        bgColor: APP_WARNING_COLOR,
      }}
      _disabled={{
        _hover: {
          opacity: 1,
          bgColor: APP_WARNING_COLOR,
        },
        cursor: "progress",
      }}
      onClick={
        !!collection.metaDataBaseUrl
          ? () => deploy()
          : () => goToCollectionError()
      }
    >
      {!!collection.metaDataBaseUrl
        ? renderDeployButton()
        : renderCollectionErrorButton()}
    </Button>
  );
};
