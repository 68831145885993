import { Box, Flex, IconButton, Image, Spinner, Text } from "@chakra-ui/react";
import useDeviceDetect from "../../system/hooks/useDeviceDetect";
import { useUiText } from "../../system/hooks/useUiText";
import { FaQuestion } from "react-icons/fa";
import {
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  COLLECTOR_BORDER_COLOR,
} from "../../theme/colors";
import { StatusCardProps } from "./types";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useContext } from "react";
import { ApiContext } from "../../system/api/ApiContext";

type CollectorSuccessCardProps = {
  imageSources: string[];
  amount: number;
  totalSupply: number;
};
function CollectorSuccessCard({
  onClose,
  imageSources,
  amount = 2,
  totalSupply,
}: CollectorSuccessCardProps & StatusCardProps) {
  const { isMobile } = useDeviceDetect();
  const { uiText } = useUiText();
  const { nftImagesRequest } = useContext(ApiContext);

  const renderPreMinted = () => {
    let items = [];

    for (let i = 0; i < amount; i++) {
      items.push(
        <Box
          display={"flex"}
          border={"0.1px solid #909090"}
          p="15px"
          borderColor={COLLECTOR_BORDER_COLOR}
          borderRadius="8px"
          boxShadow="xl"
          my={{ base: "41px", lg: "51px" }}
          flexDir={"column"}
          alignItems="center"
          m="15px"
          key={i}
          minW="250px"
        >
          {!!imageSources.length && (
            <Image w="219px" h="219px" src={imageSources[i]} />
          )}
          {!imageSources.length && (
            <Box
              w="219px"
              h="219px"
              backgroundColor={APP_PRIMARY_COLOR}
              display={"block"}
              border={"0.1px"}
              borderRadius="8px"
              my={"15px"}
            >
              <Flex
                h="100%"
               justifyContent="center"
                alignItems="center"
                overflow="hidden"
              >
                {nftImagesRequest.pending ? (
                  <Spinner color={APP_SECONDARY_COLOR} />
                ) : (
                  <FaQuestion color="#95ffce" fontSize="100" />
                )}
              </Flex>
            </Box>
          )}
          <Flex justifyContent="flex-start" w="100%">
            <Text fontFamily="heading" fontWeight="bold" fontSize="16px">
              #{i + totalSupply}
            </Text>
          </Flex>
        </Box>
      );
    }

    return (
      <Flex w="100%" overflowX="auto"justifyContent="center" flexWrap="wrap">
        {items.map((i) => i)}
      </Flex>
    );
  };

  return (
    <Box
      borderRadius={isMobile ? undefined : "12px"}
      borderWidth={"1px"}
      borderColor={"transparent"}
      w="100%"
      h="100%"
      color="black"
      bgColor={APP_PRIMARY_CONTRAST_COLOR}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
      flexDir="column"
      position="relative"
      overflow="hidden"
    >
      <IconButton
        aria-label="button"
        position="absolute"
        top="5px"
        right="3px"
        onClick={() => onClose()}
        size="lg"
        bg="transparent"
        _hover={{ cursor: "pointer" }}
        _focus={{ border: "none" }}
        icon={<AiOutlineArrowLeft />}
      />
      <Text fontFamily={"heading"} fontWeight="semibold" fontSize={"30px"}>
        {uiText.congratulations}
      </Text>
      <Text fontFamily={"heading"} fontWeight="semibold" fontSize={"15px"}>
        {uiText.mintSuccessMessage}
      </Text>
      {renderPreMinted()}
    </Box>
  );
}

export default CollectorSuccessCard;
