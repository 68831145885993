import { Flex, Heading, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, IconButton, Image } from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../../../components/buttons";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import { CollectionProvenanceViewProps } from "./types";
import { CollectionContractContext } from "./CollectionContractContext";
import { ModalContext } from "../../../system/context/ModalContext";

export const CollectionProvenanceView = ({
  goBack,
  goToEnablePreMint,
  goToEnableMinting,
  collection,
}: CollectionProvenanceViewProps) => {
  const { uiText } = useUiText();
  const { transactionPending, setProvenance, provenance } = React.useContext(
    CollectionContractContext
  );
  const { transactionPendingModal } = React.useContext(ModalContext);

  const handleSetProvenance = async () => {
    setProvenance(collection.provenanceHash);
  };

  React.useEffect(() => {
    if (transactionPending) {
      transactionPendingModal.onOpen();
    }

    if (transactionPending && provenance) {
      transactionPendingModal.onClose();
    }
  }, [transactionPending, transactionPendingModal, provenance]);

  const renderProvenanceHashSet = () => {
    return (
      <>
        <Box
          h="100%"
          display="flex"
          flexDir="column"
          justifyContent="flexStart"
          overflow="auto"
          alignItems="center"
        >
          <Box>
            <ModalHeader>
              <IconButton
                aria-label="button"
                position="absolute"
                top="5px"
                right="5px"
                onClick={() => goBack()}
                size="lg"
                bg="transparent"
                _hover={{ cursor: "pointer" }}
                _focus={{ border: "none" }}
                icon={<AiOutlineClose />}
              />
            </ModalHeader>
            <Flex
              flexDir="column"
             justifyContent="flex-start"
              p="30px"
              maxW="300px"
              alignItems="center"
            >
              <Heading fontSize="30px" textAlign="center">
                {uiText.provenanceHashSet}
              </Heading>
              <Image
                w="180px"
                m="80px 0px 80px 0"
                src="/collector-success.png"
              />
            </Flex>
          </Box>
          <Flex
            placeSelf={"center"}
            flexDir={"column"}
            w={"205px"}
            mb={"25px"}
          ></Flex>
        </Box>
      </>
    );
  };

  if (provenance) {
    return renderProvenanceHashSet();
  }

  return (
    <Box
      h="100%"
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      overflow={"auto"}
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="30px" mt="100px">
          <Heading fontSize="30px">{uiText.setProvenance}</Heading>
          <Text color={APP_PRIMARY_CONTRAST_COLOR}>
            {uiText.readyToStartCollection}
          </Text>
          <Text color={APP_TEXT_INACTIVE_COLOR}>
            {uiText.provenancePopupDescription}
          </Text>
        </Flex>
      </Box>
      <Flex placeSelf={"center"} flexDir={"column"} w={"205px"} mb={"25px"}>
        <PrimaryButton
          mb="10px"
          text={uiText.setProvenance}
          onClick={handleSetProvenance}
        />
      </Flex>
    </Box>
  );
};
