import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

export const ConnectWalletContext = React.createContext<any>({});

export const ConnectWalletProvider: React.FC<{children: any}> = ({ children }) => {
  const { chains, provider } = configureChains(
    [chain.mainnet, chain.goerli],
    [
      jsonRpcProvider({
        rpc: () => ({
          http: `https://serene-omniscient-tab.quiknode.pro/0709ed44b06dd76ede33962e81769ba5aeb89671`,
        }),
      }),
    ],
    { pollingInterval: 10000000, minQuorum: 1, targetQuorum: 1 }
  );

  const { connectors } = getDefaultWallets({
    appName: "Omakasea",
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        theme={darkTheme({
          accentColor: "black",
        })}
        chains={chains}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
