import {
  Flex,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  Text,
  Spinner,
} from "@chakra-ui/react";
import {
  APP_HEADER_BACKGROUND_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ModalContext } from "../../system/context/ModalContext";
import { HeaderButton } from "../../components/buttons/HeaderButton";
import { useUiText } from "../../system/hooks/useUiText";
import { useScreenSize } from "../../system/hooks/useScreenSize";
import { GiHamburgerMenu } from "react-icons/gi";
import { LEGACYContractContext } from "../../system/context/LEGACYContractContext";
import { FaDiscord } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { GrTwitter } from "react-icons/gr";
import { BsChevronDown } from "react-icons/bs";
import { DISCORD_PAGE_HREF, TWITTER_PAGE_HREF } from "../../system/constants";
import { useNoReceipt } from "../../pages/Modals/NoReceiptModal/useNoReceipt";
import { CollectorContext } from "../../system/context/CollectorContext";
import { AuthenticateFreeContext } from "../../system/context/AuthenticateFreeContext";
import { MintPageContractContext } from "../../pages/Modals/WalletModal/MintPageContractContext";
import mixpanel from "mixpanel-browser";
import { ApiContext } from "../../system/api/ApiContext";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

export const AppHeader = () => {
  const { address: account } = useAccount()
  const { hasReceipt, mintToken } = useContext(LEGACYContractContext);
  const { hasFreeLicence } = useContext(AuthenticateFreeContext);
  const { walletModal } = useContext(ModalContext);
  const { authenticateFreeUserRequest } = useContext(ApiContext);
  const { totalSupply, maxTotalSupply, initializing, transactionPending } =
    useContext(MintPageContractContext);
  const { uiText } = useUiText();
  const { isWidthOrGreater } = useScreenSize(1024);
  const { handleCreateNewCollection } = useNoReceipt();
  const { isMintPage, content } = useContext(CollectorContext);
  const location = useLocation();
  const isDao = location.pathname.includes("/dao");

  const [sticky, setSticky] = React.useState(false);

  const trackScroll = () => {
    if (typeof window === "undefined") {
      return;
    } else {
      setSticky(window.scrollY >= 120);
    }
  };

  React.useEffect(() => {
    document.addEventListener("scroll", trackScroll);

    return () => {
      document.removeEventListener("scroll", trackScroll);
    };
  }, []);

  const handleReceipt = () => {
    if (account) {
      walletModal.onOpen()
      mintToken(1);
      return;
    }
    walletModal.onOpen();
  };

  const handleConnect = () => {
    walletModal.onOpen();
  };

  const navItems = [
    <HeaderButton
      text={uiText.startNewCollection}
      disabled={
        location.pathname === "/create-collection" ||
        (!hasReceipt && !hasFreeLicence)
      }
      isLoading={authenticateFreeUserRequest.pending}
      display={!account ? "none" : "block"}
      onClick={handleCreateNewCollection}
      mr={"15px"}
    />,
    <Link to="/freemium">
      <HeaderButton
        text="Become a Member"
        disabled={location.pathname === "/freemium"}
        mr="15px"
        onClick={() => mixpanel.track("Navigate to Freemium")}
      />
    </Link>,
    <Link to="/team">
      <HeaderButton
        text="The Team"
        disabled={location.pathname === "/team"}
        mr="15px"
      />
    </Link>,
    <HeaderButton
      text={uiText.mintReceipt}
      disabled={!account}
      onClick={handleReceipt}
      display={!account ? "none" : "block"}
    />,
    <Flex flexDir="row" alignItems="center">
      {account && !isMintPage ? (
        <HeaderButton
          text={uiText.myProfile}
          onClick={handleConnect}
          rightIcon={<BsChevronDown color="#95ffce" />}
          profile={!isWidthOrGreater ? true : false}
        />
      ) : (
        <ConnectButton />
      )}
    </Flex>,
    <Flex>
      <IconButton
        aria-label="link"
        onClick={() => window.open(DISCORD_PAGE_HREF)}
        bg="transparent"
        icon={<FaDiscord size="22px" color="#fff" />}
        _hover={{ bgColor: "#282828" }}
        ml="15px"
        color={APP_PRIMARY_CONTRAST_COLOR}
      />
      <IconButton
        aria-label="link"
        onClick={() => window.open(TWITTER_PAGE_HREF)}
        bg="transparent"
        icon={<GrTwitter size="22px" color="#fff" />}
        _hover={{ bgColor: "#282828" }}
        ml="10px"
        color={APP_PRIMARY_CONTRAST_COLOR}
      />
      <IconButton
        aria-label="link"
        bg="transparent"
        onClick={() => window.open("/files/Lightpaper.pdf")}
        icon={<IoIosDocument size="22px" color="#fff" />}
        _hover={{ bgColor: "#282828" }}
        ml="10px"
        color={APP_PRIMARY_CONTRAST_COLOR}
      />
    </Flex>,
  ];

  const mobileHeader = () => {
    return !isMintPage ? (
      <Menu>
        <MenuButton as={IconButton} icon={<GiHamburgerMenu />} />
        <MenuList
          bgColor={APP_HEADER_BACKGROUND_COLOR}
          borderColor={"#383838"}
          boxShadow="xl"
          p="10px"
        >
          {navItems.map((n, i) => (
            <Box
              key={`nav-item-${i}`}
              _hover={{ bg: "transparent" }}
              _active={{ bgColor: "transparent" }}
              _focus={{ bgColor: "transparent" }}
              display="flex"
              alignItems="flex-start"
            >
              {n}
            </Box>
          ))}
        </MenuList>
      </Menu>
    ) : (
      <Flex
       justifyContent="center"
       alignItems="start"
        flexWrap="wrap"
        flexDir="column-reverse"
      >
        {renderTotalMinted()}
        {
          <Flex flexDir="row" alignItems="center">
            {account && !isMintPage ? (
              <HeaderButton
                text={uiText.myProfile}
                onClick={handleConnect}
                rightIcon={<BsChevronDown color="#95ffce" />}
                profile={!isWidthOrGreater ? true : false}
              />
            ) : (
              <ConnectButton chainStatus="none" />
            )}
          </Flex>
        }
      </Flex>
    );
  };

  const renderTotalMinted = () => {
    return (
      <>
        {transactionPending || initializing || maxTotalSupply === 0 ? (
          <Spinner color={APP_SECONDARY_COLOR} />
        ) : (
          <>
            {!isWidthOrGreater && (
              <Text
                color={APP_PRIMARY_CONTRAST_COLOR}
                fontWeight="bold"
                fontSize="17px"
                mr="50px"
                fontFamily={"heading"}
              >
                Minted Nfts
              </Text>
            )}
            <Text fontSize="17px" color="white" fontFamily={"heading"} mx="5px">
              <b>{totalSupply}</b>/{maxTotalSupply}
            </Text>
          </>
        )}
      </>
    );
  };

  return (
    <Flex
      backgroundColor={APP_HEADER_BACKGROUND_COLOR}
      minH={!sticky ? "20px" : "20px"}
      paddingLeft={["25px", "25px", "70px"]}
      paddingRight="32px"
      py={!sticky ? "20px" : "10px"}
      position="sticky"
      top={0}
      zIndex={10}
      justifyContent="space-between"
      transform="scale(1)"
      transition="0.55s all ease"
    >
      <Flex alignItems="center">
        <Box
          w={sticky ? 180 : !isWidthOrGreater ? 200 : 140}
          transform="scale(1)"
          transition="0.55s all ease"
        >
          {(content?.removeBrandingMintPage && isMintPage) ||
            (!isDao && (
              <Link to="/home">
                <Image
                  h={8}
                  src="/omakasealogo.png"
                  marginRight={2}
                  objectFit="contain"
                />
              </Link>
            ))}
        </Box>
      </Flex>
      <Flex>
        {isWidthOrGreater ? (
          mobileHeader()
        ) : (
          <>
            {isMintPage || isDao ? (
              <Flex justifyContent={"center"}>
                <Flex flex={2} mr="200px" alignSelf={"center"}>
                  {isMintPage && renderTotalMinted()}
                </Flex>
                {<ConnectButton />}
              </Flex>
            ) : (
              navItems.map((n, i) => {
                return (
                  <Flex key={`nav-item-${i}`}justifyContent={"center"}>
                    {n}
                  </Flex>
                );
              })
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};
