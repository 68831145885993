import { Flex, Text, Box } from "@chakra-ui/layout";
import {
  ModalHeader,
  IconButton,
  chakra,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { PrimaryButton } from "../../../components/buttons";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_PRIMARY_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import { CollectionAllowListViewProps } from "./types";
import { CsvUploadInput } from "../../../components/inputs/CsvUploadInput";
import { useContext, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { WalletContext } from "../../../system/context/WalletContext";
import { CollectionContractContext } from "./CollectionContractContext";
import { useCsvReader } from "../../../components/inputs/CSVReader";
import React from "react";
import { ApiContext } from "../../../system/api/ApiContext";
import { requestInitialState } from "../../../system/api/types";

export const CollectionAllowListView = ({
  goBack,
  goToAllowListMinting,
}: CollectionAllowListViewProps) => {
  const { uiText } = useUiText();

  const [file, setFile] = useState<FileWithPath>();
  const [currentList, setCurrentList] = useState<string[]>([]);
  const { validation } = useContext(WalletContext);
  const { contract, mintStatus } = useContext(CollectionContractContext);
  const { csvArray, readCsv } = useCsvReader();
  const {
    uploadAllowList,
    uploadAllowListRequest,
    setUploadAllowListRequest,
    getSingleCollection,
  } = useContext(ApiContext);

  const handleSetAllowList = async () => {
    uploadAllowList(
      csvArray,
      validation,
      contract?.address || "",
      () => null,
      () => null
    );
  };

  React.useEffect(() => {
    if (file) readCsv(file as File);
    // eslint-disable-next-line
  }, [file]);

  React.useEffect(() => {
    if (contract) {
      getSingleCollection(contract?.address, validation, () => goBack()).then(
        (collection) => {
          setCurrentList(collection.allowListWallets);
        }
      );
    }
    // eslint-disable-next-line
  }, [contract]);

  React.useEffect(() => {
    return () => {
      setUploadAllowListRequest(requestInitialState);
    };
    // eslint-disable-next-line
  }, []);

  const renderAllowListSuccess = () => {
    return (
      <>
        <Box
          h="100%"
          display="flex"
          flexDir="column"
          justifyContent="flexStart"
          overflow="auto"
          alignItems="center"
        >
          <Box>
            <ModalHeader>
              <IconButton
                aria-label="button"
                position="absolute"
                top="5px"
                right="5px"
                onClick={() => goBack()}
                size="lg"
                bg="transparent"
                _hover={{ cursor: "pointer" }}
                _focus={{ border: "none" }}
                icon={<AiOutlineClose />}
              />
            </ModalHeader>
            <Flex
              flexDir="column"
             justifyContent="flex-start"
              p="30px"
              maxW="300px"
              alignItems="center"
            >
              <chakra.h2
                textAlign="center"
                fontSize="30px"
                maxW="300px"
                fontFamily="heading"
                fontWeight="bold"
              >
                {uiText.allowListSuccessMessage}
              </chakra.h2>
              <Image
                w="180px"
                m="80px 0px 80px 0"
                src="/collector-success.png"
              />
            </Flex>
          </Box>
          <Flex placeSelf={"center"} flexDir={"column"} w={"205px"} mb={"25px"}>
            {mintStatus === 0 && (
              <SecondaryButton
                mb="10px"
                bgColor={APP_PRIMARY_COLOR}
                border="black"
                dark
                cursor="pointer"
                text={uiText.enableAllowListMinting}
                onClick={() => {
                  goToAllowListMinting();
                  goBack();
                }}
              />
            )}
            <SecondaryButton
              text={uiText.skip}
              border="none"
              onClick={goBack}
            />
          </Flex>
        </Box>
      </>
    );
  };

  if (uploadAllowListRequest.success) {
    return renderAllowListSuccess();
  }

  return (
    <Box
      h="100%"
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      overflow={"auto"}
    >
      {uploadAllowListRequest.pending && (
        <Spinner
          textAlign="center"
          margin="auto"
          size="xl"
          color={APP_SECONDARY_COLOR}
        />
      )}
      {!uploadAllowListRequest.pending && (
        <>
          <Box>
            <ModalHeader>
              <IconButton
                aria-label="button"
                position="absolute"
                top="5px"
                right="5px"
                onClick={() => goBack()}
                size="lg"
                bg="transparent"
                _hover={{ cursor: "pointer" }}
                _focus={{ border: "none" }}
                icon={<AiOutlineArrowLeft />}
              />
            </ModalHeader>
            <Flex flexDir="column" p="30px">
              <Text fontFamily="heading" fontSize="28px" fontWeight="bold">
                {uiText.allowListFieldLabel}
              </Text>
              <Text color={APP_TEXT_INACTIVE_COLOR} mt="28px">
                {uiText.allowListDescription}
              </Text>
            </Flex>
            <CsvUploadInput
              setFile={(f: FileWithPath) => setFile(f)}
              csvArray={!!csvArray.length ? csvArray : currentList}
            />
            {uploadAllowListRequest.error && (
              <Text textAlign="center" color={APP_ERROR_COLOR}>
                {uiText.somethingWentWrong}
              </Text>
            )}
          </Box>
          <Flex placeSelf={"center"} flexDir={"column"} w={"205px"} mb={"25px"}>
            <PrimaryButton
              mb="10px"
              text={uiText.setAllowList}
              disabled={!csvArray.length}
              onClick={handleSetAllowList}
            />
            <SecondaryButton
              text={uiText.skip}
              border="none"
              onClick={() => {
                goBack();
              }}
            />
          </Flex>
        </>
      )}
    </Box>
  );
};
