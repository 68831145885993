export const DISCORD_PAGE_HREF = "http://discord.gg/omakasea";
export const TWITTER_PAGE_HREF = "https://mobile.twitter.com/omakasea_";
export const MASATOSHI_CONTRACT_ADDRESS =
  process.env.REACT_APP_MASATOSHI_CONTRACT_ADDRESS;
export const whitelistedAddresses = [
  "0x8e3E7850b360B9C152481aDd56b7A510880495e7",
];
export const SIGNER_ADDRESS = "0xe9A347e4bFbe5A219F3497B1CA3Ac8568a99ED6c";
export const GOOGLE_MAPS_API_KEY = "AIzaSyAGw1-MTQzdpLBUxctHeuwnxDmQuS_4BnY";
export const OMAKASEA = "0x9A88d47EBb4038e9d8479A9535FCCa0d3F8Ba73B";

export const DAO_DELEGATE_ROPSTEN =
  "0x5992e66c1363240BeE233d086d4c600122E759d7";
export const DAO_DELEGATE_MAINNET =
  "0x082e44ad879e804A873B4B425d80BbCa32E74415";
export const ETHERSCAN_API_KEY = "H81JP2K78YAZZB6H89M4MBGB9TWGF792WH";
export const UNICORN_ADDRESS = "0x2a6f284baaac10f0225780353ff8e14a4be83ff9";
export const MORALIS_API_KEY =
  "SR8xyPvIYJcTwz9ucRvjag47zo2fH8cOMyWQsKRURuk721lqy3ZXfl6uP6nDFxmq";
export const MAINNET_ETHERSCAN_API_URL = "api.etherscan.io";
export const ROPSTEN_ETHERSCAN_API_URL = "api-ropsten.etherscan.io/";
export const ALCHEMY_API_KEY = "iXGUSjeHpX0H2xx21yTKpRm2hJI-diop"; 
export const INFURA_API_KEY = "921a208a22bf45668cbb538537a8f008";