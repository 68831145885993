import { ethers } from "ethers";
import React from "react";
import { Contract, useContract } from "../hooks/ethereum/useContract";

interface IContractContext extends Contract {}

export const ContractContext = React.createContext<IContractContext>({
  errorStatus: "",
  contract: {} as ethers.Contract,
  hasReceipt: false,
  mintToken: (amount: number) => Promise.resolve({} as any),
  preMint: (amount: number) => null,
  transactionPending: false,
  transactionSuccessful: false,
  transactionUnSuccessful: false,
  isLegacy: false,
  resetTransactionState: () => null,
  tokenIds: [],
  enableMinting: () => null,
  tokenBalancePending: false,
  currentTokenBalance: 0,
  withDrawEth: (address: string) =>
    Promise.resolve({} as any),
  totalSupply: 0,
  getTotalSupply: () => null,
  revealCollection: (uri: string) => Promise.resolve("" as any),
  baseUri: undefined as unknown as string,
  setProvenance: (provenanceHash: string) => null,
  provenance: undefined as unknown as string,
  initializing: false,
  mintPrice: 0,
  maxTotalSupply: 0 as number,
  // eslint-disable-next-line
  saleIsActive: false,
  mintStatus: 0,
  changeMintStatus: () => Promise.resolve(0 as any),
  allowListMintPrice: 0,
  init: () => Promise.resolve({} as any),
  contractError: false,
  maxAllowedMints: 0,
  contractBalance: 0,
  getAllVotings: () => Promise.resolve({} as any),
  getName: () => Promise.resolve({} as any),
  votings: [],
  name: "",
  percentToVote: 0,
  signVoting: (index: number) => Promise.resolve({} as any),
  activateVoting: (index: number) => Promise.resolve({} as any),
  convertToDao: () => Promise.resolve({} as any),
  getIsDao: () => Promise.resolve({} as any),
  createVoting: () => Promise.resolve({} as any),
  isConvertedToDao: false,
});

export const ContractProvider: React.FC<{children: any}> = React.memo(({ children }) => {
  const contract = useContract();

  return (
    <ContractContext.Provider value={{ ...contract }}>
      {children}
    </ContractContext.Provider>
  );
});
