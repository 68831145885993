import { Flex, Heading, Text } from "@chakra-ui/layout";
import { ModalCloseButton, Image, Tooltip } from "@chakra-ui/react";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  WALLET_MODAL_ALT_BG_COLOR,
} from "../../../theme/colors";

export const WalletModalTransactionFailedView = ({
  errorMessage,
}: {
  errorMessage?: string;
}) => {
  const { uiText } = useUiText();
  return (
    <>
      <ModalCloseButton color={APP_PRIMARY_CONTRAST_COLOR} />
      <Flex
        flexDir="column"
        h="100%"
       alignItems="center"
       justifyContent="space-around"
        bgColor={WALLET_MODAL_ALT_BG_COLOR}
        p={10}
        borderRadius={8}
      >
        <Flex flexDir="column"alignItems="center">
          <Heading color={APP_ERROR_COLOR} textAlign="center">
            {uiText.somethingWentWrong}
          </Heading>
        </Flex>
        <Image src="/bones.webp" />
        <Flex flexDir="column">
          <Tooltip label={errorMessage || ""}>
            <Text
              color={APP_PRIMARY_CONTRAST_COLOR}
              maxWidth="200px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              cursor="pointer"
            >
              {errorMessage || ""}
            </Text>
          </Tooltip>
          <Text color={APP_PRIMARY_CONTRAST_COLOR} mt="10px" fontSize="14px">
            {uiText.failedTransactionMessage}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
