import { ContractFactory, ethers, Signer, utils } from "ethers";
import React from "react";
import {
  MASATOSHI_CONTRACT_ADDRESS,
  whitelistedAddresses,
} from "../../constants";
import { WalletContext } from "../../context/WalletContext";
import { EthereumRequests } from "./ethereumRequests";
import {
  MasterchefMasatoshi,
  MasterchefMasatoshi__factory,
} from "../../../contracts/abiTypes";
import { useSigner } from "wagmi";

const masterchefMasatoshi_json = require("../../../contracts/MasterchefMasatoshi.json");

export type LEGACYContract = {
  contract: ethers.Contract | undefined;
  hasReceipt: boolean;
  mintToken: (amount: number) => any;
  preMint: (amount: number) => void;
  transactionPending: boolean;
  transactionSuccessful: boolean;
  transactionUnSuccessful: boolean;
  resetTransactionState: () => void;
  tokenIds: number[];
  enableMinting: () => void;
  saleIsActive: boolean;
  tokenBalancePending: boolean;
  currentTokenBalance: number;
  withDrawEth: (address: string) => Promise<any>;
  getTotalSupply: () => void;
  totalSupply: number;
  revealCollection: (uri: string) => void;
  baseUri: string;
  setProvenance: (provenanceHash: string) => void;
  provenance: string;
  initializing: boolean;
  init: () => Promise<any>;
  mintPrice: number;
  maxTotalSupply: number;
  deployContract: (data: {
    name: string;
    symbol: string;
    total: string;
    cost: string;
  }) => Promise<any>;
  contractError: boolean;
  errorMessage: string
};

export const useContractLEGACY = (address?: string, isLegacy?: boolean) => {
  const {data: signer} = useSigner()
  const { account, chainId } = React.useContext(WalletContext);

  const [contract, setContract] = React.useState<MasterchefMasatoshi>();
  const [currentTokenBalance, setCurrentTokenBalance] = React.useState<number>(
    undefined as unknown as number
  );
  const [isWhitelistedAddress, setIsWhitelistedAddress] = React.useState(false);
  const [totalSupply, setTotalSupply] = React.useState<number>(
    undefined as unknown as number
  );
  const [transactionPending, setTransactionPending] = React.useState(false);
  const [transactionSuccessful, setTransactionSuccessful] =
    React.useState(false);
  const [transactionUnSuccessful, setTransactionUnSuccessful] =
    React.useState(false);
  const [tokenIds, setTokenIds] = React.useState<number[]>([]);
  const [saleIsActive, setSaleIsActive] = React.useState<boolean>(false);
  const [currentBaseUri, setCurrentBaseUri] = React.useState<string>(
    undefined as unknown as string
  );
  const [currentProvenance, setCurrentProvenance] = React.useState<string>(
    undefined as unknown as string
  );

  const [tokenBalancePending, setTokenBalancePending] =
    React.useState<boolean>(false);

  const [initializing, setInitializing] = React.useState(false);
  const [mintPrice, setMintPrice] = React.useState<number>(0);
  const [maxTotalSupply, setMaxTotalSupply] = React.useState<0>();
  const [contractError, setContractError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("")

  const getTokenBalance = async (
    contract: ethers.Contract,
    account: string
  ) => {
    setTokenBalancePending(true);
    if (contract && account)
      for (let address of whitelistedAddresses) {
        if (account === address) {
          setIsWhitelistedAddress(true);
        }
      }
    await contract
      .balanceOf(account)
      .then((b: any) => {
        setCurrentTokenBalance(b.toNumber());
      })
      .catch((err: any) => console.log(err));
  };

  const getTotalSupply = async () => {
    if (contract) {
      await contract
        .totalSupply()
        .then((b: any) => {
          setTotalSupply(b.toNumber());
        })
        .catch((err: any) => console.log(err));
    }
  };

  const getBaseUri = async (contract: ethers.Contract) => {
    if (contract)
      await contract
        .baseURI()
        .then((b: any) => {
          setCurrentBaseUri(b);
        })
        .catch((err: any) => console.log(err));
  };

  const getProvenance = async () => {
    if (contract)
      await contract
        .PROVENANCE()
        .then((p: any) => {
          setCurrentProvenance(p);
        })
        .catch((err: any) => console.log(err));
  };

  const getReceipts = async (
    contract: ethers.Contract,
    tokenBalance: number
  ) => {
    setTokenBalancePending(true);
    if (tokenBalance && account) {
      let ids: number[] = [];
      for (let i = 0; i < tokenBalance; i++) {
        await contract
          .tokenOfOwnerByIndex(account, i)
          .then((id: any) => ids.push(id.toNumber()))
          .then(() => setTokenIds(ids))
          .catch((err: any) => {
            setTokenIds([]);
          });
      }
    }
    setTokenBalancePending(false);
  };

  const getSalesState = async (contract: ethers.Contract) => {
    const enabled = await contract
      ?.saleIsActive()
      .catch((err: any) => console.log(err));

    setSaleIsActive(enabled);
  };

  const getMintPrice = async () => {
    await contract
      ?.mintPrice()
      .then((p: any) => {
        const price = parseInt(p) / Math.pow(10, 18) || 0;
        setMintPrice(price);
      })
      .catch((err: any) => console.log(err));
  };

  const getMaxTotalSupply = async () => {
    await contract
      ?.maxPossibleSupply()
      .then((s: any) => {
        setMaxTotalSupply(s.toNumber());
      })
      .catch((err: any) => console.log(err));
  };

  const resetTransactionState = () => {
    setTransactionSuccessful(false);
    setTransactionUnSuccessful(false);
  };

  /**
   * Initialization method to query contract and set values to state.
   * @returns
   */
  const init = async () => {
    if (!contract) return;

    const initContract: any = contract;

    /**
     * If totalSupply fails contract may have been deployed on a
     * different network then the current one and init should be stopped.
     */
    const totalSupply = await contract
      .totalSupply()
      .then()
      .catch(() => null);
    const canQueryContract = !!totalSupply;
    if (!canQueryContract) {
      setContractError(true);
    }

    /**
     * Init for Omakasea Receipts.
     */
    if (
      initContract.address === MASATOSHI_CONTRACT_ADDRESS &&
      (chainId === 1 || chainId === 3)
    ) {
      setInitializing(true);

      await Promise.all([
        getReceipts(initContract, currentTokenBalance),
        getMintPrice(),
        getSalesState(initContract),
      ]).finally(() => setInitializing(false));
    }

    /**
     * If not receipt check if it's a legacy contract.
     * If it's not a legacy contract end the process, if it is proceed.
     */
    if (initContract.address !== MASATOSHI_CONTRACT_ADDRESS) {
      if (!isLegacy) {
        setInitializing(false);
        return;
      }

      setInitializing(true);

      await Promise.all([
        getReceipts(contract, currentTokenBalance),
        getMintPrice(),
        getSalesState(contract),
        getTotalSupply(),
        getBaseUri(contract),
        getProvenance(),
        getMaxTotalSupply(),
      ]).finally(() => setInitializing(false));
    }
  };

  React.useEffect(() => {
    if (account && signer) {
      const contract = new ethers.Contract(
        address || MASATOSHI_CONTRACT_ADDRESS!,
        masterchefMasatoshi_json["abi"],
        signer
      ) as MasterchefMasatoshi;
      setContract(contract);
      getTokenBalance(contract, account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, account]);

  React.useEffect(() => {
    if (transactionSuccessful && contract && account) {
      getTokenBalance(contract, account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSuccessful, contract, account]);

  React.useEffect(() => {
    if (contract) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTokenBalance, contract]);

  const mintToken = async (amount: number) => {
    setTokenBalancePending(true);

    const price = await contract?.mintPrice();

    await contract
      ?.mintTokens(amount, { value: price?.mul(amount.toString()) })
      .then(() => {
        setTransactionPending(true);

        let tokenBalance: number;

        const pollTransactionState = setInterval(() => {
          contract?.balanceOf(account || "").then((b: any) => {
            tokenBalance = b.toNumber();
          });
          if (tokenBalance === currentTokenBalance + amount) {
            clearInterval(pollTransactionState);
            setCurrentTokenBalance(tokenBalance);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch((err: { code: number, message: string }) => {
        console.log(err);
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        
        setErrorMessage(err.message)
        setTransactionUnSuccessful(true);
      });
  };

  const preMint = async (amount: number) => {
    await contract
      ?.preMint(amount)
      .then(() => {
        setTransactionPending(true);

        let tokenBalance: number;

        const pollTransactionState = setInterval(() => {
          contract?.balanceOf(account || "").then((b: any) => {
            tokenBalance = b.toNumber();
          });

          if (tokenBalance === currentTokenBalance + amount) {
            clearInterval(pollTransactionState);
            setCurrentTokenBalance(tokenBalance);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const enableMinting = async () => {
    await contract
      ?.flipSaleState()
      .then(() => {
        setTransactionPending(true);

        let saleState: boolean;

        const pollSaleState = setInterval(() => {
          contract?.saleIsActive().then((s) => (saleState = s));
          if (saleState === !saleIsActive) {
            clearInterval(pollSaleState);
            setSaleIsActive(saleState);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const revealCollection = async (baseUri: string) => {
    await contract
      ?.setBaseURI(baseUri)
      .then(() => {
        setTransactionPending(true);

        let baseUri: string;

        const pollBaseUriState = setInterval(() => {
          contract?.baseURI().then((uri: string) => (baseUri = uri));
          if (!!baseUri) {
            clearInterval(pollBaseUriState);
            setCurrentBaseUri(baseUri);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        console.log("revealCollection", err);
        setTransactionUnSuccessful(true);
      });
  };

  const withDrawEth = async (address: string) => {
    await contract
      ?.withdraw()
      .then(() => {
        setTransactionPending(true);

        let balance: number;

        const pollSaleState = setInterval(() => {
          const provider = new ethers.providers.Web3Provider(
            window.ethereum as any,
            "any"
          );
          const requests = new EthereumRequests(provider);
          requests.getBalance(address).then((b) => {
            const value = parseInt(b) / Math.pow(10, 18) || 0;
            balance = value;
          });
          if (balance === 0) {
            clearInterval(pollSaleState);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const setProvenance = async (provenanceHash: string) => {
    await contract
      ?.setProvenanceHash(provenanceHash)
      .then(() => {
        setTransactionPending(true);

        let provenance: string;

        const pollProvenanceState = setInterval(() => {
          contract.PROVENANCE().then((p: any) => {
            provenance = p;
          });
          if (!!provenance) {
            clearInterval(pollProvenanceState);
            setCurrentProvenance(provenance);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const deployContract = async (data: {
    name: string;
    symbol: string;
    total: string;
    cost: string;
  }) => {
    const factory = new ContractFactory(
      masterchefMasatoshi_json["abi"],
      masterchefMasatoshi_json["bytecode"],
      signer as Signer
    ) as MasterchefMasatoshi__factory;
    const { name, symbol, total, cost } = data;
    const contract = await factory.deploy(
      name,
      symbol,
      total,
      utils.parseEther(cost.toString()),
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    );
    await contract.deployTransaction.wait(5);
    const deployTxHash = contract.deployTransaction.hash;
    const contractAddress = contract.address;
    return {
      deployTxHash,
      contractAddress,
    };
  };

  return {
    baseUri: currentBaseUri,
    contract,
    currentTokenBalance,
    enableMinting,
    getTokenBalance,
    getTotalSupply,
    hasReceipt: !!currentTokenBalance || !!isWhitelistedAddress,
    initializing,
    maxTotalSupply: maxTotalSupply || 0,
    mintPrice,
    mintToken,
    preMint,
    provenance: currentProvenance,
    resetTransactionState,
    revealCollection,
    saleIsActive,
    setProvenance,
    tokenBalancePending,
    tokenIds,
    totalSupply,
    transactionPending,
    transactionSuccessful,
    transactionUnSuccessful,
    withDrawEth,
    deployContract,
    init,
    contractError,
    errorMessage
  };
};
