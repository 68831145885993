import {
  Flex,
  Input,
  Text,
  Image as ImageChakra,
  InputProps,
} from "@chakra-ui/react";
import { FieldAttributes, useField } from "formik";
import { APP_HEADER_BACKGROUND_COLOR } from "../../theme/colors";
import FormDescription from "../forms/FormDescription";

export type TextInputProps = {
  label: string;
  image: string;
  id: string;
  labelFontSize?: string;
  description?: string;
  labelFontWeight: string;
  inputMarginBottom: string;
  labelColor?: string;
} & FieldAttributes<any> &
  InputProps;

export const TextInput = ({
  label,
  image,
  labelFontSize,
  labelColor,
  description,
  labelFontWeight,
  inputMarginBottom,
  ...props
}: TextInputProps) => {
  const [field, meta] = useField(props);

  return (
    <Flex
      flex={1}
      flexDir="column"
      alignItems="start"
      mr="64px"
      mb={inputMarginBottom || "15px"}
      minW={props.minW}
    >
      <Flex alignItems="center">
        {image && <ImageChakra src={image} w="14px" h="14px" mr="7px" />}
        <Flex flexDir="column" alignItems="start">
          <Text
            fontFamily="heading"
            fontWeight={labelFontWeight || "medium"}
            fontSize={labelFontSize || "15px"}
            alignItems="start"
            color={labelColor || "black"}
          >
            {label}
          </Text>
          <FormDescription text={description} />
        </Flex>
      </Flex>
      <Input
        {...field}
        {...props}
        value={field.value || ""}
        isInvalid={meta.touched && meta.error}
        focusBorderColor={APP_HEADER_BACKGROUND_COLOR}
      />
      {meta.touched && meta.error ? (
        <>
          <Text fontSize="12px" color="#E53E3E">
            {meta.error}
          </Text>
        </>
      ) : null}
    </Flex>
  );
};
