import { Box, Flex, Image as ImageChakra, Text } from "@chakra-ui/react";
import { FieldAttributes, useField } from "formik";
import Dropzone, { FileWithPath } from "react-dropzone";
import { useUiText } from "../../system/hooks/useUiText";
import { UPLOAD_BOX_TEXT_COLOR } from "../../theme/colors";
import CustomizeMintEditButton from "../buttons/CustomizeMintEditButton";
import MintButtonArea from "../cards/MintButtonArea";

export type MintImageUploadInputProps = {
  onDrop: (acceptedFiles: any) => void;
  image: FileWithPath;
  imgSrc: string;
  width: string;
  height: string;
  notMintPage: boolean;
  onDeleteImage: () => void;
} & FieldAttributes<any>;

export const MintImageUploadInput = ({
  onDrop,
  image,
  imgSrc,
  width,
  height,
  notMintPage,
  placeholderText,
  onDeleteImage,
  ...props
}: MintImageUploadInputProps) => {
  const { uiText } = useUiText();
  // eslint-disable-next-line
  const [field, meta, helper] = useField(props);

  return (
    <Dropzone
      onDrop={(acceptedFiles: any) => {
        helper.setValue(acceptedFiles[0]);
        onDrop(acceptedFiles[0]);
      }}
      multiple={false}
      accept="image/*"
      maxSize={10485760}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          w={width}
          h={height}
          mr={props.mr}
          backgroundColor="rgba(147,147,147,0.1)"
          position={"relative"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          cursor={"pointer"}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {!image && !imgSrc ? (
            <>
              <Flex flexDir={"column"} alignItems="center">
                <ImageChakra
                  src="/add-image.png"
                  w="35px"
                  h="35px"
                  mb="14px"
                  opacity={0.5}
                />
                <Text
                  fontFamily={"body"}
                  fontWeight="semibold"
                  fontSize={"11px"}
                  color={UPLOAD_BOX_TEXT_COLOR}
                >
                  {placeholderText
                    ? placeholderText
                    : uiText.customizeMintBoxDescription}
                </Text>
              </Flex>
            </>
          ) : (
            <Box position="relative">
              <ImageChakra
                w={width}
                h={height}
                src={image ? URL.createObjectURL(image) : imgSrc}
                objectFit="contain"
              />
              <CustomizeMintEditButton />
            </Box>
          )}
          {!notMintPage && <MintButtonArea />}
        </Box>
      )}
    </Dropzone>
  );
};
