import { Box, Flex, Spinner, Text, usePrevious } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../components/buttons";
import { useUiText } from "../../system/hooks/useUiText";
import {
  APP_IMAGE_PLACEHOLDER_COLOR,
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
  DESCRIPTION_COLOR,
} from "../../theme/colors";

import { Collection, requestInitialState } from "../../system/api/types";
import { useEthereumWallet } from "../../system/hooks/ethereum/useEthereumWallet";
import { UpdatedNotificationModal } from "../Modals/UpdatedNotificationModal/UpdatedNotificationModal";
import { MintImageUploadInput } from "../../components/inputs/MintImageUploadInput";
import { TextInput } from "../../components/inputs/TextInput";
import { Form, Formik } from "formik";
import { submitCustomizeMintForm, validateCustomizeMintForm } from "./utils";
import { CopyButton } from "../../components/buttons/CopyButton";
import { SwitchInput } from "../../components/inputs/SwitchInput";
import { LEGACYContractContext } from "../../system/context/LEGACYContractContext";
import FormLabel from "../../components/forms/FormLabel";
import FormDescription from "../../components/forms/FormDescription";
import { ApiContext } from "../../system/api/ApiContext";
import { useContract } from "../../system/hooks/ethereum/useContract";
import { TransactionPendingModal } from "../Modals/TransactionPendingModal/TransactionPendingModal";

const CustomizeMintForm = () => {
  const { uiText } = useUiText();
  const { sign, account, validation } = useEthereumWallet();
  let navigate = useNavigate();
  const { address } = useParams();
  const { hasReceipt } = useContext(LEGACYContractContext);
  const {
    uploadPlaceholderRequest,
    updateMintPageRequest,
    updateMintPage,
    setUpdateMintPageRequest,
    uploadPlaceholderMetaData,
    getMintPageContent,
    getSingleCollection,
  } = useContext(ApiContext);
  const { revealCollection, transactionPending } = useContract(address);

  const [desktopImage, setDesktopImage] = useState<FileWithPath>(null as any);
  const [mobileImage, setMobileImage] = useState<FileWithPath>(null as any);
  const [placeholderImages, setPlaceholderImages] = useState<FileWithPath[]>([
    {} as FileWithPath,
    {} as FileWithPath,
    {} as FileWithPath,
  ]);
  const [placeholderUrl, setPlaceholderUrl] = useState<string>("");
  const [mintLink, setMintLink] = useState("");
  const [collection, setCollection] = useState<Collection>();
  const prevAddress = usePrevious(address);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (collection) {
      setMintLink(window.location.origin + "/mint/" + address);
    }
  }, [collection, address]);

  useEffect(() => {
    if (
      ((address && !collection) ||
        (address && collection && prevAddress !== address)) &&
      !pageLoading
    ) {
      setPageLoading(true);
      getMintPageContent(address).then((collection) => {
        setCollection(collection);
        setPageLoading(false);
      });
      getSingleCollection(address, validation, () => null).then(
        (collection) => {
          setPlaceholderUrl(collection.tempImageUrl);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, address, navigate, prevAddress]);

  useEffect(() => {
    if (transactionPending) {
      setUpdateMintPageRequest(requestInitialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionPending]);

  const handleViewMintPage = () => {
    window.open(`/mint/${address}`, "_blank")?.focus();
  };

  if (pageLoading || !collection) {
    return (
      <Flex h="100vh" w="100vw" alignItems="center"justifyContent="center" bg="white">
        <Spinner size="xl" color={APP_SECONDARY_COLOR} />
      </Flex>
    );
  }

  return (
    <Box
      pt="74px"
      bg="white"
      px={{ base: "20px", xl: "300px" }}
      pb={{ base: "100px", xl: "600px" }}
      w="100%"
    >
      {updateMintPageRequest.success && (
        <UpdatedNotificationModal
          clearStatus={() => setUpdateMintPageRequest(requestInitialState)}
          onViewMintPage={handleViewMintPage}
          updatingPlaceholderData={uploadPlaceholderRequest.pending}
        />
      )}
      {transactionPending && (
        <TransactionPendingModal isOpen={true} onClose={() => null} />
      )}
      <Flex
        flexDir={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap="wrap"
      >
        <Text fontFamily={"heading"} fontWeight="bold" fontSize={"32px"}>
          {uiText.customizeMintTitle}
        </Text>
        <Flex flexDir={"column"} alignItems={"start"}>
          <Text
            fontFamily={"body"}
            fontSize="12px"
            mb="12px"
            color={DESCRIPTION_COLOR}
          >
            {uiText.customizeMintCollectionLink}
          </Text>
          <Flex>
            <Text
              fontFamily={"heading"}
              fontWeight="bold"
              fontSize={"18px"}
              mr="23px"
              w="320px"
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {mintLink}
            </Text>
            <CopyButton
              text={mintLink}
              mt="-7px"
              bgColor={APP_PRIMARY_CONTRAST_COLOR}
              aria-label="button"
              _hover={{ bgColor: "white" }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex mt="77px" flexDir={"column"} alignItems="start">
        <Text fontFamily={"heading"} fontWeight="semibold" fontSize={"24px"}>
          {uiText.customizeMintBannerTitle}
        </Text>
        <Text
          fontFamily={"body"}
          fontSize={"16px"}
          color={APP_TEXT_INACTIVE_COLOR}
        >
          {uiText.customizeMintBannerDescription}
        </Text>
      </Flex>

      <Formik
        initialValues={{
          twitter: collection?.twitter || "",
          instagram: collection?.instagram || "",
          opensea: collection?.opensea || "",
          discord: collection?.discord || "",
          mobileImage: mobileImage || {},
          desktopImage: desktopImage || {},
          removeBrandingMintPage: false,
          placeholderImages: placeholderImages || [],
        }}
        validate={validateCustomizeMintForm}
        onSubmit={(values) => {
          if (!account || !address) return;
          submitCustomizeMintForm(
            values,
            account,
            address,
            placeholderImages,
            updateMintPage,
            sign,
            uploadPlaceholderMetaData,
            revealCollection
          );
        }}
      >
        <Form>
          <Flex flexDir={"row"} mt="60px" bg="white" flexWrap="wrap">
            <Flex flexDir={"column"} mr="10px" mb="50px">
              <Flex alignItems={"center"} flexDir="row" mb="20px">
                <Text
                  fontFamily={"heading"}
                  fontSize="18px"
                  fontWeight={"medium"}
                  mr="8px"
                >
                  {uiText.customizeMintDesktopTitle}
                </Text>
                <Text
                  fontFamily={"body"}
                  fontSize="11px"
                  color={APP_TEXT_INACTIVE_COLOR}
                >
                  {uiText.customizeMintDesktopDescription}
                </Text>
              </Flex>
              <MintImageUploadInput
                onDrop={(file: any) => {
                  setDesktopImage(file);
                }}
                image={desktopImage}
                imgSrc={collection.mintPageDesktopImageUrl}
                name="desktopImage"
                type="file"
                width={{ base: "375px", md: "750px" }}
                height={{ base: "183px", md: "326px" }}
              />
            </Flex>
            <Flex flexDir={"column"} mr="10px" mb="50px">
              <Flex alignItems={"center"} flexDir="row" mb="20px">
                <Text
                  fontFamily={"heading"}
                  fontSize="18px"
                  fontWeight={"medium"}
                  mr="8px"
                >
                  {uiText.customizeMintMobileTitle}
                </Text>
                <Text
                  fontFamily={"body"}
                  fontSize="11px"
                  color={APP_TEXT_INACTIVE_COLOR}
                >
                  {uiText.customizeMintMobileDescription}
                </Text>
              </Flex>
              <MintImageUploadInput
                onDrop={(file: any) => {
                  setMobileImage(file);
                }}
                image={mobileImage}
                imgSrc={collection.mintPageMobileImageUrl}
                name="mobileImage"
                type="file"
                width="240px"
                height="366px"
              />
            </Flex>
          </Flex>

          {hasReceipt && (
            <SwitchInput
              name="removeBrandingMintPage"
              label="Remove Branding from Mint Page"
              w="full"
              borderColor={APP_TEXT_INACTIVE_COLOR}
            />
          )}

          <FormLabel
            name="placeholders"
            fontSize="24px"
            text={uiText.placeholderImagesLabel}
            mt="50px"
          />
          <FormDescription
            maxW="578px"
            fontSize="16px"
            text={uiText.placeHolderDescription}
          />
          <Flex alignItems="center">
            {placeholderImages.map((image, index) => (
              <Flex position="relative" key={`placeholder-${index}`}>
                <MintImageUploadInput
                  name="placeholders"
                  width="200px"
                  height="200px"
                  mr="10px"
                  imgSrc={placeholderUrl && `${placeholderUrl}${index}`}
                  placeholderText="Add a Placeholder Image"
                  onDrop={(file: any) => {
                    setPlaceholderImages(
                      placeholderImages.map((f, i) => {
                        if (i === index) {
                          return file;
                        }
                        return f;
                      })
                    );
                  }}
                  image={Object.keys(image).length === 0 ? null : image}
                  notMintPage
                />
              </Flex>
            ))}
          </Flex>
          <Flex mt="77px" flexDir={"column"} alignItems="start">
            <Text
              fontFamily={"heading"}
              fontWeight="semibold"
              fontSize={"24px"}
            >
              {uiText.customizeMintLinks}
            </Text>

            <Flex w="100%" mt="48px" flexWrap={"wrap"}>
              <TextInput
                name="discord"
                image="/discord-black.png"
                type="text"
                maxW="300px"
                placeholder=""
                label={uiText.discord}
                w="full"
                borderColor={APP_TEXT_INACTIVE_COLOR}
                minW="300px"
              />
              <TextInput
                name="instagram"
                image="/instagram-black.png"
                type="text"
                maxW="300px"
                placeholder=""
                label={uiText.instagram}
                minW="300px"
                borderColor={APP_TEXT_INACTIVE_COLOR}
              />
              <TextInput
                name="twitter"
                image="/twitter-black.png"
                type="text"
                maxW="300px"
                label={uiText.twitter}
                minW="300px"
                borderColor={APP_TEXT_INACTIVE_COLOR}
              />
              <TextInput
                name="opensea"
                image="/opensea-black.png"
                type="text"
                maxW="300px"
                label={uiText.opensea}
                minW="300px"
                borderColor={APP_TEXT_INACTIVE_COLOR}
              />
            </Flex>
          </Flex>
          <Flex justifyContent={"center"} mt={"110px"} flexWrap="wrap">
            <PrimaryButton
              w="226px"
              h="54px"
              text={uiText.saveDetails}
              isLoading={updateMintPageRequest.pending}
              type="submit"
              mb="10px"
            />
            <PrimaryButton
              w="226px"
              h="54px"
              borderWidth={"1px"}
              ml={{ base: "0px", md: "30px" }}
              mb="10px"
              _hover={{
                opacity: 0.8,
                backgroundColor: APP_IMAGE_PLACEHOLDER_COLOR,
              }}
              borderColor={APP_PRIMARY_COLOR}
              backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
              text={uiText.viewMintPage}
              onClick={() => window.open(`/mint/${address}`, "_blank")?.focus()}
            />
          </Flex>
        </Form>
      </Formik>
    </Box>
  );
};

export default CustomizeMintForm;
