import { Flex, Heading } from "@chakra-ui/react";
import {
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
} from "../../theme/colors";
import { TeamProfileCard } from "./TeamProfileCard";
import { executiveProfiles, profiles } from "./utils";

export const TeamPage = () => {
  return (
    <Flex
      h="100%"
      w="100%"
      p="20px"
      backgroundColor={APP_PRIMARY_COLOR}
     justifyContent="center"
    >
      <Flex flexDir="column"alignItems="center">
        <Heading color={APP_PRIMARY_CONTRAST_COLOR} mt="60px">
          Omakasea Team
        </Heading>
        <Flex flexWrap="wrap" mt="50px"alignItems="center"justifyContent="center">
          {executiveProfiles.map((p) => (
            <TeamProfileCard profile={p} key={p.firstName} />
          ))}
        </Flex>
        <Flex mt="10px" flexWrap="wrap"justifyContent="center">
          {profiles.map((p) => (
            <TeamProfileCard profile={p} key={p.firstName} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
