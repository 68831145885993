import {
  Box,
  Button,
  chakra,
  Flex,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContractType } from "../../../system/api/types";
import { ModalContext } from "../../../system/context/ModalContext";
import { useDeployContract } from "../../../system/hooks/ethereum/deploy/useDeployContract";
import { usePrevious } from "../../../system/hooks/usePrevious";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_HEADER_TEXT_COLOR,
  APP_SECONDARY_COLOR,
  HOMEPAGE_BACKGROUND_COLOR,
} from "../../../theme/colors";
import { TransactionPendingModal } from "../../Modals/TransactionPendingModal/TransactionPendingModal";
import { SuccessCollectionProps } from "./types";

export const SuccessCollection = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [props, setProps] = useState<SuccessCollectionProps>();
  const [isDeployed, setIsDeployed] = useState(false);

  const prevId = usePrevious(props?.id);

  const { uiText } = useUiText();
  const { walletModal } = React.useContext(ModalContext);
  const { deployAndVerify, verifyCollectionRequest, deployRequest } =
    useDeployContract(props?.contractType as ContractType);

  const handleDeployAndVerify = () => {
    if (!props?.collection) return;
    deployAndVerify(props.collection, props.id);
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      setProps(location.state);
      if (location.state.id !== prevId) {
        walletModal.onOpen(1);
      }
      setIsDeployed(location.state.deployed);
    } else {
      navigate("/home", { replace: true });
    }
  }, [location.state, navigate, walletModal, prevId]);

  return !props?.id ||
    verifyCollectionRequest.pending ||
    deployRequest.pending ? (
    <Flex h="100vh" w="100vw" alignItems="center"justifyContent="center">
      <TransactionPendingModal isOpen={true} onClose={() => null} />
      <Spinner size="xl" color={APP_SECONDARY_COLOR} />
    </Flex>
  ) : (
    <Box
      display={"flex"}
      justifyContent={"flex-start"}
      px={"150px"}
      w="100%"
      height="89vh"
      alignSelf={"stretch"}
    >
      <Flex justifyContent={"center"} flexDir={"column"} position="relative">
        <Flex flexDir={"row"} alignItems={"center"}>
          <Image
            src="/celebration.webp"
            width={props?.id !== prevId ? "240px" : "208px"}
            height={props?.id !== prevId ? "240px" : "208px"}
            objectFit={"contain"}
          />
          <Box>
            {props?.id !== prevId && (
              <chakra.h1
                fontSize={"36px"}
                textAlign={"left"}
                fontWeight={"semibold"}
                color={HOMEPAGE_BACKGROUND_COLOR}
              >
                {uiText.successCollectionTitle}
              </chakra.h1>
            )}
            <chakra.h1
              width="342px"
              fontSize={"41px"}
              textAlign={"left"}
              lineHeight={"57px"}
              fontWeight={"medium"}
              color={HOMEPAGE_BACKGROUND_COLOR}
            >
              {uiText.successCollectionSubTitle1} <br />
              {isDeployed
                ? uiText.successCollectionSubTitle2
                : uiText.successCollectionSubTitle4}
            </chakra.h1>
          </Box>
        </Flex>
        <Text
          fontFamily={"body"}
          color="#939393"
          fontSize={"16px"}
          textAlign={"left"}
        >
          {uiText.successCollectionSubTitle3}
        </Text>
        <Flex flexDir={"row"} mt="66px">
          {isDeployed ? (
            <Button
              width="182px"
              mr="12px"
              h="44px"
              fontSize="12px"
              onClick={() => walletModal.onOpen(1)}
              bgColor={HOMEPAGE_BACKGROUND_COLOR}
              color={APP_HEADER_TEXT_COLOR}
              fontFamily="heading"
              fontWeight="bold"
            >
              {uiText.successCollectionButton1}
            </Button>
          ) : (
            <Button
              width="182px"
              mr="12px"
              h="44px"
              fontSize="12px"
              onClick={handleDeployAndVerify}
              bgColor={HOMEPAGE_BACKGROUND_COLOR}
              color={APP_HEADER_TEXT_COLOR}
              fontFamily="heading"
              fontWeight={"bold"}
            >
              {uiText.deployCollection}
            </Button>
          )}
          <Button
            bgColor={APP_HEADER_TEXT_COLOR}
            width={"182px"}
            h="44px"
            fontSize={"12px"}
            borderWidth={"1px"}
            fontFamily="heading"
            fontWeight={"bold"}
            onClick={() => navigate("/create-collection", { replace: true })}
            borderColor={HOMEPAGE_BACKGROUND_COLOR}
          >
            {uiText.successCollectionButton2}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
