import React from "react";
import NoReceiptModal from "../../pages/Modals/NoReceiptModal/NoReceiptModal";
import { TransactionPendingModal } from "../../pages/Modals/TransactionPendingModal/TransactionPendingModal";
import { WalletModalContainer } from "../../pages/Modals/WalletModal/WalletModalContainer";
import {
  ModalController,
  useModalController,
} from "../hooks/useModalController";

interface IModalContext extends ModalController {}

export const ModalContext = React.createContext<IModalContext>(
  {} as IModalContext
);

export const ModalProvider: React.FC<{children: any}> = ({ children }) => {
  const modalController = useModalController();
  return (
    <ModalContext.Provider value={{ ...modalController }}>
      {children}
      <WalletModalContainer
        isOpen={modalController.walletModal.isOpen}
        onClose={modalController.walletModal.onClose}
        walletModalTabIndex={modalController.walletModalTabIndex}
        collectionId={modalController.collectionId}
      />
      <NoReceiptModal
        isOpen={modalController.noReceiptsModal.isOpen}
        onClose={modalController.noReceiptsModal.onClose}
      />
      <TransactionPendingModal
        isOpen={modalController.transactionPendingModal.isOpen}
        onClose={modalController.transactionPendingModal.onClose}
      />
    </ModalContext.Provider>
  );
};
