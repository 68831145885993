import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    body: "Open Sans",
    heading: "Montserrat",
  },
  colors: {
    green: {
      "50": "#99fecf",
      "100": "#99fecf",
      "200": "#99fecf",
      "300": "#99fecf",
      "400": "#99fecf",
      "500": "#99fecf",
      "600": "#99fecf",
      "700": "#99fecf",
      "800": "#99fecf",
      "900": "#99fecf",
    },
    appHeaderBackgroundDark: "#120f11",
    appHeaderBackgroundColor: "#1c171a",
    appHeaderTextColor: "#fff",
    appPrimaryColor: "#000",
    appImagePlaceholderColor: "#F1F1F1",
    appPrimaryButtonTextColor: "#000",
    appPrimaryContrastColor: "#fff",
    appSecondaryColor: "#95ffce",
    appSecondaryNeonColor: "#2FFFA7",
    appSecondaryColorDark: "#75c9a2",
    appPrimaryTextColor: "#565656",
    appDividerColor: "#00000029",
    appInfoBackgroundColor: "#292727",
    appTextInactiveColor: "#939393",
    appErrorColor: "#fe4166",
    appDescriptionColor: "#585757",
    appBorderColorDark: "#1A1818",
    appTabListSelectedColor: "#fff",
    moreInfoButtonColor: "#B2B2B2",
    walletModalHeaderBackgroundColor: "#0a0a0a",
    walletModalBodyBackgroundColor: "#fff",
    homePageBackgroundColor: "#121212",
    walletModalAltBackgroundColor: "#120f11",
    appWarningColor: "#FF2F67",
    appSecondaryLabel: "#FCEAB6",
    buttonBorderColor: "#99FECF",
    deleteButtonColor: "#FC355B",
    fileErrorColor: "#FC3469",
    descriptionColor: "#707070",
    collectorBorderColor: "#909090",
    uploadBoxTextColor: "#9C9B9B",
    collectionImageBackground: "#F6F6F6",
    collectionImageText: "#292727",
  },
  breakpoints: {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "150em",
  },
  components: {
    TabList: {
      defaultProps: {
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    Button: {
      defaultProps: {
        fontFamily: "'Montserrat', sans-serif",
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  }
});

export const scrollBarStyles = {
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
    borderRadius: "20px",
    backgroundColor: "white",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#95ffce",
  },
};

export const colors = {
  appHeaderBackgroundColor: "#1C171A",
  appHeaderTextColor: "#fff",
  appPrimaryColor: "#000",
  appImagePlaceholderColor: "#F1F1F1",
  appPrimaryButtonTextColor: "#000",
  appPrimaryContrastColor: "#fff",
  appSecondaryColor: "#95ffce",
  appSecondaryNeonColor: "#2FFFA7",
  appSecondaryColorDark: "#75c9a2",
  appPrimaryTextColor: "#565656",
  appDividerColor: "#00000029",
  appInfoBackgroundColor: "#292727",
  appTextInactiveColor: "#939393",
  appErrorColor: "#fe4166",
  appDescriptionColor: "#585757",
  appBorderColorDark: "#1A1818",
  appTabListSelectedColor: "#fff",
  moreInfoButtonColor: "#B2B2B2",
  walletModalHeaderBackgroundColor: "#0a0a0a",
  walletModalBodyBackgroundColor: "#fff",
  homePageBackgroundColor: "#121212",
  walletModalAltBackgroundColor: "#120f11",
  appWarningColor: "#FF2F67",
  appSecondaryLabel: "#FCEAB6",
  buttonBorderColor: "#99FECF",
  deleteButtonColor: "#FC355B",
  fileErrorColor: "#FC3469",
  descriptionColor: "#707070",
  collectorBorderColor: "#909090",
  uploadBoxTextColor: "#9C9B9B",
  collectionImageBackground: "#F6F6F6",
  collectionImageText: "#292727",
};
