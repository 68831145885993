import { Flex, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, IconButton, Spinner } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CollectionMintingOptionsViewProps } from "./types";
import { CollectionContractContext } from "./CollectionContractContext";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoIosAlert } from "react-icons/io";
import { colors } from "../../../theme/theme";
import { PrimaryButton } from "../../../components/buttons";
import { WalletContext } from "../../../system/context/WalletContext";
import { Collection } from "../../../system/api/types";
import { LEGACYContractContext } from "../../../system/context/LEGACYContractContext";
import { ApiContext } from "../../../system/api/ApiContext";

export const CollectionMintingOptionsView = ({
  goBack,
  goToPreMint,
  goToEnableMinting,
  goToProvenance,
  goToAllowList,
  goToAllowListMinting,
}: CollectionMintingOptionsViewProps) => {
  const { uiText } = useUiText();
  const { provenance, currentTokenBalance, contract, mintStatus, isLegacy } =
    React.useContext(CollectionContractContext);
  const { validation } = React.useContext(WalletContext);
  const [optionAlert, setOptionAlert] = React.useState(false);
  const [collection, setCollection] = React.useState<Collection>();
  const { hasReceipt } = useContext(LEGACYContractContext);
  const { getSingleCollection } = React.useContext(ApiContext);

  const handleEnableMinting = () => {
    if (
      (!provenance ||
        !collection?.allowListWallets.length ||
        (hasReceipt && !currentTokenBalance)) &&
      !optionAlert
    ) {
      setOptionAlert(true);
      return;
    }
    goToEnableMinting();
  };

  React.useEffect(() => {
    if (contract) {
      getSingleCollection(contract?.address, validation, () => goBack()).then(
        (collection) => {
          setCollection(collection);
        }
      );
    }
    // eslint-disable-next-line
  }, [contract, validation]);

  const renderMintingOption = (
    heading: string,
    description: string,
    onClick: () => void,
    isCompleted: boolean,
    showAlert: boolean
  ) => {
    return (
      <Flex
        key={heading}
       alignItems="center"
       justifyContent="space-between"
        onClick={onClick}
        borderBottom="0.5px #b8b8b8 solid"
        padding="15px"
        _hover={{
          cursor: "pointer",
          bgColor: isCompleted ? "transparent" : "#f2f2f2",
          boxShadow: "lg",
        }}
      >
        <Flex flexDir="column">
          <Text fontFamily="heading" fontWeight="bold" fontSize="18px">
            {heading}
          </Text>
          <Text color={APP_TEXT_INACTIVE_COLOR} fontSize="13px">
            {description}
          </Text>
        </Flex>
        {!showAlert && (
          <BsFillCheckCircleFill
            fontSize="30px"
            color={
              isCompleted
                ? colors.appSecondaryColor
                : colors.appTextInactiveColor
            }
          />
        )}
        {showAlert && (
          <IoIosAlert
            fontSize="30px"
            style={{
              color: "#fe4166",
            }}
          />
        )}
      </Flex>
    );
  };

  /**
   * Add here if adding more options to the list .
   */
  const mintingOptions = [
    {
      heading: uiText.provenance,
      description: uiText.mintingOptionsProvenance,
      isCompleted: !!provenance,
      alert: optionAlert && !provenance,
      onclick: goToProvenance,
    },
    {
      heading: uiText.preMint,
      description: uiText.mintingOptionsPreMint,
      isCompleted: !!currentTokenBalance,
      alert: optionAlert && !currentTokenBalance,
      onclick: mintStatus === 0 ? goToPreMint : () => null,
    },
    {
      heading: uiText.allowListFieldLabel,
      description: uiText.mintingOptionsAllowList,
      isCompleted: !!collection?.allowListWallets?.length,
      alert: optionAlert && !collection?.allowListWallets?.length,
      onclick: goToAllowList,
    },
    {
      heading: uiText.allowListMinting,
      description: uiText.enableAllowListMinting,
      isCompleted: mintStatus === 1,
      alert: optionAlert && mintStatus !== 1,
      onclick: goToAllowListMinting,
    },
    // TODO - Might not need this anymore (RF)
    // {
    //   heading: uiText.metadata,
    //   description: uiText.propagateToIpfs,
    //   isCompleted: collection?.status === "dataPinned",
    //   alert: optionAlert && mintStatus !== 1,
    //   onclick: goToMetadata,
    // },
    {
      heading: uiText.publicMinting,
      description: uiText.mintingOptionsPublicMinting,
      isCompleted: false,
      alert: false,
      onclick: handleEnableMinting,
    },
  ];

  if (!collection) {
    return <Spinner m="auto" color={APP_SECONDARY_COLOR} size="lg" />;
  }

  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow={"auto"}
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="30px">
          {!optionAlert && (
            <Text color={APP_TEXT_INACTIVE_COLOR} fontSize="14px">
              {uiText.mintingOptionsDescription}
            </Text>
          )}
          {optionAlert && (
            <Text color={APP_ERROR_COLOR} fontSize="14px" mb="40px">
              {uiText.mintingOptionsWarning}
            </Text>
          )}
          {!hasReceipt &&
            mintingOptions
              .filter((m) => m.heading !== "Pre-Mint")
              .map((option) =>
                renderMintingOption(
                  option.heading,
                  option.description,
                  option.onclick,
                  option.isCompleted,
                  option.alert
                )
              )}
          {hasReceipt &&
            !isLegacy &&
            mintingOptions.map((option) =>
              renderMintingOption(
                option.heading,
                option.description,
                option.onclick,
                option.isCompleted,
                option.alert
              )
            )}
          {hasReceipt &&
            isLegacy &&
            mintingOptions
              .filter((m) => m.heading !== "Allow List")
              .filter((m) => m.heading !== "Allow List Minting")

              .map((option) =>
                renderMintingOption(
                  option.heading,
                  option.description,
                  option.onclick,
                  option.isCompleted,
                  option.alert
                )
              )}
          {optionAlert && (
            <PrimaryButton
              mt="50px"
              text={uiText.continue}
              onClick={goToEnableMinting}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};
