import { Flex, Heading, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, IconButton } from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../../../components/buttons";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useUiText } from "../../../system/hooks/useUiText";
import { APP_TEXT_INACTIVE_COLOR } from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CollectionEnableAllowMintingViewProps } from "./types";
import { CollectionContractContext } from "./CollectionContractContext";
import { ModalContext } from "../../../system/context/ModalContext";
import { FiAlertCircle } from "react-icons/fi";
import { colors } from "../../../theme/theme";
import { FaEdit } from "react-icons/fa";

export const CollectionEnableAllowMintingView = ({
  goBack,
  goToCollectionDetails,
  goToPreMint,
  collection,
}: CollectionEnableAllowMintingViewProps) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const { uiText } = useUiText();
  const { transactionPending, changeMintStatus, currentTokenBalance } =
    React.useContext(CollectionContractContext);
  const { transactionPendingModal } = React.useContext(ModalContext);

  const handleEnableAllowListMinting = async (continueAnyway?: boolean) => {
    if (
      currentTokenBalance === 0 &&
      !continueAnyway &&
      collection.contractType !== "Erc-721"
    ) {
      setShowAlert(true);
      return;
    }
    changeMintStatus(1);
  };

  React.useEffect(() => {
    if (transactionPending) {
      transactionPendingModal.onOpen();
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionPending, transactionPendingModal]);

  if (showAlert) {
    return (
      <Box
        h="100%"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        overflow="auto"
      >
        <Box>
          <ModalHeader>
            <IconButton
              aria-label="button"
              position="absolute"
              top="5px"
              right="5px"
              onClick={() => goBack()}
              size="lg"
              bg="transparent"
              _hover={{ cursor: "pointer" }}
              _focus={{ border: "none" }}
              icon={<AiOutlineArrowLeft />}
            />
          </ModalHeader>
          <Flex
            flexDir="column"
            p="30px"
            mt="100px"
           justifyContent="center"
           alignItems="center"
          >
            <FiAlertCircle size="104px" color={colors.appWarningColor} />

            <Heading fontSize="28px" mb="10px">
              Are you sure
            </Heading>
            <Text color={APP_TEXT_INACTIVE_COLOR} textAlign="center">
              You won’t be able to set these options below after you enable
              minting.
            </Text>
            <SecondaryButton
              onClick={goToPreMint}
              text={uiText.preMint}
              mt="10px"
              rightIcon={<FaEdit />}
              border="none"
            />
          </Flex>
        </Box>
        <Flex placeSelf="center" flexDir="column" w="205px" mb="25px">
          <PrimaryButton
            mb="10px"
            text={uiText.continue}
            onClick={() => handleEnableAllowListMinting(true)}
          />
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="auto"
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="30px" mt="100px">
          <Heading fontSize="28px" mb="10px">
            {uiText.allowListMinting}
          </Heading>
          <Text color={APP_TEXT_INACTIVE_COLOR}>
            {uiText.enableAllowListMinting}
          </Text>
        </Flex>
      </Box>
      <Flex placeSelf="center" flexDir="column" w="205px" mb="25px">
        <PrimaryButton
          mb="10px"
          text={uiText.enableNow}
          onClick={() => handleEnableAllowListMinting()}
        />
        <SecondaryButton
          isLoading={transactionPending}
          text={uiText.skipAndEnableLater}
          border="none"
          onClick={() => goBack()}
        />
      </Flex>
    </Box>
  );
};
