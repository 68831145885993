import { Button } from "@chakra-ui/button";
import {
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../theme/colors";
import { ButtonProps } from "./types";

export const SecondaryButton = ({ text, dark, ...rest }: ButtonProps) => {
  return (
    <Button
      _hover={{ backgroundColor: APP_TEXT_INACTIVE_COLOR }}
      _focus={{ border: "none" }}
      borderColor={APP_SECONDARY_COLOR || rest.borderColor}
      p="25px 40px 25px 40px"
      fontFamily="'Montserrat', sans-serif"
      fontWeight="bold"
      fontSize={rest.fontSize ? rest.fontSize : "12px"}
      variant="outline"
      color={dark ? APP_PRIMARY_CONTRAST_COLOR : APP_PRIMARY_COLOR}
      {...rest}
    >
      {text}
    </Button>
  );
};
