import { Flex, Text } from "@chakra-ui/layout";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  chakra,
} from "@chakra-ui/react";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_NEON_COLOR,
} from "../../../theme/colors";
import { TransactionPendingModalProps } from "./types";

export const TransactionPendingModal = ({
  isOpen,
  onClose,
}: TransactionPendingModalProps) => {
  const { uiText } = useUiText();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          mb={0}
          maxWidth="388px"
          height="663px"
          top={3}
          position="absolute"
          right={{ base: undefined, md: "85px" }}
          bgColor="transparent"
        >
          <Flex
            flexDir="column"
            h="100%"
           alignItems="center"
           justifyContent="space-around"
            bgColor={"appHeaderBackgroundDark"}
            p={10}
            borderRadius={"12px"}
          >
            <Flex flexDir="column"alignItems="center">
              <chakra.h2
                fontSize={"25px"}
                fontWeight={"bold"}
                width={"200px"}
                color={APP_SECONDARY_NEON_COLOR}
                textAlign="center"
              >
                {uiText.processingTransaction}
              </chakra.h2>
            </Flex>
            <Image
              src={"/loading_animation.webp"}
              w="293px"
              h="293px"
              objectFit={"contain"}
            />
            <Text
              fontWeight={"semibold"}
              fontSize={"14px"}
              fontFamily={"heading"}
              color={APP_PRIMARY_CONTRAST_COLOR}
            >
              {uiText.transactionInProgressMessage}
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};
