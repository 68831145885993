import { ContractFactory, utils } from "ethers";
import {
  MasterchefMasatoshiJuniorX__factory,
  MasterchefMasatoshiJunior__factory,
  MasterchefMasatoshiV2__factory,
} from "../../../../contracts/abiTypes";
import {
  DAO_DELEGATE_MAINNET,
  DAO_DELEGATE_ROPSTEN,
  OMAKASEA,
  SIGNER_ADDRESS,
} from "../../../constants";

const masterchefMasatoshiDao_json = require("../../../../contracts/MasterchefMasatoshi-v2.json");
const masterchefMasatoshiJunior_json = require("../../../../contracts/MasterchefMasatoshiJunior.json");
const masterchefMasatoshiJuniorX_json = require("../../../../contracts/MasterchefMasatoshiJuniorX.json");

export const deployJuniorContract = async (
  data: {
    name: string;
    symbol: string;
    total: string;
    cost: string;
    allowListMintPrice: string;
    maxAllowedMints: string;
  },
  signer: any,
  account: string,
  setPending: () => void
) => {
  const factory = new ContractFactory(
    masterchefMasatoshiJunior_json["abi"],
    masterchefMasatoshiJunior_json["bytecode"],
    signer
  ) as MasterchefMasatoshiJunior__factory;
  const { name, symbol, total, cost, allowListMintPrice, maxAllowedMints } =
    data;

  const contract = await factory
    .deploy(
      name,
      symbol,
      total,
      utils.parseEther(cost.toString()),
      utils.parseEther(allowListMintPrice.toString()),
      maxAllowedMints,
      SIGNER_ADDRESS,
      OMAKASEA,
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    )
    .then((c) => {
      setPending();
      return c;
    })
    .catch((err: any) => console.log(err));
  await contract?.deployTransaction.wait(3).catch((err: any) => {
    console.log(err);
  });
  const deployTxHash = contract?.deployTransaction.hash;
  const contractAddress = contract?.address;
  return {
    deployTxHash,
    contractAddress,
  };
};

export const deployJuniorXContract = async (
  data: {
    name: string;
    symbol: string;
    total: string;
    cost: string;
    allowListMintPrice: string;
    maxAllowedMints: string;
  },
  signer: any,
  account: string,
  setPending: () => void
) => {
  const factory = new ContractFactory(
    masterchefMasatoshiJuniorX_json["abi"],
    masterchefMasatoshiJuniorX_json["bytecode"],
    signer
  ) as MasterchefMasatoshiJuniorX__factory;
  const { name, symbol, total, cost, allowListMintPrice, maxAllowedMints } =
    data;

  const contract = await factory
    .deploy(
      name,
      symbol,
      total,
      utils.parseEther(cost.toString()),
      utils.parseEther(allowListMintPrice.toString()),
      maxAllowedMints,
      SIGNER_ADDRESS,
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    )
    .then((c) => {
      setPending();
      return c;
    })
    .catch((err: any) => console.log(err));

  await contract?.deployTransaction.wait(3).catch((err: any) => {
    console.log(err);
  });
  const deployTxHash = contract?.deployTransaction.hash;
  const contractAddress = contract?.address;
  return {
    deployTxHash,
    contractAddress,
  };
};

export const deployDaoContract = async (
  data: {
    name: string;
    symbol: string;
    total: string;
    cost: string;
    allowListMintPrice: string;
    maxAllowedMints: string;
  },
  signer: any,
  account: string,
  setPending: () => void,
  chainId: number
) => {
  const factory = new ContractFactory(
    masterchefMasatoshiDao_json["abi"],
    masterchefMasatoshiDao_json["bytecode"],
    signer
  ) as MasterchefMasatoshiV2__factory;
  const { name, symbol, total, cost, allowListMintPrice, maxAllowedMints } =
    data;

  const contract = await factory
    .deploy(
      name,
      symbol,
      total,
      utils.parseEther(cost.toString()),
      utils.parseEther(allowListMintPrice.toString()),
      maxAllowedMints,
      SIGNER_ADDRESS,
      chainId === 1 ? DAO_DELEGATE_MAINNET : DAO_DELEGATE_ROPSTEN,
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    )
    .then((c) => {
      setPending();
      return c;
    })
    .catch((err: any) => console.log(err));
  await contract?.deployTransaction.wait(3).catch((err: any) => {
    console.log(err);
  });
  const deployTxHash = contract?.deployTransaction.hash;
  const contractAddress = contract?.address;
  return {
    deployTxHash,
    contractAddress,
  };
};
