import { Flex, Text, InputProps, Switch } from "@chakra-ui/react";
import { FieldAttributes, useField } from "formik";

export type SwitchInputProps = {
  label: string;
  id: string;
} & FieldAttributes<any> &
  InputProps;

export const SwitchInput = ({ label, image, ...props }: SwitchInputProps) => {
  const [field, meta] = useField(props);
  return (
    <Flex flex={1} flexDir="column" alignItems="flex-start" mt="50px">
      <Text
        fontFamily="heading"
        fontWeight="semibold"
        fontSize={"20px"}
        mb="10px"
        mt="50px"
      >
        {label}
      </Text>
      <Switch
        {...field}
        {...props}
        colorScheme="gray"
        isInvalid={meta.touched && meta.error}
        maxW="50px"
        size="lg"
      />
    </Flex>
  );
};
