import "./App.css";
import { AppContainer } from "./layout/AppContainer/AppContainer";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./layout/ScrollToTop/ScrollToTop";
import CustomizeMintForm from "./pages/CustomizeMintForm/CustomizeMintForm";
import { AddUser } from "./pages/AddUser/AddUser";
import mixpanel from "mixpanel-browser";
import React, { Suspense, lazy } from "react";
import { SuccessCollection } from "./pages/CreateCollection/SuccessCollection/SuccessCollection";
import { DaoContainer } from "./pages/DaoDashboard/DaoContainer";
import { TeamPage } from "./pages/TeamPage/TeamPage";
import { useAccount } from "wagmi";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const Unicorn = lazy(() => import("./pages/Unicorn/Unicorn"));
const ChiaPlants = lazy(() => import("./pages/ChiaPlants/ChiaPlants"));
const Fish = lazy(() => import("./pages/Fish/Fish"));
const BanDel = lazy(() => import("./pages/BanDel/BanDel"));
const EightiesBabies = lazy(
  () => import("./pages/EightiesBabies/EightiesBabies")
);
const MintContainer = lazy(() => import("./pages/Mint/MintContainer"));
const CreateCollectionContainer = lazy(
  () => import("./pages/CreateCollection/CreateCollectionContainer")
);
const UseForFreePage = lazy(
  () => import("./pages/UseForFreePage/UseForFreePage")
);
const ReviewCollection = lazy(
  () => import("./pages/CreateCollection/ReviewCollection/ReviewCollection")
);

const App = () => {
  const { address: account } = useAccount();
  React.useEffect(() => {
    if (account && process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
      mixpanel.track("Visit", { account: account });
    }
  }, [account]);

  return (
    <div className="App">
      <ScrollToTop>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/freemium" element={<UseForFreePage />} />
            <Route
              path="/create-collection"
              element={<CreateCollectionContainer />}
            />
            <Route path="/review-collection" element={<ReviewCollection />} />
            <Route
              path="/success-collection"
              element={
                <AppContainer>
                  <SuccessCollection />
                </AppContainer>
              }
            />
            <Route
              path="/team"
              element={
                <AppContainer>
                  <TeamPage />
                </AppContainer>
              }
            />
            <Route path="/mint/:address" element={<MintContainer />} />
            <Route
              path="/customize/:address"
              element={
                <AppContainer>
                  <CustomizeMintForm />
                </AppContainer>
              }
            />
            <Route
              path="/dao/:address"
              element={
                <AppContainer>
                  <DaoContainer />
                </AppContainer>
              }
            />
            <Route
              path="/add-user"
              element={
                <AppContainer>
                  <AddUser />
                </AppContainer>
              }
            />
            <Route path="/unicorn" element={<Unicorn />} />
            <Route path="/cacti" element={<ChiaPlants />} />
            <Route path="/fish" element={<Fish />} />
            <Route path="/bandel" element={<BanDel />} />
            <Route path="/eighties-babies" element={<EightiesBabies />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </div>
  );
};

export default App;
