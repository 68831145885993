import { Flex } from "@chakra-ui/react";
import { AppFooter } from "../AppFooter/AppFooter";
import { AppHeader } from "../AppHeader/AppHeader";

export const AppContainer: React.FC<{children: any}>= ({ children }) => {
  return (
    <>
      <AppHeader />
      <Flex paddingTop={0} justifyContent="center" overflow="hidden">
        <Flex h="100%" w="100%">
          {children}
        </Flex>
      </Flex>
      <AppFooter />
    </>
  );
};
