import {
  Heading,
  Button,
  Box,
  ModalHeader,
  Text,
  Flex,
  IconButton,
  Divider,
  Tooltip,
  Image,
  Spinner,
} from "@chakra-ui/react";
import {
  APP_ERROR_COLOR,
  APP_IMAGE_PLACEHOLDER_COLOR,
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { CollectionDetailsProps } from "./types";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { PrimaryButton } from "../../../components/buttons";
import { useUiText } from "../../../system/hooks/useUiText";
import { AiFillQuestionCircle } from "react-icons/ai";
import React from "react";
import { ModalContext } from "../../../system/context/ModalContext";
import { CollectionContractContext } from "./CollectionContractContext";
import { RoundIconImageButton } from "../../../components/buttons/RoundIconImageButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CopyButton } from "../../../components/buttons/CopyButton";
import { MintStatus } from "../../../system/hooks/ethereum/useContract";
import { useContractLEGACY } from "../../../system/hooks/ethereum/useContractLEGACY";
import { FiAlertCircle } from "react-icons/fi";
import { usePrevious } from "../../../system/hooks/usePrevious";
import { useNetwork } from "wagmi";
import { WarningButton } from "../../../components/buttons/WarningButton";

const INFO_ICON_BG_COLOR = "#b2b2b2";

export const CollectionDetails = ({
  collection,
  onBackToCollections,
  goToRevealCollection,
  goToMintingOptions,
}: CollectionDetailsProps) => {
  const { uiText } = useUiText();
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const { walletModal } = React.useContext(ModalContext);
  const { transactionPendingModal } = React.useContext(ModalContext);

  const {
    baseUri,
    enableMinting,
    initializing,
    saleIsActive,
    mintStatus,
    totalSupply,
    transactionPending,
    maxTotalSupply,
    withDrawEth,
    init,
    contractError,
    contractBalance,
    isConvertedToDao,
    convertToDao,
    getIsDao,
  } = React.useContext(CollectionContractContext);

  const legacyContract = useContractLEGACY(collection.contractAddress);
  const enableMintingLEGACY = legacyContract.enableMinting;
  const saleIsActiveLEGACY = legacyContract.saleIsActive;
  const prevInitializing = usePrevious(initializing);

  const [loading, setLoading] = React.useState(true);

  const handleWithdrawEth = () => {
    withDrawEth(collection.contractAddress).then(() => {
      init();
    });
  };

  const handleEnableMinting = async () => {
    if (
      collection.contractType !== "Erc-721" &&
      collection.contractType !== "Erc-721 DAO"
    ) {
      enableMintingLEGACY();
    }
    enableMinting();
  };

  const handleSale = (mintStatus: MintStatus) => {
    if (mintStatus !== 2) goToMintingOptions();
    if (mintStatus === 2) handleEnableMinting();
  };

  const handleLegacySale = (saleIsActive: boolean) => {
    if (!saleIsActive) goToMintingOptions();
    if (saleIsActive) handleEnableMinting();
  };

  React.useEffect(() => {
    if (transactionPending) {
      transactionPendingModal.onOpen();
    }
  }, [transactionPending, transactionPendingModal]);

  React.useEffect(() => {
    init && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (prevInitializing && !initializing) {
      setLoading(false);
      getIsDao();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevInitializing, initializing]);

  const renderEnableMintingButtons = () => {
    if (!collection.contractType) {
      return saleIsActiveLEGACY ? (
        <WarningButton
          w="160px"
          text={uiText.pauseMinting}
          onClick={handleEnableMinting}
          fontFamily="heading"
          fontWeight="bold"
          fontSize="12px"
        />
      ) : (
        <PrimaryButton
          text={mintStatus === 2 ? uiText.enableMinting : uiText.mintingOptions}
          onClick={() => handleLegacySale(saleIsActiveLEGACY)}
          w="160px"
        />
      );
    }
    if (collection.contractType) {
      return saleIsActive && mintStatus === 2 ? (
        <WarningButton
          w="160px"
          text={uiText.pauseMinting}
          onClick={handleEnableMinting}
          fontFamily="heading"
          fontWeight="bold"
          fontSize="12px"
        />
      ) : (
        <PrimaryButton
          text={mintStatus === 2 ? uiText.enableMinting : uiText.mintingOptions}
          onClick={() => handleSale(mintStatus)}
          w="160px"
        />
      );
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  const renderCollectionImage = () => {
    return (
      <Image
        h="236px"
        w="210px"
        objectFit="cover"
        borderRadius="5px"
        src={collection.collectionImage}
        bgColor={APP_IMAGE_PLACEHOLDER_COLOR}
        mr="32px"
      />
    );
  };

  if (contractError) {
    return (
      <Flex
       justifyContent="center"
       alignItems="center"
        height="100%"
        flexDir="column"
        p="50px"
      >
        <IconButton
          aria-label="button"
          position="absolute"
          top={2}
          right={5}
          onClick={onBackToCollections}
          size="lg"
          bg="transparent"
          _focus={{ border: "none" }}
          _hover={{ cursor: "pointer" }}
          icon={<AiOutlineArrowLeft />}
        />
        <FiAlertCircle
          style={{ color: "red", fontSize: "100px", marginBottom: "20px" }}
        />
        <Text>
          There was a problem loading this collection. Please check your network
          or try again later.
        </Text>
      </Flex>
    );
  }

  if (loading) {
    return (
      <Flex justifyContent="center"alignItems="center" height="100%">
        <Spinner color={APP_SECONDARY_COLOR} />
      </Flex>
    );
  }

  return (
    <Box
      overflow={"auto"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      pb={10}
    >
      <Box>
        <ModalHeader position="relative">
          <Text
            fontFamily="body"
            fontWeight="normal"
            color={APP_TEXT_INACTIVE_COLOR}
            fontSize="12px"
          >
            {collection?.symbol}
          </Text>
          <Heading fontSize="28px" fontWeight="bold">
            {collection?.name}
          </Heading>
          <IconButton
            aria-label="button"
            position="absolute"
            top={2}
            right={5}
            onClick={onBackToCollections}
            size={"lg"}
            bg="transparent"
            _focus={{ border: "none" }}
            _hover={{ cursor: "pointer" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="5px 30px 30px 30px">
          <Flex mb={5}>
            {renderCollectionImage()}
            <Flex flexDir="column" justifyContent="center">
              <Box>
                <Text
                  fontFamily="heading"
                  fontSize="11px"
                  fontWeight="bold"
                  w="80px"
                >
                  {uiText.createdOn}
                </Text>
                <Text fontSize="9px">
                  {moment(collection.dateCreated).format("LL")}
                </Text>
              </Box>
              <Box mt="32px" w="80px">
                <Text fontFamily="heading" fontWeight="bold" fontSize="11px">
                  {uiText.totalMinted}
                </Text>
                <Text fontSize="9px">
                  {totalSupply} of {maxTotalSupply}
                </Text>
              </Box>
              <Box mt="32px">
                <RoundIconImageButton
                  imgSource="/etherscan.png"
                  alt="Etherscan"
                  onClick={() =>
                    openInNewTab(
                      chain?.name === "ropsten"
                        ? `https://ropsten.etherscan.io/address/${collection.contractAddress}`
                        : `https://etherscan.io/address/${collection.contractAddress}`
                    )
                  }
                />
                <RoundIconImageButton
                  imgSource="/ipfs.png"
                  alt="IPFS"
                  disabled={!collection.metaDataBaseUrl}
                  onClick={() => {
                    const metaDataBaseUrl = collection.metaDataBaseUrl;
                    const ipfs = metaDataBaseUrl?.split("ipfs://")[1];
                    openInNewTab(`https://ipfs.io/ipfs/${ipfs}`);
                  }}
                />
                <RoundIconImageButton
                  imgSource="/opensea.png"
                  alt="Opensea"
                  disabled
                />
              </Box>
            </Flex>
          </Flex>
          <Flex flexDir="column">
            <Flex justifyContent="space-between" mb={3}>
              <Box>
                <Text fontFamily="heading" fontWeight="bold" fontSize="11px">
                  {uiText.contractAddress}
                </Text>
                <Text
                  mt="4px"
                  w="238px"
                  fontFamily="body"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color={APP_TEXT_INACTIVE_COLOR}
                  fontSize="10px"
                >
                  {collection.contractAddress}
                </Text>
              </Box>
              <CopyButton
                text={collection.contractAddress}
                bgColor={APP_PRIMARY_CONTRAST_COLOR}
                _hover={{ bgColor: "white" }}
              />
            </Flex>
            <Divider mb={3} />
            <Flex
              flexDir="row"
              alignItems="center"
             justifyContent="space-between"
              mb={3}
            >
              <Box>
                <Flex alignItems="center">
                  <Text
                    fontFamily="heading"
                    fontWeight="bold"
                    fontSize="11px"
                    mr="10px"
                  >
                    {uiText.contractBalance}
                  </Text>
                  <Tooltip
                    label={uiText.withdrawFundsTooltip}
                    placement="top"
                    fontFamily="body"
                    fontWeight="bold"
                    fontSize="11px"
                    borderRadius="5px"
                    p="12px"
                    maxW={200}
                    hasArrow
                    shouldWrapChildren
                  >
                    <AiFillQuestionCircle
                      color={INFO_ICON_BG_COLOR}
                      cursor="pointer"
                      size="11px"
                    />
                  </Tooltip>
                </Flex>
                <Flex flexDir={"row"} fontFamily={"row"} mt={"4px"}>
                  <Flex flex={"col"}>
                    <Text
                      fontSize={"10px"}
                      fontFamily={"body"}
                      fontWeight={"normal"}
                      color={APP_TEXT_INACTIVE_COLOR}
                      mr={1}
                    >
                      {contractBalance}
                    </Text>
                    <Text
                      fontFamily={"body"}
                      fontSize="10px"
                      color={APP_TEXT_INACTIVE_COLOR}
                    >
                      {uiText.ethereumAbbr}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Button
                color="black"
                borderColor={APP_PRIMARY_COLOR}
                variant="outline"
                borderRadius="20px"
                disabled={contractBalance === 0}
                onClick={handleWithdrawEth}
                fontSize="8px"
                w="87px"
                h="23px"
                fontFamily={"body"}
                fontWeight={"bold"}
              >
                <Text>{uiText.withdrawETHButtonLabel}</Text>
              </Button>
            </Flex>
            <Divider mb={3} />
            <Flex justifyContent="space-between" mb={3}>
              <Box>
                <Flex flexDir="column">
                  <Flex>
                    <Text
                      fontFamily={"heading"}
                      fontWeight={"bold"}
                      fontSize="11px"
                      mr={"10px"}
                    >
                      {baseUri
                        ? uiText.collectionRevealed
                        : uiText.revealCollection}
                    </Text>

                    <Tooltip
                      label={uiText.revealCollectionMessage}
                      placement="top"
                      hasArrow
                      shouldWrapChildren
                      fontFamily="body"
                      fontWeight="bold"
                      fontSize="11px"
                      borderRadius="5px"
                      p="12px"
                    >
                      <AiFillQuestionCircle
                        color={INFO_ICON_BG_COLOR}
                        cursor="pointer"
                        size="11px"
                      />
                    </Tooltip>
                  </Flex>
                  {!collection.metaDataBaseUrl && (
                    <Text color={APP_ERROR_COLOR} fontSize="11px">
                      {uiText.metadataBaseUrlUndefinedError}
                    </Text>
                  )}
                </Flex>
              </Box>
              {collection?.metaDataBaseUrl &&
                collection?.metaDataBaseUrl !== baseUri && (
                  <Button
                    color="black"
                    borderColor={APP_PRIMARY_COLOR}
                    variant="outline"
                    borderRadius="20px"
                    fontSize="8px"
                    fontFamily="body"
                    fontWeight="bold"
                    w="87px"
                    h="23px"
                    onClick={() => goToRevealCollection()}
                  >
                    <Text>{uiText.revealCollection}</Text>
                  </Button>
                )}
            </Flex>
            <Divider mb={3} />

            {collection.contractType === "Erc-721 DAO" && (
              <Flex justifyContent="space-between" mb={3}>
                <Box>
                  <Flex alignItems={"center"}>
                    <Text
                      fontFamily={"heading"}
                      fontWeight={"bold"}
                      fontSize="11px"
                      mr={"10px"}
                    >
                      {!isConvertedToDao
                        ? "Convert to Dao"
                        : "Converted to Dao"}
                    </Text>
                  </Flex>
                </Box>
                {!isConvertedToDao && (
                  <Button
                    color="black"
                    borderColor={APP_PRIMARY_COLOR}
                    variant="outline"
                    borderRadius="20px"
                    fontSize="8px"
                    fontFamily="body"
                    fontWeight="bold"
                    w="87px"
                    h="23px"
                    onClick={() => convertToDao()}
                  >
                    <Text>Convert To Dao</Text>
                  </Button>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
      {collection.deployStatus === "DEPLOYED" && (
        <Flex mx="30px" flexDir="row" justifyContent="space-between">
          <PrimaryButton
            w="160px"
            mr="10px"
            backgroundColor={APP_PRIMARY_COLOR}
            _hover={{
              opacity: 0.8,
            }}
            color={APP_PRIMARY_CONTRAST_COLOR}
            text={uiText.mintPage}
            onClick={() => {
              walletModal.onClose();
              navigate(`/customize/${collection.contractAddress}`, {
                state: { collection },
              });
            }}
          />
          <Flex justifyContent="center">{renderEnableMintingButtons()}</Flex>
        </Flex>
      )}
    </Box>
  );
};
