import { Flex, Heading, Text } from "@chakra-ui/layout";
import { ModalHeader, IconButton } from "@chakra-ui/react";
import { PrimaryButton } from "../../../components/buttons";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useUiText } from "../../../system/hooks/useUiText";
import { APP_TEXT_INACTIVE_COLOR } from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import React from "react";
import { CollectionContractContext } from "./CollectionContractContext";
import { CollectionRevealViewProps } from "./types";

export const CollectionRevealView = ({
  goBack,
  collection,
}: CollectionRevealViewProps) => {
  const { uiText } = useUiText();
  const { revealCollection, transactionPending } = React.useContext(
    CollectionContractContext
  );

  const handleRevealCollection = () => {
    revealCollection(collection.metaDataBaseUrl).finally(() => {
      goBack();
    });
  };

  return (
    <>
      <ModalHeader>
        <IconButton
          aria-label="button"
          position="absolute"
          top="5px"
          right="5px"
          onClick={() => goBack()}
          size="lg"
          bg="transparent"
          _hover={{ cursor: "pointer" }}
          _focus={{ border: "none" }}
          icon={<AiOutlineArrowLeft />}
        />
      </ModalHeader>
      <Flex flexDir="column" p="30px" mt="100px">
        <Heading mb={5}>{uiText.revealCollection}</Heading>
        <Text color={APP_TEXT_INACTIVE_COLOR}>
          {uiText.revealCollectionMessage}
        </Text>
        <Flex position="absolute" bottom="25px" right="25%" flexDir={"column"}>
          <PrimaryButton
            mb="10px"
            text={uiText.revealCollection}
            onClick={handleRevealCollection}
            isLoading={transactionPending}
          />
          <SecondaryButton
            text={uiText.cancel}
            border="none"
            onClick={() => goBack()}
          />
        </Flex>
      </Flex>
    </>
  );
};
