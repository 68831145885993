import { ethers } from "ethers";
import React from "react";
import {
  LEGACYContract,
  useContractLEGACY,
} from "../hooks/ethereum/useContractLEGACY";

interface IContractContext extends LEGACYContract {}

export const LEGACYContractContext = React.createContext<IContractContext>({
  contract: {} as ethers.Contract,
  hasReceipt: false,
  mintToken: (amount: number) => null,
  preMint: (amount: number) => null,
  transactionPending: false,
  transactionSuccessful: false,
  transactionUnSuccessful: false,
  resetTransactionState: () => null,
  tokenIds: [],
  enableMinting: () => null,
  saleIsActive: false,
  tokenBalancePending: false,
  currentTokenBalance: 0,
  withDrawEth: (address: string) =>
    Promise.resolve({} as any),
  totalSupply: 0,
  getTotalSupply: () => null,
  revealCollection: (uri: string) => null,
  baseUri: undefined as unknown as string,
  setProvenance: (provenanceHash: string) => null,
  provenance: undefined as unknown as string,
  initializing: false,
  init: () => Promise.resolve({} as any),
  mintPrice: 0,
  maxTotalSupply: 0 as number,
  // eslint-disable-next-line
  deployContract: ({}) => Promise.resolve({} as any),
  contractError: false,
  errorMessage: ""
});

export const LEGACYContractProvider: React.FC<{children: any}> = React.memo(({ children }) => {
  const contract = useContractLEGACY();

  return (
    <LEGACYContractContext.Provider value={{ ...contract }}>
      {children}
    </LEGACYContractContext.Provider>
  );
});
