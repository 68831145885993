import { Flex, Text } from "@chakra-ui/layout";
import { chakra, Image } from "@chakra-ui/react";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_NEON_COLOR,
} from "../../../theme/colors";

export const WalletModalTransactionPendingView = () => {
  const { uiText } = useUiText();
  return (
    <>
      <Flex
        maxWidth="388px"
        height="663px"
        flexDir="column"
       alignItems="center"
        top={3}
        bgColor={"appHeaderBackgroundDark"}
        borderRadius={"12px"}
      >
        <Flex flexDir="column"alignItems="center">
          <chakra.h2
            fontSize={"25px"}
            fontWeight={"bold"}
            width={"200px"}
            color={APP_SECONDARY_NEON_COLOR}
            textAlign="center"
          >
            {uiText.processingTransaction}...
          </chakra.h2>
        </Flex>
        <Image src={"/loading_animation.webp"} mb="50px" maxW="350px" />
        <Text color={APP_PRIMARY_CONTRAST_COLOR} mb="50px">
          {uiText.transactionInProgressMessage}
        </Text>
      </Flex>
    </>
  );
};
