import { Flex } from "@chakra-ui/layout";
import { Text, Image, Button } from "@chakra-ui/react";
import { useContext } from "react";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { CollectorContext } from "../../../system/context/CollectorContext";
import { WalletContext } from "../../../system/context/WalletContext";
import useDeviceDetect from "../../../system/hooks/useDeviceDetect";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_DIVIDER_COLOR,
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
} from "../../../theme/colors";
import { WalletModalConnectViewProps } from "./types";

export const WalletModalConnectView = ({
  onClose,
}: WalletModalConnectViewProps) => {
  const { uiText } = useUiText();
  const { isMobile } = useDeviceDetect();
  const { isMintPage } = useContext(CollectorContext);
  const { account } = useContext(WalletContext);
  const isMobileMint = isMobile && isMintPage;
  const wallets = [
    { src: "/metamask-fox.svg", text: uiText.metamask },
    { src: "/walletConnect.png", text: uiText.walletConnect },
    { src: "/safepal.png", text: uiText.safepal },
    { src: "/trustWallet.png", text: uiText.trustWallet },
    { src: "/tokenPocket.png", text: uiText.tokenPocket },
  ];

  const renderWalletSelection = (
    src: string,
    text: string,
    last: boolean,
    index: number
  ) => {
    const connect = () => {
    };

    return (
      <Flex
        key={`wallet-${text}`}
        w="100%"
        flexDirection="row"
        alignItems="center"
        _hover={{ cursor: "pointer", backgroundColor: "#f2f2f2" }}
        onClick={connect}
        borderBottom={last ? "none" : "1px solid"}
        borderColor={APP_DIVIDER_COLOR}
        py={"20px"}
        pl={1}
      >
        <Image src={src} w="37px" h="37px" mr="34px" objectFit={"contain"} />
        <Text
          fontFamily="heading"
          fontWeight="medium"
          fontSize={"16px"}
          color={APP_PRIMARY_COLOR}
        >
          {text}
        </Text>
      </Flex>
    );
  };

  if (account) {
    return <div />;
  }

  return (
    <Flex
      position={"relative"}
      flexDir="column"
      height="100%"
      paddingY="48px"
      paddingX="43px"
      overflow="auto"
    >
      {isMobileMint && (
        <Button
          onClick={onClose}
          _hover={{
            borderColor: "transparent",
            bgColor: APP_PRIMARY_CONTRAST_COLOR,
          }}
          _active={{
            borderColor: "transparent",
            bgColor: APP_PRIMARY_CONTRAST_COLOR,
          }}
          _focus={{
            borderColor: "transparent",
            bgColor: APP_PRIMARY_CONTRAST_COLOR,
          }}
          top={0}
          w={"full"}
          position={"absolute"}
          alignSelf="center"
          bgColor={APP_PRIMARY_CONTRAST_COLOR}
        >
          <Image
            src={"/arrow-down.png"}
            w="40px"
            h="30px"
            objectFit={"contain"}
          />
        </Button>
      )}
      <Text
        fontSize={"28px"}
        fontWeight="bold"
        fontFamily={"heading"}
        mb="23px"
      >
        {uiText.selectWallet}
      </Text>
      <Flex flexDir="column" h="100%"alignItems="center">
        {wallets.map((w, i) =>
          renderWalletSelection(w.src, w.text, i === wallets.length - 1, i)
        )}
      </Flex>
      {!isMobileMint && (
        <SecondaryButton
          borderColor={APP_PRIMARY_COLOR}
          alignSelf="center"
          width={"205px"}
          fontFamily={"heading"}
          py="15px"
          mt={"20px"}
          text={uiText.cancel}
          onClick={() => onClose()}
        />
      )}
    </Flex>
  );
};
