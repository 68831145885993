// require(mintStatus == MintStatus.AllowList && !paused, "s");
// require(totalSupply() + amount <= maxPossibleSupply, "m");
// require(hashMessage(msg.sender, address(this)) == messageHash, "i");
// require(verifyAddressSigner(messageHash, signature), "f");
// require(totalMintsPerAddress[msg.sender] + amount <= maxAllowedMints, "l");
// require(mintAmount <= msg.value, "a");

export const useErrorStatus = (error: string) => {
  const message = () => {
    const errorCode = error?.split(":")[1];
    const isErrorCode = errorCode && errorCode.replace(/ /g, "").length === 1;
    const errorStatus = isErrorCode ? errorCode.replace(/ /g, "") : error;

    if (errorStatus === "l")
      return "Amount exceeds the max allowed mints for this address. Reduce the amount and try again";

    if (errorStatus === "s") return "Sale is currently paused";

    if (errorStatus === "i") return "";

    if (errorStatus === "f") return "Authentication error";

    if (errorStatus === "a") return "Not enough eth";

    if (errorStatus === "m")
      return "Amount exceeds the remaining supply. Reduce the amount and try again";

    return errorStatus;
  };

  return { message: message() };
};
