import React from "react";
import { ApiContext } from "../api/ApiContext";
import { Collection } from "../api/types";
import { WalletContext } from "../context/WalletContext";

export const useCollections = () => {
  const { account, validation } = React.useContext(WalletContext);
  const { getCollections } = React.useContext(ApiContext);
  const [collections, setCollections] = React.useState<Collection[]>();

  const getAllCollections = async () => {
    if (account && validation) {
      getCollections(account, validation).then((collections) => {
        setCollections(collections);
      });
    }
  };

  return {
    getAllCollections,
    collections,
  };
};
