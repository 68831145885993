import { FileWithPath } from "react-dropzone";

export type ApiCollection = {
  _id: string;
  date: number;
  collectionName: string;
  symbol: string;
  maxSupply: number;
  price: string;
  currencyAddress: string;
  creatorAddress: string;
  contractType: ContractType;
  imgBaseUrl: string;
  metaDataBaseUrl: string;
  provenanceHash: string;
  deployTxHash: string;
  paidTxHash: string;
  contractAddress: string;
  network: string;
  status:
    | "imgPinned"
    | "dataPinned"
    | "created"
    | "imgPinFailed"
    | "metadataPinFailed"
    | "deployed";
  mintPageImgBaseUrl: string;
  mintPageDesktopImageUrl: string;
  mintPageMobileImageUrl: string;
  discord: string;
  instagram: string;
  opensea: string;
  telegram: string;
  twitter: string;
  allowListMintPrice: string;
  allowListWallets: string[];
  maxAllowedMints: number;
  description: string;
  revealOnMint: boolean;
  tempImageUrl: string;
};

export type DeployStatusType = "DEPLOYED" | "NOTDEPLOYED" | "PROCESSING";

export type Collection = {
  id: string;
  symbol: string;
  name: string;
  collectionImage: string;
  contractAddress: string;
  contractBalance: string;
  contractType: ContractType;
  creatorAddress: string;
  dateCreated: string;
  cost: string;
  total: number;
  deployStatus: DeployStatusType;
  metaDataBaseUrl: string;
  provenanceHash: string;
  mintPageImgBaseUrl: string;
  mintPageDesktopImageUrl: string;
  mintPageMobileImageUrl: string;
  network: string;
  discord: string;
  instagram: string;
  opensea: string;
  telegram: string;
  twitter: string;
  allowListMintPrice: string;
  maxAllowedMints: number;
  allowListWallets: string[];
  revealOnMint: boolean;
  tempImageUrl: string;
  status:
    | "imgPinned"
    | "dataPinned"
    | "created"
    | "imgPinFailed"
    | "metadataPinFailed"
    | "deployed";
};

export type MintPage = {
  validation: any;
  id: string;
  discord: string;
  twitter: string;
  instagram: string;
  opensea: string;
  telegram: string;
  mintImages: Array<FileWithPath>;
};

export type MintPageContent = {
  mintPageDesktopImageUrl: string;
  mintPageMobileImageUrl: string;
  price: string;
  discord: string;
  instagram: string;
  opensea: string;
  telegram: string;
  twitter: string;
  removeBrandingMintPage: boolean;
  revealOnMint: boolean;
};

export type ContractType = "Erc-721X" | "Erc-721" | "Erc-721 DAO";

export type RequestState = {
  pending: boolean;
  success: boolean;
  error: boolean;
};

export interface IApiContext {
  getCollections: (
    creatorAddress: string,
    validation: string
  ) => Promise<Collection[]>;
  createCollection: (data: FormData) => Promise<any>;
  previewCollection: (
    data: FormData,
    onSuccess: (images: string[]) => void,
    onFailure: (err: any) => void
  ) => Promise<void>;
  verifyCollection: (
    data: any,
    onSuccess: () => void,
    onFailure: (err: any) => void
  ) => Promise<void>;
  getSingleCollection: (
    contractAddress: string,
    validation: string,
    onFailure: () => void
  ) => Promise<Collection>;
  getMintPageContent: (contractAddress: string) => Promise<any>;
  getNftImageById: (
    contractAddress: string,
    nftIds: string[],
    validation: string
  ) => Promise<any>;
  updateMintPage: (
    data: any,
    success: () => void,
    failure: () => void
  ) => Promise<any>;
  uploadPlaceholderMetaData: (data: any) => Promise<any>;
  uploadAllowList: (
    list: string[],
    validation: string,
    address: string,
    success: () => void,
    failure: () => void
  ) => Promise<void>;
  generateSigAllowList: (
    contractAddress: string,
    validation: string,
    success: () => void,
    failure: () => void
  ) => Promise<
    | { messageHash: string; signature: string }
    | { messageHash: string; signature: string }
  >;
  authenticateFreeUser: (validation: string) => Promise<void>;
  addAddressToFreeList: (
    account: string,
    validation: string,
    onSuccess: () => void
  ) => Promise<void>;
  setUploadAllowListRequest: (status: RequestState) => void;
  setUpdateMintPageRequest: (status: RequestState) => void;
  setCreateCollectionsRequest: (status: RequestState) => void;
  setPreviewCollectionRequest: (status: RequestState) => void;
  setAddToFreeListRequest: (status: RequestState) => void;
  getCollectionsRequest: RequestState;
  createCollectionsRequest: RequestState;
  previewCollectionRequest: RequestState;
  verifyCollectionRequest: RequestState;
  singleCollectionRequest: RequestState;
  mintPageContentRequest: RequestState;
  nftImagesRequest: RequestState;
  updateMintPageRequest: RequestState;
  uploadPlaceholderRequest: RequestState;
  uploadAllowListRequest: RequestState;
  addToFreeListRequest: RequestState;
  apiError: string;
  authenticateFreeUserRequest: RequestState;
}

export const requestInitialState: RequestState = {
  pending: false,
  success: false,
  error: false,
};

export const requestPendingState: RequestState = {
  pending: true,
  success: false,
  error: false,
};

export const requestSuccessState: RequestState = {
  pending: false,
  success: true,
  error: false,
};

export const requestErrorState: RequestState = {
  pending: false,
  success: false,
  error: true,
};
