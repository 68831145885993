import { ethers } from "ethers";
import React from "react";
import { ModalContext } from "../../../system/context/ModalContext";
import {
  Contract,
  useContract,
} from "../../../system/hooks/ethereum/useContract";
import { useContractLEGACY } from "../../../system/hooks/ethereum/useContractLEGACY";

interface IContractContext extends Contract {}

export const CollectionContractContext = React.createContext<IContractContext>({
  errorStatus: "",
  isLegacy: false,
  contract: {} as ethers.Contract,
  hasReceipt: false,
  mintToken: (amount: number) => Promise.resolve({} as any),
  preMint: (amount: number) => null,
  transactionPending: false,
  transactionSuccessful: false,
  transactionUnSuccessful: false,
  resetTransactionState: () => null,
  tokenIds: [],
  enableMinting: () => null,
  saleIsActive: false,
  tokenBalancePending: false,
  currentTokenBalance: 0,
  withDrawEth: (address: string) =>
    Promise.resolve({} as any),
  totalSupply: 0,
  mintStatus: undefined as unknown as number,
  changeMintStatus: () => Promise.resolve(0 as any),
  allowListMintPrice: 0,
  getTotalSupply: () => null,
  revealCollection: (uri: string) => Promise.resolve("" as any),
  baseUri: undefined as unknown as string,
  setProvenance: (provenanceHash: string) => null,
  provenance: undefined as unknown as string,
  initializing: false,
  mintPrice: undefined as unknown as number,
  maxTotalSupply: 0,
  // eslint-disable-next-line
  init: () => Promise.resolve({} as any),
  contractError: false,
  maxAllowedMints: 0,
  contractBalance: 0,
  getAllVotings: () => Promise.resolve({} as any),
  getName: () => Promise.resolve({} as any),
  votings: [],
  name: "",
  percentToVote: 0,
  signVoting: () => Promise.resolve({} as any),
  activateVoting: () => Promise.resolve({} as any),
  convertToDao: () => Promise.resolve({} as any),
  getIsDao: () => Promise.resolve({} as any),
  createVoting: () => Promise.resolve({} as any),
  isConvertedToDao: false,
});

export const CollectionContractProvider: React.FC<{children: any,
  contractAddress: string;
  isLegacy: boolean;
}> = ({ children, contractAddress, isLegacy }) => {
  const contract = useContract(contractAddress);
  const legacyContract = useContractLEGACY(contractAddress);
  const { transactionPendingModal } = React.useContext(ModalContext);

  const collectionContract = isLegacy
    ? (legacyContract as unknown as Contract)
    : contract;

  React.useEffect(() => {
    if (
      collectionContract.transactionPending &&
      !transactionPendingModal.isOpen
    ) {
      transactionPendingModal.onOpen();
    }

    if (
      !collectionContract.transactionPending &&
      transactionPendingModal.isOpen
    ) {
      transactionPendingModal.onClose();
    }
    // eslint-disable-next-line
  }, [contract.transactionPending, legacyContract.transactionPending]);

  return (
    <CollectionContractContext.Provider
      value={{ ...collectionContract, isLegacy }}
    >
      {children}
    </CollectionContractContext.Provider>
  );
};
