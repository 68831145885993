import {
  Flex,
  ModalCloseButton,
  Image,
  chakra,
  Button,
  ModalContent,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../system/context/ModalContext";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_HEADER_TEXT_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_WARNING_COLOR,
  BUTTON_BORDER_COLOR,
  WALLET_MODAL_ALT_BG_COLOR,
} from "../../../theme/colors";
import { NoReceiptModalProps } from "./types";

function NoReceiptModal({ isOpen, onClose }: NoReceiptModalProps) {
  const { uiText } = useUiText();
  const { walletModal } = React.useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          mb={0}
          height="663px"
          bg="transparent"
          position="absolute"
          right={{ base: undefined, md: "85px" }}
        >
          <ModalCloseButton color={APP_PRIMARY_CONTRAST_COLOR} />
          <Flex
            flexDir="column"
            h="100%"
           alignItems="center"
            justifyContent={"center"}
            bgColor={WALLET_MODAL_ALT_BG_COLOR}
            p={"10px"}
            borderRadius={8}
            overflow="auto"
          >
            <Flex flexDir="column"alignItems="center">
              <chakra.h1
                color={APP_WARNING_COLOR}
                textAlign={"center"}
                fontSize={"28px"}
                fontWeight={"bold"}
              >
                {uiText.noReceiptsTitle}
              </chakra.h1>
              <chakra.h1
                mt="11px"
                color={APP_HEADER_TEXT_COLOR}
                textAlign={"center"}
                fontSize={"12px"}
              >
                {uiText.noReceiptsSubTitle}
              </chakra.h1>
            </Flex>
            <Image
              src="/chef.webp"
              height={"332px"}
              width={"332px"}
              objectFit={"contain"}
            />
            <Button
              w={"205px"}
              height={"44px"}
              color={APP_HEADER_TEXT_COLOR}
              bgColor={WALLET_MODAL_ALT_BG_COLOR}
              borderWidth={1}
              borderColor={BUTTON_BORDER_COLOR}
              fontSize={"12px"}
              _hover={{ color: BUTTON_BORDER_COLOR }}
              onClick={() => walletModal.onOpen()}
            >
              {uiText.mintReceipt}
            </Button>
            <Button
              w={"205px"}
              height={"44px"}
              mt="10px"
              color={APP_HEADER_TEXT_COLOR}
              bgColor={WALLET_MODAL_ALT_BG_COLOR}
              borderWidth={1}
              borderColor={BUTTON_BORDER_COLOR}
              fontSize={"12px"}
              _hover={{ color: BUTTON_BORDER_COLOR }}
              onClick={() => {
                navigate("/freemium");
                onClose();
              }}
            >
              Sign Up For Free
            </Button>
            <Button
              height={"44px"}
              color={APP_HEADER_TEXT_COLOR}
              bgColor={WALLET_MODAL_ALT_BG_COLOR}
              fontSize={"12px"}
              _hover={{ color: BUTTON_BORDER_COLOR }}
              as="a"
              href="/home#receipt-section"
              onClick={() => onClose()}
            >
              {uiText.learnMore}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NoReceiptModal;
