import {
  Avatar,
  Box,
  Flex,
  Heading,
  Spinner,
  Square,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import React from "react";
import { PrimaryButton } from "../../components/buttons";
import { CopyButton } from "../../components/buttons/CopyButton";
import { MasterchefMasatoshi } from "../../contracts/abiTypes";
import { useContract } from "../../system/hooks/ethereum/useContract";
import {
  APP_HEADER_BACKGROUND_COLOR,
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";
import { CreateProposalForm } from "./CreateProposalForm";
import { DaoDashboardTableRow } from "./DaoDashboardDataRow";
import { Abi } from "./types";
import { useSetAbiFunctions } from "./useSetAbiFunctions";
import { AiOutlinePlus } from "react-icons/ai";
import { usePrevious } from "../../system/hooks/usePrevious";
import { TransactionPendingModal } from "../Modals/TransactionPendingModal/TransactionPendingModal";
import { ErrorNotificationModal } from "../Modals/ErrorNotificationModal/ErrorNotificationModal";
import { useUiText } from "../../system/hooks/useUiText";
import axios from "axios";
import {
  ETHERSCAN_API_KEY,
  MAINNET_ETHERSCAN_API_URL,
  ROPSTEN_ETHERSCAN_API_URL,
} from "../../system/constants";
import { convertIpfsToIpfsUrl } from "../../system/api/mappers";
import { useProvider, useNetwork, useAccount } from "wagmi";

const masterchefMasatoshi_json = require("../../contracts/MasterchefMasatoshi.json");
const masterchefMasatoshiV2_json = require("../../contracts/MasterchefMasatoshi-v2.json");

type DaoDashboardProps = {
  contractAddress: string;
};

export const DaoDashboard = ({ contractAddress }: DaoDashboardProps) => {
  const [showCreateProposal, setShowCreateProposal] = React.useState(false);
  const [abi, setAbi] = React.useState<Abi>([]);
  const [address, setAddress] = React.useState("");
  const [members, setMembers] = React.useState(0);
  const [votingTime, setVotingTime] = React.useState(0);
  const [sortedVotings, setSortedVotings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [membersLoading, setMembersLoading] = React.useState(true);
  const [isMember, setIsMember] = React.useState(false);
  const [image, setImage] = React.useState("");

  const provider = useProvider();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const { address: account } = useAccount();
  const {
    percentToVote,
    name,
    votings,
    contract,
    signVoting,
    activateVoting,
    initializing,
    transactionPending,
    transactionUnSuccessful,
    resetTransactionState,
    createVoting,
  } = useContract(contractAddress, true);
  const { functions } = useSetAbiFunctions(abi);
  const prevInitializing = usePrevious(initializing);
  const { uiText } = useUiText();

  const getVotingTime = async () => {
    const contract = new ethers.Contract(
      address,
      masterchefMasatoshi_json["abi"],
      provider
    ) as MasterchefMasatoshi;

    contract
      ?.votingDuration()
      .then((t) => {
        setVotingTime(t.toNumber());
      })
      .catch((err) => console.log("votingDuration", err));
  };

  const confirmMember = async () => {
    const contract =
      (new ethers.Contract(
        address,
        masterchefMasatoshi_json["abi"],
        provider
      ) as MasterchefMasatoshi);

    if (!account) return;

    contract
      ?.balanceOf(account)
      .then((t) => {
        if (t.toNumber() === 0) {
          return setIsMember(false);
        }
        setIsMember(true);
      })
      .catch(() => {});

    const uri = await contract?.tokenURI(0);

    await axios
      .get(convertIpfsToIpfsUrl(uri || ""))
      .then((res) => {
        setImage(convertIpfsToIpfsUrl(res.data.image));
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        }
      });
  };

  const getTransactions = async (contractAddress: string) => {
    await axios
      .get(
        `https://${
          chainId === 3 ? ROPSTEN_ETHERSCAN_API_URL : MAINNET_ETHERSCAN_API_URL
        }/api?module=logs&action=getLogs&fromBlock=0&toBlock=latest&address=${contractAddress}&topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef&apikey=${ETHERSCAN_API_KEY}`
      )
      .then((res) => {
        const ownerAddresses = res.data.result.map((t: any) => t.topics[2]);
        const members = [...new Set(ownerAddresses)];
        setMembers(members.length);
        setMembersLoading(false);
        return res.data;
      })
      .catch((err) => {
        setMembersLoading(false);
        if (err.response) {
          console.log(err);
        }
      });
  };

  React.useEffect(() => {
    if (!contractAddress) return;
    setAddress(contractAddress);
    if (provider) {
      getTransactions(contractAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, chainId]);

  React.useEffect(() => {
    if (contract) {
      getVotingTime();
      confirmMember();
      const combined = [
        ...masterchefMasatoshiV2_json["abi"],
        ...masterchefMasatoshi_json["abi"],
      ];
      const abi = combined.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );

      setAbi(abi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  React.useEffect(() => {
    if (votings?.length && votingTime) {
      setSortedVotings(
        votings.slice().sort((a, b) => {
          return b.timestamp.toNumber() - a.timestamp.toNumber();
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votingTime, votings]);

  React.useEffect(() => {
    if (prevInitializing && !initializing) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevInitializing, initializing]);

  const renderTable = () => {
    return (
      <Flex
        backgroundColor={APP_PRIMARY_COLOR}
        borderColor={APP_SECONDARY_COLOR}
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="8px"
        margin="auto"
        mt="50px"
        maxW="100%"
        overflow="auto"
        mx="3px"
      >
        <TableContainer w="100%">
          <Flex justifyContent="space-between" alignItems="center" p="20px">
            <Heading
              color={APP_PRIMARY_CONTRAST_COLOR}
              fontSize={{ base: "14px", md: "20px" }}
            >
              Proposals List
            </Heading>
            <PrimaryButton
              text="Create Proposal"
              leftIcon={<AiOutlinePlus />}
              onClick={() => setShowCreateProposal(true)}
            />
          </Flex>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="white"></Th>
                <Th color="white">Title</Th>
                <Th color="white">Status</Th>
                <Th color="white">Created</Th>
                <Th color="white">Time Left</Th>
                <Th color="white"></Th>
              </Tr>
            </Thead>
            <Tbody color="white">
              {sortedVotings.map((rowData, i) => (
                <DaoDashboardTableRow
                  key={`dao-table-row${i}`}
                  rowData={rowData}
                  votingTime={votingTime}
                  abi={abi}
                  signVoting={signVoting}
                  members={members}
                  percentToVote={percentToVote}
                  activateVoting={activateVoting}
                  membersLoading={membersLoading}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    );
  };

  if (loading && !isMember) {
    return (
      <Flex
        h="100vh"
        w="100%"
        pb="50px"
       alignItems="center"
       justifyContent="center"
        flexDir="column"
        backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
      >
        <Spinner color={APP_SECONDARY_COLOR} size="xl" />
      </Flex>
    );
  }

  if (!isMember) {
    return (
      <Flex
        h="100%"
        w="100%"
        pb="50px"
       alignItems="center"
       justifyContent="center"
        flexDir="column"
        backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
      >
        <Text fontSize="18px">{uiText.daoNotAMemberMessage}</Text>
      </Flex>
    );
  }

  return (
    <Flex
      h="100%"
      minH="100vh"
      w="100%"
      pb="50px"
      flexDir="column"
      backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
    >
      {transactionPending && (
        <TransactionPendingModal
          isOpen={transactionPending}
          onClose={() => null}
        />
      )}
      {transactionUnSuccessful && (
        <ErrorNotificationModal
          onCloseAction={() => resetTransactionState()}
          errorStatus="Transaction failed"
        />
      )}
      <Flex
        backgroundColor={APP_HEADER_BACKGROUND_COLOR}
        minH="150px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Avatar size="2xl" mb="-55px" mr="44px" src={image} />
      </Flex>
      <Flex justifyContent="center" mt="70px" flexWrap="wrap">
        <Square
          size="100px"
          backgroundColor="black"
          borderRadius="8px"
          mr="44px"
          fontSize="12px"
          color="white"
          flexDir="column"
          fontWeight="bold"
        >
          {uiText.daoQuorumText}
          <Text fontSize="28px" fontWeight="bold">
            {percentToVote}%
          </Text>
        </Square>
        <Flex flexDir="column" mr="44px">
          <Heading alignSelf="center" fontFamily="body" fontWeight="semibold">
            {name}
          </Heading>
          <Flex mt="10px" justifyContent="center" alignItems="center">
            <Text
              mr="20px"
              fontSize="18px"
              maxW={{ base: "200px", md: "100%" }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {address}
            </Text>
            <CopyButton
              text={address}
              backgroundColor="black"
              color="white"
              w="14px"
              h="28px"
            />
          </Flex>
        </Flex>
        <Square
          size="100px"
          backgroundColor="black"
          borderRadius="8px"
          mr="44px"
          fontSize="12px"
          color="white"
          flexDir="column"
          fontWeight="bold"
        >
          {uiText.daoMembersText}
          <Box fontSize="28px" fontWeight="bold">
            {membersLoading ? <Spinner /> : members}
          </Box>
        </Square>
      </Flex>
      {showCreateProposal && (
        <CreateProposalForm
          goBack={() => setShowCreateProposal(false)}
          functions={functions}
          abi={abi}
          daoAddress={address}
          transactionPending={transactionPending}
          createVoting={createVoting}
        />
      )}
      {!showCreateProposal && renderTable()}
    </Flex>
  );
};
