import React from "react";
import {
  ApiCollection,
  Collection,
  IApiContext,
  requestErrorState,
  requestInitialState,
  RequestState,
  requestSuccessState,
} from "./types";
import { utils } from "ethers";
import { mapApiCollectionToCollection, mintPageContentMapper } from "./mappers";
import { apiGet, apiMake, apiCreate } from "./api";
import { RequestPendingModal } from "../../pages/Modals/RequestPendingModal/RequestPendingModal";
import mixpanel from "mixpanel-browser";
import { ErrorNotificationModal } from "../../pages/Modals/ErrorNotificationModal/ErrorNotificationModal";
import { useUiText } from "../hooks/useUiText";

export const ApiContext = React.createContext<IApiContext>({
  getCollections: (creatorAddress: string, validation: string) =>
    Promise.resolve([] as any),
  createCollection: (data: FormData) => Promise.resolve({} as any),
  previewCollection: (
    data: FormData,
    onSuccess: (images: string[]) => void,
    onFailure: (err: any) => void
  ) => Promise.resolve({} as any),
  verifyCollection: (
    data: FormData,
    onSuccess: (res: any) => void,
    onFailure: (err: any) => void
  ) => Promise.resolve({} as any),
  getSingleCollection: (
    contractAddress: string,
    validation: string,
    onFailure: (err: any) => void
  ) => Promise.resolve({} as any),
  getMintPageContent: (contractAddress: string) => Promise.resolve({} as any),
  getNftImageById: (
    contractAddress: string,
    nftIds: string[],
    validation: string
  ) => Promise.resolve({} as any),
  updateMintPage: (data: any, success: () => void, failure: () => void) =>
    Promise.resolve({} as any),
  uploadPlaceholderMetaData: (data: any) => Promise.resolve({} as any),
  uploadAllowList: (
    list: string[],
    validation: string,
    address: string,
    success: () => void,
    failure: () => void
  ) => Promise.resolve({} as any),
  generateSigAllowList: (
    contractAddress: string,
    validation: string,
    success: () => void,
    failure: () => void
  ) => Promise.resolve({} as any),
  authenticateFreeUser: () => null as unknown as any,
  addAddressToFreeList: (
    account: string,
    validation: string,
    onSuccess: () => void
  ) => Promise.resolve({} as any),
  setUploadAllowListRequest: () => null,
  setUpdateMintPageRequest: () => null,
  setCreateCollectionsRequest: () => null,
  setPreviewCollectionRequest: () => null,
  setAddToFreeListRequest: () => null,
  getCollectionsRequest: requestInitialState,
  createCollectionsRequest: requestInitialState,
  previewCollectionRequest: requestInitialState,
  verifyCollectionRequest: requestInitialState,
  singleCollectionRequest: requestInitialState,
  mintPageContentRequest: requestInitialState,
  nftImagesRequest: requestInitialState,
  updateMintPageRequest: requestInitialState,
  uploadPlaceholderRequest: requestInitialState,
  uploadAllowListRequest: requestInitialState,
  addToFreeListRequest: requestInitialState,
  apiError: "",
  authenticateFreeUserRequest: requestInitialState,
});

export const ApiProvider: React.FC<{children: any}> = ({ children }) => {
  const { uiText } = useUiText();

  const [getCollectionsRequest, setGetCollectionsRequest] =
    React.useState<RequestState>(requestInitialState);

  const [createCollectionsRequest, setCreateCollectionsRequest] =
    React.useState<RequestState>(requestInitialState);

  const [previewCollectionRequest, setPreviewCollectionRequest] =
    React.useState<RequestState>(requestInitialState);

  const [verifyCollectionRequest, setVerifyCollectionRequest] =
    React.useState<RequestState>(requestInitialState);

  const [singleCollectionRequest, setSingleCollectionRequest] =
    React.useState<RequestState>(requestInitialState);

  const [mintPageContentRequest, setMintPageContentRequest] =
    React.useState<RequestState>(requestInitialState);

  const [nftImagesRequest, setNftImagesRequest] =
    React.useState<RequestState>(requestInitialState);

  const [updateMintPageRequest, setUpdateMintPageRequest] =
    React.useState<RequestState>(requestInitialState);

  const [uploadPlaceholderRequest, setUploadPlaceholderRequest] =
    React.useState<RequestState>(requestInitialState);

  const [uploadAllowListRequest, setUploadAllowListRequest] =
    React.useState<RequestState>(requestInitialState);

  const [addToFreeListRequest, setAddToFreeListRequest] =
    React.useState<RequestState>(requestInitialState);

  const [authenticateFreeUserRequest, setAuthenticateFreeUserRequestRequest] =
    React.useState<RequestState>(requestInitialState);

  const [apiError, setApiError] = React.useState("");

  const getCollections = async (
    creatorAddress: string,
    validation: string
  ): Promise<Collection[]> => {
    creatorAddress = utils.getAddress(creatorAddress);
    setGetCollectionsRequest({ ...getCollectionsRequest, pending: true });

    const data: { collections: ApiCollection[] } = await apiGet
      .post("/getCollectionsByCreatorAddress", { creatorAddress, validation })
      .then((res) => {
        setGetCollectionsRequest(requestSuccessState);
        return res.data;
      })
      .catch((err) => {
        setGetCollectionsRequest(requestErrorState);

        if (err.response) {
          console.log("getCollectionsAPI", err.response);
        }
      });

    const collections = data?.collections?.map((c) =>
      mapApiCollectionToCollection(c)
    );
    return collections;
  };

  const createCollection = async (data: FormData) => {
    setCreateCollectionsRequest({ ...createCollectionsRequest, pending: true });
    mixpanel.track("Attempted Collection Create");

    const link: string = await apiMake
      .post("/make", data)
      .then((res) => {
        setCreateCollectionsRequest(requestSuccessState);
        mixpanel.track("Successful Collection Create");
        return res.data.metaDataBaseUrl;
      })
      .catch((res) => {
        setCreateCollectionsRequest(requestErrorState);
        const createError = res.response?.data.error;
        console.log("Submit Collection", createError);
        setApiError(createError);
        mixpanel.track("Unsuccessful Collection Create", {
          error: createError,
        });
      });

    return link;
  };

  const previewCollection = async (
    data: FormData,
    onSuccess: (images: string[]) => void,
    onFailure: (err: any) => void
  ) => {
    setPreviewCollectionRequest({ ...previewCollectionRequest, pending: true });

    await apiMake
      .post<string[]>("/generatePreview", data)
      .then((res) => {
        setPreviewCollectionRequest(requestSuccessState);
        onSuccess(res.data);
      })
      .catch((err) => {
        const createError = err.response?.data?.error;
        setApiError(createError);
        setPreviewCollectionRequest(requestErrorState);
        onFailure(err);
      });
  };

  const verifyCollection = async (
    data: any,
    onSuccess: () => void,
    onFailure: (err: any) => void
  ) => {
    setVerifyCollectionRequest({ ...verifyCollectionRequest, pending: true });

    await apiGet
      .post("/verify", data)
      .then((res) => {
        setVerifyCollectionRequest(requestSuccessState);
        onSuccess();
      })
      .catch((err) => {
        onFailure(err);
        setVerifyCollectionRequest(requestErrorState);

        if (!err.response) {
          // network error
          Promise.reject("Error: Network Error");
        } else {
          const createError = err.response?.data?.error;
          setApiError(createError);
          Promise.reject(err);
        }
      });
  };

  const getSingleCollection = async (
    contractAddress: string,
    validation: string,
    onFailure: () => void
  ): Promise<Collection> => {
    setSingleCollectionRequest({ ...singleCollectionRequest, pending: true });

    const data = await apiGet
      .post("/getCollectionByContractAddress", { contractAddress, validation })
      .then((res) => {
        setSingleCollectionRequest(requestSuccessState);
        return res.data;
      })
      .catch((err) => {
        setSingleCollectionRequest(requestErrorState);
        onFailure();
        if (err.response) {
          console.log("getCollectionByContractAddress", err.response);
        }
      });
    if (!data) return {} as Collection;
    const collection = mapApiCollectionToCollection(data.collections);
    return collection;
  };

  const getMintPageContent = async (contractAddress: string): Promise<any> => {
    setMintPageContentRequest({ ...mintPageContentRequest, pending: true });

    const data = await apiGet
      .post("/getMintPageContent", { contractAddress })
      .then((res) => {
        setMintPageContentRequest(requestSuccessState);
        return res.data;
      })
      .catch((err) => {
        setMintPageContentRequest(requestErrorState);
        console.log(err);
        if (err.response) {
          console.log("getMintPageContent", err.response);
        }
      });

    const mintPageContent = mintPageContentMapper(data);
    return mintPageContent;
  };

  const getNftImageById = async (
    contractAddress: string,
    nftIds: string[],
    validation: string
  ): Promise<any> => {
    setNftImagesRequest({ ...nftImagesRequest, pending: true });

    const data = await apiGet
      .post("/getNftImagesByIds", { nftIds, validation, contractAddress })
      .then((res) => {
        setNftImagesRequest(requestSuccessState);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setNftImagesRequest(requestErrorState);
        if (err.response) {
          console.log("getNftImagesByIds", err.response);
        }
      });

    return data;
  };

  const updateMintPage = async (
    data: any,
    success: () => void,
    failure: () => void
  ) => {
    setUpdateMintPageRequest({ ...updateMintPageRequest, pending: true });

    const mintPage = await apiCreate
      .post("/uploadMintPage", data)
      .then((res) => {
        setUpdateMintPageRequest(requestSuccessState);
        success();
        return res.data;
      })
      .catch((err) => {
        setUpdateMintPageRequest(requestErrorState);
        if (!err.response) {
          // network error
          Promise.reject("Error: Network Error");
        } else {
          failure();
          const createError = err.response?.data?.error;
          setApiError(createError);
          console.log("uploadMintPageApi", err.response);
        }
      });

    return mintPage;
  };

  const uploadPlaceholderMetaData = async (data: any) => {
    setUploadPlaceholderRequest({ ...uploadPlaceholderRequest, pending: true });

    const placeholderMetaData = await apiMake
      .post("/uploadPlaceholderMetaData", data)
      .then((res) => {
        setUploadPlaceholderRequest(requestSuccessState);
        return res.data.metaDataBaseUrl;
      })
      .catch((err) => {
        setUploadPlaceholderRequest(requestErrorState);
        console.log(err);
        if (err.response) {
          console.log("uploadPlaceholderMetaData", err.response);
        }
      });

    return placeholderMetaData;
  };

  const uploadAllowList = async (
    list: string[],
    validation: string,
    address: string,
    success: () => void,
    failure: () => void
  ) => {
    setUploadAllowListRequest({ ...uploadAllowListRequest, pending: true });

    const w = JSON.stringify({
      wallets: list,
    });

    const data = {
      wallets: w,
      contractAddress: address,
      validation,
    };

    await apiGet
      .post("/uploadAllowList", data)
      .then((res) => {
        setUploadAllowListRequest(requestSuccessState);
        success();
        return res.data;
      })
      .catch((err) => {
        setUploadAllowListRequest(requestErrorState);
        if (err.response) {
          console.log("uploadAllowList", err.response);
          failure();
        }
      });
  };

  const generateSigAllowList = async (
    contractAddress: string,
    validation: string,
    success: () => void,
    failure: () => void
  ) => {
    const data = {
      contractAddress,
      validation,
    };
    const result = await apiGet
      .post<{ messageHash: string; signature: string }>(
        "/generateSigAllowList",
        data
      )
      .then((res) => {
        success();
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        failure();
        return {} as { messageHash: string; signature: string };
      });
    return result;
  };

  const authenticateFreeUser = async (validation: string) => {
    setAuthenticateFreeUserRequestRequest({
      ...authenticateFreeUserRequest,
      pending: true,
    });
    await apiGet
      .post("/authenticateFreeUser", { validation })
      .then((res) => {
        setAuthenticateFreeUserRequestRequest(requestSuccessState);
        return res.data;
      })
      .catch((err) => {
        setAuthenticateFreeUserRequestRequest(requestErrorState);
        if (err.response) {
        }
      });
  };

  const addAddressToFreeList = async (
    account: string,
    validation: string,
    onSuccess: () => void
  ) => {
    setAddToFreeListRequest({ ...addToFreeListRequest, pending: true });

    await apiGet
      .post("/updateFreeUser", { account, validation })
      .then((res) => {
        setAddToFreeListRequest(requestSuccessState);
        onSuccess();
        return res.data;
      })
      .catch((err) => {
        setAddToFreeListRequest(requestErrorState);
        if (err.response) {
          console.log("updateFreeUser", err);
        }
      });
  };

  return (
    <>
      {updateMintPageRequest.pending && (
        <RequestPendingModal
          title="Update Mint Page"
          subtitle="updating mint page content"
          onCloseAction={() => setUpdateMintPageRequest(requestInitialState)}
        />
      )}
      {updateMintPageRequest.error && (
        <ErrorNotificationModal
          onCloseAction={() => setUpdateMintPageRequest(requestInitialState)}
          errorStatus={apiError}
        />
      )}
      {verifyCollectionRequest.pending && (
        <RequestPendingModal
          title="Verifying Collection"
          subtitle="Contract deployed successfully"
          onCloseAction={() => null}
        />
      )}
      {verifyCollectionRequest.error && (
        <ErrorNotificationModal
          errorStatus={uiText.deployFailedErrorMessage}
          onCloseAction={() => setVerifyCollectionRequest(requestInitialState)}
        />
      )}
      {previewCollectionRequest.pending && (
        <RequestPendingModal
          title="Generating Preview"
          subtitle={uiText.reviewCardProcessingSubTitle}
          onCloseAction={() => null}
        />
      )}
      {previewCollectionRequest.error && (
        <ErrorNotificationModal
          onCloseAction={() => setPreviewCollectionRequest(requestInitialState)}
          errorStatus={apiError}
        />
      )}
      <ApiContext.Provider
        value={{
          getCollections,
          createCollection,
          previewCollection,
          verifyCollection,
          getSingleCollection,
          getMintPageContent,
          getNftImageById,
          updateMintPage,
          uploadPlaceholderMetaData,
          uploadAllowList,
          generateSigAllowList,
          authenticateFreeUser,
          addAddressToFreeList,
          setUploadAllowListRequest: (status: RequestState) =>
            setUploadAllowListRequest(status),
          setUpdateMintPageRequest: (status: RequestState) =>
            setUpdateMintPageRequest(status),
          setCreateCollectionsRequest: (status: RequestState) =>
            setCreateCollectionsRequest(status),
          setPreviewCollectionRequest: (status: RequestState) =>
            setPreviewCollectionRequest(status),
          setAddToFreeListRequest: (status: RequestState) =>
            setAddToFreeListRequest(status),
          getCollectionsRequest,
          createCollectionsRequest,
          previewCollectionRequest,
          verifyCollectionRequest,
          singleCollectionRequest,
          mintPageContentRequest,
          nftImagesRequest,
          updateMintPageRequest,
          uploadPlaceholderRequest,
          uploadAllowListRequest,
          addToFreeListRequest,
          apiError,
          authenticateFreeUserRequest,
        }}
      >
        {children}
      </ApiContext.Provider>
    </>
  );
};
