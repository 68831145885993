import {
  ModalContent,
  Modal,
  ModalOverlay,
  useDisclosure,
  Flex,
  Box,
  chakra,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { CollectorContext } from "../../../system/context/CollectorContext";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_HEADER_TEXT_COLOR,
  APP_SECONDARY_NEON_COLOR,
} from "../../../theme/colors";

type RequestPendingModalProps = {
  onCloseAction: () => void;
  title: string;
  subtitle: string;
};
export const RequestPendingModal = ({
  onCloseAction,
  title,
  subtitle,
}: RequestPendingModalProps) => {
  const { isMintPage } = React.useContext(CollectorContext);
  const { uiText } = useUiText();
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onCloseAction();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          maxWidth="388px"
          height="663px"
          bg="transparent"
          border="none"
          boxShadow="none"
          position={isMintPage ? undefined : "absolute"}
          top={isMintPage ? undefined : 3}
          right={{ base: undefined, md: isMintPage ? undefined : "85px" }}
        >
          <Flex
            flexDir={"column"}
            flex={1}
            mt={"60px"}
            bgColor={"appHeaderBackgroundDark"}
            borderRadius={"12px"}
            py={"50px"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box flexDir={"column"} alignItems={"center"} display={"flex"}>
              <chakra.h2
                fontSize={"25px"}
                fontWeight={"bold"}
                width={"200px"}
                color={APP_SECONDARY_NEON_COLOR}
                textAlign="center"
              >
                {title}
              </chakra.h2>
              <Flex flexDir={"row"}alignItems="center">
                <chakra.h2
                  mt="12px"
                  fontWeight={"semibold"}
                  color={APP_HEADER_TEXT_COLOR}
                  fontSize={"12px"}
                  width={"250px"}
                  textAlign="center"
                >
                  {subtitle}
                </chakra.h2>
              </Flex>
            </Box>
            <Image
              src="/loading_animation.webp"
              w="293px"
              h="293px"
              objectFit={"contain"}
            />
            <chakra.h2
              textAlign={"center"}
              fontWeight={"semibold"}
              fontSize={"14px"}
              color={APP_HEADER_TEXT_COLOR}
            >
              {uiText.reviewCardProcessingBottomSubTitle1} <br />
              {uiText.reviewCardProcessingBottomSubTitle2}
            </chakra.h2>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};
