import {
  BoxProps,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import { useErrorStatus } from "../../system/hooks/useErrorStatus";
import { useUiText } from "../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  WALLET_MODAL_ALT_BG_COLOR,
} from "../../theme/colors";
import { StatusCardProps } from "./types";
import { AiOutlineClose } from "react-icons/ai";
import { CollectorContext } from "../../system/context/CollectorContext";
import React from "react";

export const ErrorCard = ({
  removeBranding,
  onClose,
  errorStatus,
  ...rest
}: StatusCardProps &
  BoxProps & { errorStatus: string; removeBranding?: boolean }) => {
  const { uiText } = useUiText();
  const { message } = useErrorStatus(errorStatus);
  const { isMintPage } = React.useContext(CollectorContext);

  return (
    <Flex
      flexDir="column"
     alignItems="center"
     justifyContent="space-around"
      bgColor={WALLET_MODAL_ALT_BG_COLOR}
      p={10}
      borderRadius={8}
      onClick={() => onClose()}
      width={"358px"}
      height={"612px"}
      {...rest}
      position="relative"
    >
      <IconButton
        aria-label="button"
        position="absolute"
        top="5px"
        color={APP_PRIMARY_CONTRAST_COLOR}
        right="5px"
        onClick={() => onClose()}
        size="lg"
        bg="transparent"
        _hover={{ cursor: "pointer" }}
        _focus={{ border: "none" }}
        icon={<AiOutlineClose />}
      />
      <Flex flexDir="column"alignItems="center">
        <Heading
          color={removeBranding ? APP_PRIMARY_CONTRAST_COLOR : APP_ERROR_COLOR}
          textAlign="center"
        >
          {uiText.somethingWentWrong}
        </Heading>
      </Flex>
      <Image
        w={{base: "100px", md: "100%"}}
        src={
          isMintPage && removeBranding ? "/collector-error.png" : "/bones.webp"
        }
      />
      <Text w="100%" color={APP_PRIMARY_CONTRAST_COLOR} textAlign="center">
        {message}
      </Text>
    </Flex>
  );
};
