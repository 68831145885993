import { Button } from "@chakra-ui/button";
import {
  APP_PRIMARY_BUTTON_TEXT,
  APP_SECONDARY_COLOR_DARK,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";
import { ButtonProps } from "./types";

export const PrimaryButton = ({ text, ...rest }: ButtonProps) => {
  return (
    <Button
      color={APP_PRIMARY_BUTTON_TEXT}
      backgroundColor={APP_SECONDARY_COLOR}
      _hover={{ backgroundColor: APP_SECONDARY_COLOR_DARK }}
      _focus={{ border: "none" }}
      p="25px 40px 25px 40px"
      fontFamily="heading"
      fontSize={rest.fontSize ? rest.fontSize : "12px"}
      fontWeight="bold"
      {...rest}
    >
      {text}
    </Button>
  );
};
