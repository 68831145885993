const uiText = {
  aboutDescription1:
    "Don’t know how or where to create your own NFT collections? Omakasea makes it easy for you. Simply add images to build your collection. No code required.",
  aboutDescription2:
    "Join the club. Receive lifetime access to the Kitchen, an exclusive place where members can create their own custom NFT collections.",
  aboutDescription3: "Project White Paper",
  aboutHeading: "What's Omakasea?",
  attributeNameRequired: "Attribute name required",
  addPreviewImage: "Add Preview Image",
  allowListMinting: "Allow List Minting",
  propagateToIpfs: "Propagate your metadata on IPFS",
  metadata: "Metadata",
  enableAllowListMinting: "Enable allow list minting",
  allowListSuccessMessage: "Allowlist is now live.",
  allowListCsvUploadMessage: "Click to upload or Drag .CSV here",
  allowListDescription:
    "Update allowlisted addresses. Use a CSV file with a single column named “wallets” containing each address. The list you provide is exhaustive. Any future updates should be added to this existing list.",
  allowListFieldLabel: "Allow List",
  metadataDescription:
    "Propagate your metadata and images on the IPFS network. This ensures faster data retrieval and integrity.",
  maxAllowedMintsFieldDescription:
    "Enter the maximum mint amount for each address.",
  maxAllowedMintsLabel: "Max Allowed Mints",
  allowListMintPriceFieldDescription: "Enter the cost for allowed mints.",
  balance: "Balance",
  cancel: "Cancel",
  collectionRevealed: "Collection Revealed",
  collections: "Collections",
  classTooltip:
    "Add a new class when you want to use different attributes, allowing you to have different styles of NFTs in the same collection.",
  classesAttributeDescription:
    "Each attribute (folder) should be sorted in the order they appear. Example: “Background” comes before “Body” to avoid any unwanted visual collisions. To add 1 of 1 to your collection, add a new class and upload 1 trait per attribute.",
  congratulations: "Congratulations!",
  connect: "Connect",
  continue: "Continue",
  connectWallet: "Connect Wallet",
  contractAddress: "Contract Address",
  contractBalance: "Contract Balance",
  copyButtonSuccessTooltip: "Copied",
  copyButtonTooltip: "Copy to Clipboard",
  createdOn: "Created On",
  deployCollection: "Deploy Collection",
  deployedOn: "Created On",
  disconnect: "Disconnect",
  enableMinting: "Enable Minting",
  enableMintingDescription:
    "Enabling public minting at any time. After minting is enabled, you still have the option to pause minting. After enabled you won't be able to access provenance, premint or allowlist options.",
  enablePreMint: "Enable Pre-Mint",
  enableNow: "Enable Now",
  enter: "Enter",
  enterKitchen: "Enter Kitchen",
  ethereum: "Ethereum",
  ethereumAbbr: "ETH",
  errorCreatingCollection: "Error Creating Collection",
  deployFailedErrorMessage:
    "Contract may still have been deployed and verified. Please check etherscan to confirm.",
  failedTransactionMessage: "Check your Wallet for details.",
  getReceiptMessage: "Get a Receipt to start a new Collection.",
  generatePreview: "Generate Preview",
  goToDiscord: "Go to Discord",
  imageWillDisplay: "Image will display after Collection is Revealed.",
  infoDescription2:
    "Simply connect your wallet to access your collections and explore all the unique features.",
  insufficientFundsMessage:
    "Insufficient Balance 5 ETH are required to mint a Receipt.",
  introHeading1: "Produce unique, randomly-generated NFT Collections.",
  introHeading2: "No code required.",
  learnMore: "Learn More",
  maxNumberError: (num: number) => `Maximum value should be ${num}`,
  minNumberError: (num: number) => `Minimum value should be ${num}`,
  mintNewReceipt: "Mint New Receipt",
  mintReceipt: "Mint Receipt",
  mintingOptions: "Minting Options",
  mintingOptionsWarning:
    "One or more options have not been set. These options won’t be available after you tap continue.",
  mintingOptionsProvenance: "Set Provenance Hash",
  mintingOptionsPreMint: "Mint before enable Minting",
  mintingOptionsAllowList: "Give preference to wallets",
  mintingOptionsPublicMinting: "Enable public minting",
  mintingOptionsDescription:
    "Complete these steps before you enable public minting. You can always come back any time to continue the process.",
  mintPage: "Mint Page",
  mintSuccessMessage: "Your mint was successful.",
  mobileLanding: "Available on Desktop only.",
  myProfile: "My Profile",
  myReceipts: "Receipts",
  noCollectionsMessage: "You don't have any collections.",
  noReceiptMessage: "You don't have a receipt yet.",
  noReceiptsSubTitle:
    "Mint a Receipt or sign up for a free licence to Start New Collection.",
  noReceiptsTitle: "You don’t have a Receipt",
  pauseMinting: "Pause Minting",
  placeholderImagesLabel: "Placeholder Images",
  placeHolderDescription:
    "Select up to 3 images as a placeholder until the collection is revealed. These images will display on Opensea and other marketplaces. PNG | JPG | GIF",
  placeholderMax100: "Max 100",
  preMint: "Pre-Mint",
  homePageTitle: "NFT Creator Studio and Launchpad.",
  homePageSubTitle1:
    "Produce unique, randomly-generated or 1/1 NFT Collections.",
  homePageSubTitle2: "No code required.",
  freemium: "Freemium",
  freemiumSubTitle:
    "All the NFT minting tools you need to launch your collection and build your project.",
  publicMinting: "Public Minting",
  preMintMessage:
    "A Pre-Mint sets aside some NFTs for you, the creator, before enabling public minting.",
  prepareYourFiles: "Prepare your files.",
  processingTransaction: "Processing Transaction",
  setProvenance: "Set Provenance",
  provenance: "Provenance",
  provenanceHashSet: "Provenance Hash Set",
  provenancePopupDescription:
    "Set Provenance before enabling minting to ensure the authenticity of your collection.",
  readyToStartCollection: "You are ready to start your collection.",
  receiptAuthenticityDescription1: `Omakasea guarantees the authenticity and provenance of every Receipt. `,
  receiptAuthenticityDescription2:
    "Each Receipt is put through a hashing algorithm in the exact order they appear in the collection. The resulting hashes are then combined and hashed one last time to produce the Provenance Hash (essentially, the transaction Receipt).",
  receiptAuthenticityDescription3: `This hash is written to the blockchain. Anyone can follow the same process to discover the same hash and prove the collection has not been tampered with prior to launch.`,
  receiptCostMessage: (amount: number) => `Receipt Cost ${amount} ETH`,
  receiptHasBeenMinted: "Receipt has been Minted.",
  receiptHeading: "Get a Receipt",
  receiptOverviewDescription1: `Omakasea has limited membership—there’s only space for 1,111 active members. Your membership (A.K.A. Receipt) grants you lifetime access to the Kitchen.`,
  receiptOverviewDescription2: `Minting a Receipt costs 5 ETH. No bonding curves or elaborate pricing schemes. Memberships cost the same for everyone.`,
  receiptSpecsDescription1: `Receipt NFTs (memberships) are unique digital collectibles, generated with various attributes such as menu items, ingredients and designs.`,
  receiptSpecsDescription2: `Receipts are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS.`,
  receiptSubHeadingAuthenticity: "Authenticity",
  receiptSubHeadingOverview: "Overview",
  receiptSubHeadingSpecs: "Specs",
  receipts: "Receipts",
  revealCollection: "Reveal Collection",
  revealOnMint: "Reveal On Mint",
  revealOnMintDescription:
    "NFTs will be shown to minters when they mint. This does not reveal your collection to anyone else.",
  revealCollectionMessage:
    "Revealing the collection exposes the IPFS base URL in the smart contract. This URL is the web address that links to the Metadata associated with each NFT.  Make sure to reveal the collection after the last NFT has been minted. Exposing the IPFS base URL prematurely bears the risk of rare NFTs getting bought first.",
  saveAttribute: "Save Attribute",
  smartContractType: "Smart Contract Type",
  smartContractTypeDescription:
    "Select between a regular NFT contract and DAO capable NFT contract. Note that the DAO ERC-721 cost more gas to deploy then the regular contract.",
  setAllowList: "Set Allowlist",
  daoNotAMemberMessage:
    "Sorry, you must be a member to access the dao dashboard.",
  daoMembersText: "MEMBERS",
  daoQuorumText: "QUORUM",
  skip: "Skip",
  skipAndEnableMinting: "Skip and Enable Minting",
  skipAndEnableLater: "Skip and Enable Later",
  somethingWentWrong: "Something Went Wrong",
  startNewCollection: "Start New Collection",
  startNftCollection: "Start NFT Collection",
  startSale: "Start Sale",
  start: "Start",
  switchNetworkMessage: "Switch to Ethereum Mainnet.",
  totalMinted: "Total Minted",
  metadataBaseUrlUndefinedError:
    "! Metadata Base Url is undefined. Cannot Reveal Collection.",
  uploadArtworkTooltip:
    "Add more Class when you want to use a combination of different attributes, allowing you to have different styles of NFTs in the same collection. You can also add 1 of 1 by adding single traits to each attribute for the Class.",
  totalItemsTooltip:
    "Based on the amount of items you specified for each class, you should be able to generate this amount of unique items for your collection. Keep in mind that the total amount of traits and rarity you set for each trait will influence the final amount of unique items the collection is able to generate. In the event where the collection cannot create enough unique items, just add new traits or modify the rarity of each traits.",
  transactionInProgressMessage: "This might take a few minutes.",
  visitKitchenDescription1:
    "Create your own unique randomly generated NFT collections. No programming or development experience is required.",
  visitKitchenDescription2:
    "To access the Kitchen, holders must be signed into a web wallet such as MetaMask containing a valid Receipt.",
  visitKitchenDescription3:
    "Use art layers to create your collection. Customize your name, description, mint cost, trait rarity, max supply and more.",
  visitKitchenHeading: "Visit Kitchen",
  walletAddress: "Wallet Address",
  // Kitchen Page
  introHeaderKitchen1: "Welcome to the Kitchen",
  introHeaderKitchen2:
    "Upload art. Create your fully customizable, NFT collection. Zero code required.",
  prepareHeading: "Prepare your files",
  prepareDescription:
    "Only images in .PNG format can be uploaded. Image size on all traits must be the same with a transparent background. Make sure all the elements are in the correct overlay position.",
  createHeading: "Create and rearrange attributes",
  createDescription:
    "Add the attributes (folders) you need. Rearrange them in the desired order. Each attribute overlays over one another to generate random unique images.",
  addNewClass: "Add  New  Class",
  addHeading: "Add unique traits to each attribute",
  addDescription:
    "Set the rarity percentage for each trait. This determines the frequency with which the trait appears in the collection.",
  previewHeading: "Preview your NFT Collection",
  previewDescription:
    "Generate an instant preview of your collection. Re-order any attributes if necessary.",
  customizeHeading: "Customize your collection details",
  customizeDescription:
    "Make sure your description, mint cost, trait rarity, max supply and all collection details are correct. After that you are ready to generate your collection.",
  customizeList1: "NFT Cost",
  customizeList2: "Number of Items",
  customizeList3: "Extra Metadata",
  infoHeading: "What else you should know",
  infoDescription1:
    "When creating your collection, you will need to deploy the Smart Contract on the Ethereum Blockchain. Don’t worry, this is a seamless process. Just make sure you have a sufficient ETH balance to pay for the deployment. Gas fees vary and are hard to predict. Set aside around 1 ETH for this process.",
  welcomeToTheKitchen: "Welcome to the Kitchen.",
  welcomeToTheKitchenDescription:
    "Upload art. Create your fully customizable, NFT collection. Zero code required.",
  withdrawETHButtonLabel: "Withdraw ETH",
  withdrawFundsTooltip:
    "Withdraws all ETH collected from sales into your wallet.",
  wrongNetwork: "Wrong Network",
  totalItems: "Total Items",
  numberOfItems: "Number of items",
  numberOfItemsDescription: "Specify the amount of items in this Class.",

  // modal connect wallet
  selectWallet: "Select Wallet",
  metamask: "Metamask",
  safepal: "SafePal",
  trustWallet: "Trust Wallet",
  tokenPocket: "Token Pocket",
  walletConnect: "Wallet Connect",

  // Create Collection Page
  createCollectionAddMetadata: "Add New",
  createCollectionCostDescription:
    "The cost to mint one NFT in ETH. All items are the same price.",
  createCollectionCostLabel: "NFT Cost*",
  createCollectionCostPlaceholder: "Max 30 characters. Max 18 decimals.",
  createCollectionDecimalsError: "Maximum decimals should be 18 decimals",
  createCollectionDescriptionDescription:
    "Provide detailed description of your Collection",
  createCollectionDescriptionLabel: "Description",
  createCollectionDownloadLabel: "Download Image",
  createCollectionGenerateButtonDescription: "Choose a folder or Drop it here.",
  createCollectionGenerateButtonLabel: "Add New Attribute",
  createCollectionGenerateDescription:
    "Each attribute (folder) should be sorted in the order they appear. Example: “Background” comes before “Body” to avoid any unwanted visual collisions.",
  createCollectionGenerateLabel: "Upload Artwork*",
  createCollectionGenerateTooltip:
    "Each attribute is a folder, which contains the traits for that feature. (e.g., all the different eye colours traits will be added in the eye colour attribute folder.)",
  createCollectionMax10000: "Max 10000",
  createCollectionMax150Characters: "Maximum length should be 150 characters",
  createCollectionMax30Characters: "Maximum length should be 30 characters",
  createCollectionMetadataDescription1:
    "Data will display in the NFT Collection Metadata.",
  createCollectionMetadataDescription2:
    "Add up to five keys and values. The data cannot be changed after the collection is created.",
  createCollectionMetadataError: "Maximum value should be 10000",
  createCollectionMetadataExample: "Ex: Creator. Max 30 characters.",
  createCollectionValueExample: "Ex: Carmen Santiago",
  createCollectionMetadataLabel: "Extra Metadata",
  createCollectionNameDescription: "Enter Collection Name",
  createCollectionNameLabel: "Name*",
  createCollectionPlaceholder30Characters: "Max 30 characters.",
  createCollectionPlaceholderMax10Characters: "Max 10 characters.",
  createCollectionPlaceholderMax500Characters: "Max 500 characters.",
  createCollectionPlaceholderMax150Characters: "Max 150 characters.",
  createCollectionPreviewTip1: "Bottom",
  createCollectionPreviewTip2: "Overlay Position",
  createCollectionPreviewTip3: "Top",
  createCollectionRequired: "This is required",
  createCollectionSubmitButton: "Review Collection",
  createCollectionSymbolDescription: "Symbol will appear as Collection Ticker",
  createCollectionSymbolLabel: "Symbol*",
  createCollectionTitle: "Start Building your NFT Collection",
  createCollectionTotalDescription:
    "Specify the number of items in your Collection.",
  createCollectionTotalLabel: "Number of Items*",
  createCollectionMax10Characters: "Maximum length should be 10 characters",
  deleteCollectionModal: "Delete Attribute",
  deleteCollectionTitle: "Delete Attribute?",
  deleteCollectionButton1: "Delete",
  deleteCollectionButton2: "Cancel",
  deleteCollectionTraitTitle: "Delete Trait?",
  fileErrorTitle: "Upload Error",
  fileErrorDescriptionPremium:
    "Files must be . or .gif format and have less than 10MB",
  fileErrorDescriptionFreemium:
    "Files must be .png format and have less than 1MB",

  // Review Collection Page
  reviewCollectionTitle: "Review your Collection",
  reviewCollectionEditButtonLabel: "Edit Details",
  reviewCollectionTitleDescription:
    "After your Collection is created you won’t be able to edit.",
  reviewCollectionCreateButton: "Create Collection",
  reviewCollectionName: "Name",
  reviewCollectionSymbol: "Symbol",
  reviewCollectionDescription: "Description",
  reviewCollectionUploaded1: "Uploaded Artwork",
  reviewCollectionUploaded2: "Attributes",
  reviewCollectionCost: "NFT Cost",
  reviewCollectionTotal: "Number of Items",
  reviewCollectionMetadata: "Extra Metadata",
  reviewCollectionKey: "Key",
  reviewCollectionValue: "Value",
  reviewCardProcessingTitle: "We're creating your collection",
  reviewCardProcessingSubTitle: "This may take a while",
  reviewCardProcessingBottomSubTitle1: "Keep your browser",
  reviewCardProcessingBottomSubTitle2: "open the entire time",
  reviewCardDeployingTitle1: "Contract",
  reviewCardDeployingTitle2: "Deploying",
  reviewCardDeployingSubtitle: "This might take a few minutes",
  reviewCardFailTitle: "Something went wrong.",
  reviewCardFailSubTitle: "Check your Wallet for details.",
  reviewCardCollectionFailedTitle: "Collection Failed",
  reviewCardCollectionFailedSubtitle1: "Unable to generate enough NFT’s",
  reviewCardCollectionFailedSubtitle2:
    "with the amount uploaded. Increase the ",
  reviewCardCollectionFailedSubtitle3: "size of yourcollection and start over",
  reviewCardInsufficientTitle: "Insufficient ETH Balance",
  reviewCardInsufficientSubtitle1: "Make sure you have a sufficient",
  reviewCardInsufficientSubtitle2: "ETH balance to deploy your contract.",
  reviewCardSuccessTitle: "Almost There",
  reviewCardSuccessSubTitle1: "Now that your Collection is created",
  reviewCardSuccessSubTitle2: "deploy it on the Ethereum Blockchain.",
  reviewCardSuccessButton: "Deploy Contract",
  reviewCardDeployLater: "Deploy Later",
  reviewCardSuccessDescription:
    "Make sure you have a sufficient ETH balance to deploy your contract.",
  reviewed: "Reviewed",
  created: "Created",
  deployed: "Deployed",

  // Success Collection Page
  successCollectionTitle: "Congratulations.",
  successCollectionSubTitle1: "Your Collection",
  successCollectionSubTitle2: "has been deployed.",
  successCollectionSubTitle3:
    "Manage your Collection at any time by connecting your wallet.",
  successCollectionSubTitle4: "has been created.",
  successCollectionButton1: "View Collection",
  successCollectionButton2: "Create New Collection",

  //Use omakasea for free page
  useForFree: "Use Omakasea for free",
  followUs: "Follow us on twitter and turn on alerts",
  makeA: "Make a ", //tweet is the next word, but it's a link
  tweet: "tweet. ",
  tagUs:
    "Tag @Omakasea_ and include the #omakasea hashtag with your public wallet addres",
  waitReply: "Wait for a reply from one of our mods",
  successAllow: "Your wallet address is now allowed to use Omakasea for free!",

  // Mint Page
  mintNow: "Mint Now",
  createdWith: "Created with Omakasea",
  // Customize Mint Page
  customizeMintTitle: "Customize Mint Page",
  customizeMintCollectionLink:
    "Share the link so Collectors can find you and start Minting your NFTs",
  customizeMintBannerTitle: "Page Banner",
  customizeMintBannerDescription:
    "Add a Collection Cover for your mint page, the Mint Button will appear automatically",
  customizeMintBoxDescription: "Add a Collection Cover",
  customizeMintDesktopTitle: "Desktop",
  customizeMintDesktopDescription: "1720px x 730px Recommended",
  customizeMintMobileTitle: "Mobile",
  customizeMintMobileDescription: "390px x 730px Recommended",
  customizeMintButtonArea: "Mint Button Area",
  customizeMintLinks: "Links",
  discord: "Discord",
  instagram: "Instagram",
  twitter: "Twitter",
  opensea: "OpenSea",
  saveDetails: "Save Details",
  viewMintPage: "View Mint Page",
  IPFSLink: "IPFS Link",
  lightPaper: "Light Paper",
  edit: "Edit",
  mintCost: "Mint Cost",
  selectAmount: "Select Amount to be Minted.",

  whatsIncludedInFreemium: [
    "Deploy unlimited generative NFT collections in 30 minutes or less",
    "Whitelisted mints - no additional blockchain fees (gas)",
    "Non-custodial: all NFT contracts are owned by you",
    ".PNG file support",
    "Max trait file size 1MB",
    "NFT Collection mint page Builder",
    "Minimum collection size: 1,000",
    "Maximum collection size: 10,000",
    "Minimum collection cost: 0.01 ETH",
  ],
  whatsIncludedInPremium: [
    "Premium Listing for your collection on the front page of Omakasea.com",
    ".GIF file support ",
    "Max trait file size 10MB",
    "Unlimited collection size ",
    "Optionally include DAO integration with your NFT project ",
    "Omakasea marketing support for your collection ",
    "Audio & video trait support *Coming soon! ",
    "Customization & special features for your collection upon request ",
    "Personal or commercial use license ",
    "Live chat support ",
    "Remove Omakasea branding on mint page",
    "Deploy unlimited collections",
  ],
};

export type UiText = typeof uiText;

export const useUiText = () => {
  return { uiText };
};
