import { Button, Text } from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";
import { useUiText } from "../../system/hooks/useUiText";
import { APP_PRIMARY_CONTRAST_COLOR } from "../../theme/colors";

function CustomizeMintEditButton() {
  const { uiText } = useUiText();
  return (
    <Button
      bgColor={APP_PRIMARY_CONTRAST_COLOR}
      leftIcon={<MdModeEditOutline size={"9px"} />}
      position={"absolute"}
      bottom={"20px"}
      right={"21px"}
      width="53px"
      height="23px"
      borderRadius={"14px"}
      border="1px"
    >
      <Text fontFamily={"body"} fontWeight="medium" fontSize={"10px"} ml="-5px">
        {uiText.edit}
      </Text>
    </Button>
  );
}

export default CustomizeMintEditButton;
