import { ExternalProvider, Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";

/**
 * Exposes methods for making RPC requests to Ethereum.
 */
export class EthereumRequests {
  readonly ethereum;
  constructor(ethereum: Web3Provider | undefined) {
    this.ethereum = ethereum?.provider;
  }

  public getAccounts = async (provider?: ExternalProvider) => {
    let eth: ExternalProvider | undefined;
    provider ? (eth = provider) : (eth = this.ethereum);
    if (eth?.request) {
      return await eth.request({ method: "eth_requestAccounts" });
    }
  };

  public reconnect = async () => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
    }
  };

  public disconnect = async () => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "eth_requestAccounts",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
    }
  };

  public getBalance = async (account: string) => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "eth_getBalance",
        params: [account, "latest"],
      });
    }
  };

  public sendTransaction = async (transactionParameters: any) => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
    }
  };

  public switchChain = async (chainId: string) => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(parseInt(chainId)) }],
      });
    }
  };

  public sign = async (
    account: string | undefined,
    msg: string,
    pass: string
  ) => {
    if (this?.ethereum?.request) {
      return await this.ethereum.request({
        method: "personal_sign",
        params: [msg, account, pass],
      }).catch((err) => console.log(err)
      )
    }
  };
}
