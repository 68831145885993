export const executiveProfiles = [
  {
    firstName: "Max",
    lastName: "Gail",
    blurb: `Professional psychological operation administrator.
         Broke the IQ test as a child. Magic the Gathering. Early Bitcoin participant. 
         Follows the quickest distance between two points.`,
    image: "MM05.webp",
    title: "Chief Executive Officer",
    twitter: "@omakasea_",
  },
  // {
  //   firstName: "Etienne",
  //   lastName: "Dusseault",
  //   blurb: `Multidisciplinary hacker. Programming the blockchain since 2017. 
  //     Ex Consensys/MetaMask for 2 years building security infrastructure.
  //      Enjoys breaking things and putting them back together.`,
  //   image: "ET05.webp",
  //   title: "Chief Technology Officer",
  //   twitter: "@TheChadDevET",
  // },
];

export const profiles = [
  // {
  //   firstName: "Cristiano",
  //   lastName: "Maynez",
  //   blurb: `Hardware wizard. Built a drone fabrication business at 14. 
  //       AutoCAD pro. Solidity dev. Has an affinity for nodes. 
  //       Solders silicone wires onto chipsets for leisure.`,
  //   image: "C05.webp",
  //   title: "Blockchain Developer",
  //   twitter: "@blocklinechef",
  // },
  // {
  //   firstName: "Wai",
  //   lastName: "Santy",
  //   blurb: `A creative soul with more than 15 years’ experience in design & brand industry. 
  //     Former creative for Walt Disney, a true art geek at heart. 
  //     Embracing art in every way, from oil painting to digital art.`,
  //   image: "W05.webp",
  //   title: "Art Person",
  //   twitter: "@datartperson",
  // },
  // {
  //   firstName: "Delaney",
  //   lastName: "Mills",
  //   blurb: `Community curator for Omakasea. 
  //     She has over a decade of experience in the fine art, 
  //     entertainment, and community organizing spaces, but the instant she heard about Web3 and NFTs, she fell in love.`,
  //   image: "teara05.webp",
  //   title: "Community Manager",
  //   twitter: "@tearaverse",
  // },
  {
    firstName: "Stefan",
    lastName: "Fiebig",
    blurb: `Loves Tech! Discord Magician and Designer / Background in Business Administration, Marketing and B2B Wholesale.`,
    image: "Koma05.webp",
    title: "Community Manager",
    twitter: "@polykoma",
  },
  // {
  //   firstName: "Rich",
  //   lastName: "Forrester",
  //   blurb: `Dev specializing in the frontend. 
  //     Built tech for several different industries including oil and gas, education and real estate. 
  //     Before coding received a BSc in Geology and spent 10 years discovering and developing oil fields in western Canada.`,
  //   image: "Rich05.webp",
  //   title: "Front End Developer",
  //   twitter: "@rickyforr",
  // },
  // {
  //   firstName: "Rizqi",
  //   lastName: "Amaliah",
  //   blurb: `Full-time Executive Assistant for Masterchef Masatoshi, Office Best Mafia.`,
  //   image: "kia05.webp",
  //   title: "Executive Assistant",
  //   twitter: "@kamisuka",
  // },
  // {
  //   firstName: "Kristina",
  //   lastName: "Sulityaningsih",
  //   blurb: `Part-time dgen, full-time administration support for Omakasea HQ. Office best listener.`,
  //   image: "Nana06.webp",
  //   title: "Executive Assistant",
  //   twitter: "@inc09n1tto",
  // },
  {
    firstName: "Ronaldo",
    lastName: "Tejasukmana",
    blurb: `Just a coffee junkie UI Designer.`,
    image: "Ronald05.webp",
    title: "UI Designer",
    twitter: "@thebronaldo",
  },
];
