import { Flex, Heading, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, IconButton } from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../../../components/buttons";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useUiText } from "../../../system/hooks/useUiText";
import { APP_TEXT_INACTIVE_COLOR } from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CollectionEnableMintingViewProps } from "./types";
import { CollectionContractContext } from "./CollectionContractContext";

export const CollectionEnableMintingView = ({
  goBack,
  goToCollectionDetails,
}: CollectionEnableMintingViewProps) => {
  const { uiText } = useUiText();
  const {
    transactionPending,
    changeMintStatus,
    enableMinting,
    transactionSuccessful,
    isLegacy,
  } = React.useContext(CollectionContractContext);

  const handleEnableMinting = async () => {
    if (isLegacy) {
      enableMinting();
      return;
    }
    changeMintStatus(2);
  };

  React.useEffect(() => {
    if (transactionSuccessful) {
      goToCollectionDetails();
    }
    // eslint-disable-next-line
  }, [transactionSuccessful]);

  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="auto"
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="30px" mt="100px">
          <Heading fontSize="28px" mb="10px">
            {uiText.enableMinting}
          </Heading>
          <Text color={APP_TEXT_INACTIVE_COLOR}>
            {uiText.enableMintingDescription}
          </Text>
        </Flex>
      </Box>
      <Flex placeSelf="center" flexDir="column" w="205px" mb="25px">
        <PrimaryButton
          mb="10px"
          text={uiText.enableNow}
          onClick={handleEnableMinting}
        />
        <SecondaryButton
          isLoading={transactionPending}
          text={uiText.skipAndEnableLater}
          border="none"
          onClick={() => goToCollectionDetails()}
        />
      </Flex>
    </Box>
  );
};
