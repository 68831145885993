import { usePrevious } from "@chakra-ui/react";
import React from "react";
import { Collection } from "../../../system/api/types";
import { LEGACYContractContext } from "../../../system/context/LEGACYContractContext";
import { WalletContext } from "../../../system/context/WalletContext";
import { useCollections } from "../../../system/hooks/useCollections";
import { WalletModalContainerProps } from "./types";
import { WalletModal } from "./WalletModal";

export const WalletModalContainer = ({
  isOpen,
  onClose,
  walletModalTabIndex,
  collectionId,
}: WalletModalContainerProps) => {
  const {
    balance,
    account,
    ethereum,
    accountChangePending,
    switchNetwork,
    validation,
  } = React.useContext(WalletContext);

  const {
    transactionPending,
    transactionSuccessful,
    transactionUnSuccessful,
    resetTransactionState,
    hasReceipt,
    mintPrice,
    errorMessage
  } = React.useContext(LEGACYContractContext);

  const [selectedCollection, setSelectedCollection] =
    React.useState<Collection>();
  const { getAllCollections, collections } = useCollections();
  const [viewPreMint, setViewPreMint] = React.useState(false);
  const [viewReveal, setViewReveal] = React.useState(false);
  const [viewProvenance, setViewProvenance] = React.useState(false);
  const [viewEnableMint, setViewEnableMint] = React.useState(false);
  const [viewMintingOptions, setViewMintingOptions] = React.useState(false);
  const [viewAllowList, setViewAllowList] = React.useState(false);
  const [viewAllowListMinting, setViewAllowListMinting] = React.useState(false);
  const [viewCollectionError, setViewCollectionError] = React.useState(false);

  const prevCollectionId = usePrevious(selectedCollection?.id);

  React.useEffect(() => {
    if (isOpen && account && ethereum) {
      getAllCollections();
    }
    // eslint-disable-next-line
  }, [account, ethereum, isOpen, validation]);

  React.useEffect(() => {
    if (collectionId && prevCollectionId !== collectionId) {
      setSelectedCollection(collections?.find((c) => c.id === collectionId));
    }
  }, [collectionId, collections, prevCollectionId, account, validation]);

  const handleSelectCollection = (id: string, collections: Collection[]) => {
    const collection = collections?.find((c) => c.id === id);
    setSelectedCollection(collection);
  };

  const handleClose = () => {
    // Clean up modal state before closing
    resetTransactionState();
    setSelectedCollection(undefined);
    setViewPreMint(false);
    setViewProvenance(false);
    setViewReveal(false);
    setViewEnableMint(false);
    setViewMintingOptions(false);
    setViewAllowList(false);
    setViewAllowListMinting(false);
    setViewCollectionError(false);
    onClose();
  };

  const handleGoBackToDetails = () => {
    setViewPreMint(false);
    setViewProvenance(false);
    setViewReveal(false);
    setViewEnableMint(false);
    setViewMintingOptions(false);
    setViewAllowList(false);
    setViewAllowListMinting(false);
    setViewCollectionError(false);
  };

  React.useEffect(() => {
    return () => {
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WalletModal
      balance={balance}
      insufficientFunds={parseInt(balance) < mintPrice}
      account={account}
      accountChangePending={accountChangePending}
      collections={collections || []}
      switchNetwork={switchNetwork}
      transactionPending={transactionPending}
      transactionSuccessful={transactionSuccessful}
      transactionUnSuccessful={transactionUnSuccessful}
      viewPreMint={viewPreMint}
      viewReveal={viewReveal}
      viewProvenance={viewProvenance}
      viewEnableMint={viewEnableMint}
      viewAllowList={viewAllowList}
      viewAllowListMinting={viewAllowListMinting}
      viewMintingOptions={viewMintingOptions}
      viewCollectionError={viewCollectionError}
      handleSelectCollection={handleSelectCollection}
      handleClose={handleClose}
      handleViewEnableMint={(view: boolean) => setViewEnableMint(view)}
      handleViewPreMint={(view: boolean) => setViewPreMint(view)}
      handleViewReveal={(view: boolean) => setViewReveal(view)}
      handleViewProvenance={(view: boolean) => setViewProvenance(view)}
      handleViewMintingOptions={(view: boolean) => setViewMintingOptions(view)}
      handleGoBackToDetails={handleGoBackToDetails}
      handleViewAllowList={(view: boolean) => setViewAllowList(view)}
      handleViewAllowListMinting={(view: boolean) =>
        setViewAllowListMinting(view)
      }
      handleViewCollectionError={(view: boolean) =>
        setViewCollectionError(view)
      }
      hasReceipt={hasReceipt}
      walletModalTabIndex={walletModalTabIndex}
      isOpen={isOpen}
      onClose={onClose}
      selectedCollection={selectedCollection}
      clearSelectedCollection={() => setSelectedCollection(undefined)}
      errorMessage={errorMessage}
    />
  );
};
