import React from "react";

export const useScreenSize = (width: number) => {
  const [isWidthOrGreater, setIsWidthOrGreater] = React.useState(
    window.innerWidth < width
  );

  const updateMedia = () => {
    setIsWidthOrGreater(window.innerWidth < width);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return {
    isWidthOrGreater,
  };
};
