import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode, useContext } from "react";
import { Collection } from "../../../system/api/types";
import { CollectorContext } from "../../../system/context/CollectorContext";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_DIVIDER_COLOR,
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
  WALLET_MODAL_BODY_BG_COLOR,
  WALLET_MODAL_HEADER_BG_COLOR,
} from "../../../theme/colors";
import { CollectionAllowListView } from "./CollectionAllowListView";
import { CollectionContractProvider } from "./CollectionContractContext";
import { CollectionCreationErrorView } from "./CollectionCreationErrorView";
import { CollectionDetails } from "./CollectionDetails";
import { CollectionEnableAllowMintingView } from "./CollectionEnableAllowMintingView";
import { CollectionEnableMintingView } from "./CollectionEnableMintingView";
import { CollectionMintingOptionsView } from "./CollectionMintingOptionsView";
import { CollectionPreMintView } from "./CollectionPreMintView";
import { CollectionProvenanceView } from "./CollectionProvenanceView";
import { CollectionRevealView } from "./CollectionRevealView";
import { MyCollectionsSection } from "./MyCollectionsSection";
import { MyReceiptsSection } from "./MyReceiptsSection";
import { WalletModalProps } from "./types";
import { isLegacyContract } from "./utils";
import { WalletModalConnectView } from "./WalletModalConnectView";
import { WalletModalTransactionFailedView } from "./WalletModalTransactionFailedView";
import { WalletModalTransactionPendingView } from "./WalletModalTransactionPendingView";
import { WalletModalTransactionSuccessfulView } from "./WalletModalTransactionSuccessfulView";

export const WalletModal = ({
  account,
  balance,
  clearSelectedCollection,
  collections,
  handleClose,
  handleSelectCollection,
  handleViewAllowListMinting,
  handleViewPreMint,
  handleViewReveal,
  handleViewProvenance,
  handleViewEnableMint,
  handleGoBackToDetails,
  handleViewMintingOptions,
  handleViewAllowList,
  handleViewCollectionError,
  hasReceipt,
  insufficientFunds,
  isOpen,
  onClose,
  selectedCollection,
  transactionPending,
  transactionSuccessful,
  transactionUnSuccessful,
  viewPreMint,
  viewReveal,
  viewProvenance,
  viewMintingOptions,
  viewAllowListMinting,
  walletModalTabIndex,
  viewEnableMint,
  viewAllowList,
  viewCollectionError,
  errorMessage,
}: WalletModalProps) => {
  const { uiText } = useUiText();
  const [tabIndex, setTabIndex] = React.useState(0);
  React.useEffect(() => {
    setTabIndex(walletModalTabIndex);
  }, [walletModalTabIndex]);
  const { isMintPage } = useContext(CollectorContext);

  const renderModalContentView = () => {
    return (
      <>
        {!selectedCollection && (
          <>
            <ModalHeader
              mx={"-0.8px"}
              mt={"-0.8px"}
              borderWidth={"1px"}
              borderTopRadius={"12px"}
              borderColor={WALLET_MODAL_HEADER_BG_COLOR}
              bgColor={WALLET_MODAL_HEADER_BG_COLOR}
              color={APP_PRIMARY_CONTRAST_COLOR}
            >
              <Flex
                flexDir={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Flex flexDir={"column"}>
                  <Text
                    fontSize="12px"
                    fontFamily={"heading"}
                    fontWeight="semibold"
                  >
                    {uiText.walletAddress}
                  </Text>
                  <Text
                    fontFamily={"body"}
                    fontWeight={"normal"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    fontSize="12px"
                    w="161px"
                  >
                    {account}
                  </Text>
                </Flex>
              </Flex>
            </ModalHeader>
            <Divider color={APP_DIVIDER_COLOR} />
            <ModalBody
              p="5px 5px 0px 15px"
              color={"#000"}
              bgColor={WALLET_MODAL_BODY_BG_COLOR}
              borderBottomRadius="8px"
            >
              <Tabs
                variant="line"
                isLazy
                h="95%"
                position="relative"
                defaultIndex={tabIndex}
              >
                <TabList borderBottom="none">
                  {[uiText.myReceipts, uiText.collections].map((subHeading) => (
                    <Tab
                      key={`sub-heading-${subHeading}`}
                      _selected={{
                        borderBottomWidth: "3px",
                        borderColor: APP_SECONDARY_COLOR,
                        color: APP_PRIMARY_COLOR,
                        fontWeight: 600,
                      }}
                      _focus={{ boxShadow: "none" }}
                      _active={{ background: "none" }}
                      fontSize="20px"
                      fontFamily={"heading"}
                      fontWeight={"semibold"}
                      color={APP_TEXT_INACTIVE_COLOR}
                      mr="40px"
                      mb="20px"
                    >
                      {subHeading}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels zIndex="2">
                  <TabPanel h="455px">
                    <MyReceiptsSection
                      balance={balance}
                      insufficientFunds={insufficientFunds}
                    />
                  </TabPanel>
                  <TabPanel h="455px" bg={APP_PRIMARY_CONTRAST_COLOR}>
                    <MyCollectionsSection
                      hasReceipt={hasReceipt}
                      collections={
                        collections.sort(function (
                          a: Collection,
                          b: Collection
                        ) {
                          return (
                            new Date(b.dateCreated).getTime() -
                            new Date(a.dateCreated).getTime()
                          );
                        }) || []
                      }
                      setCollection={handleSelectCollection}
                      goToCollectionError={() =>
                        handleViewCollectionError(true)
                      }
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          </>
        )}
      </>
    );
  };

  function renderWithModal<P>(
    Component: any,
    props: P,
    transparent?: boolean,
    collectionAddress?: string
  ) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
        isCentered={isMintPage}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="388px"
          height="663px"
          borderRadius={"12px"}
          borderWidth={"1px"}
          borderColor={"transparent"}
          bg={transparent ? "transparent" : WALLET_MODAL_BODY_BG_COLOR}
          position={isMintPage ? undefined : "absolute"}
          top={isMintPage ? undefined : 3}
          right={{ base: undefined, md: isMintPage ? undefined : "85px" }}
        >
          {!!collectionAddress ? (
            <CollectionContractProvider
              contractAddress={collectionAddress}
              isLegacy={isLegacyContract(
                selectedCollection?.contractType || ""
              )}
            >
              <Component {...props} />
            </CollectionContractProvider>
          ) : (
            <Component {...props} />
          )}
        </ModalContent>
      </Modal>
    );
  }

  if (transactionPending) {
    return renderWithModal(WalletModalTransactionPendingView, {}, true);
  }

  if (transactionSuccessful) {
    return renderWithModal(WalletModalTransactionSuccessfulView, {}, true);
  }

  if (transactionUnSuccessful) {
    return renderWithModal(
      WalletModalTransactionFailedView,
      { errorMessage },
      true
    );
  }

  if (viewPreMint) {
    return renderWithModal(
      CollectionPreMintView,
      {
        goBack: () => handleViewPreMint(false),
        goToAllowList: () => handleViewAllowList(true),
        goToEnableMinting: () => {
          handleViewEnableMint(true);
        },
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewProvenance) {
    return renderWithModal(
      CollectionProvenanceView,
      {
        goBack: () => handleViewProvenance(false),
        goToEnablePreMint: () => handleViewPreMint(true),
        goToEnableMinting: () => handleViewEnableMint(true),
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewReveal) {
    return renderWithModal(
      CollectionRevealView,
      {
        goBack: () => handleViewReveal(false),
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewAllowList) {
    return renderWithModal(
      CollectionAllowListView,
      {
        goBack: () => {
          handleViewAllowList(false);
        },
        goToAllowListMinting: () => handleViewAllowListMinting(true),
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewAllowListMinting) {
    return renderWithModal(
      CollectionEnableAllowMintingView,
      {
        goBack: () => {
          handleViewAllowListMinting(false);
          handleViewPreMint(false);
        },
        goToCollectionDetails: handleGoBackToDetails,
        goToPreMint: () => {
          handleViewAllowListMinting(false);
          handleViewPreMint(false);
          handleViewPreMint(true);
        },
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewEnableMint) {
    return renderWithModal(
      CollectionEnableMintingView,
      {
        goBack: () => {
          handleViewEnableMint(false);
          handleViewPreMint(false);
        },
        goToCollectionDetails: handleGoBackToDetails,
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (viewMintingOptions) {
    return renderWithModal(
      CollectionMintingOptionsView,
      {
        goBack: handleGoBackToDetails,
        goToAllowListMinting: () => handleViewAllowListMinting(true),
        goToAllowList: () => handleViewAllowList(true),
        goToProvenance: () => handleViewProvenance(true),
        goToPreMint: () => handleViewPreMint(true),
        goToEnableMinting: () => handleViewEnableMint(true),
        goToMintingOptions: () => handleViewMintingOptions(true),
        collection: selectedCollection as Collection,
      },
      false,
      selectedCollection?.contractAddress
    );
  }

  if (!account) {
    return renderWithModal(WalletModalConnectView, {
      onClose,
    });
  }

  if (viewCollectionError) {
    return renderWithModal(CollectionCreationErrorView, {
      goBack: () => handleGoBackToDetails(),
      handleClose: () => handleClose(),
    });
  }

  if (selectedCollection) {
    return renderWithModal(
      CollectionDetails,
      {
        collection: selectedCollection as Collection,
        onBackToCollections: () => {
          clearSelectedCollection();
          setTabIndex(1);
        },
        goToPreMint: () => handleViewPreMint(true),
        goToProvenance: () => handleViewProvenance(true),
        goToRevealCollection: () => handleViewReveal(true),
        goToEnableMinting: () => handleViewEnableMint(true),
        goToMintingOptions: () => handleViewMintingOptions(true),
      },
      false,
      selectedCollection.contractAddress
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
      isCentered={isMintPage}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="388px"
        height="663px"
        borderRadius="12px"
        position={isMintPage ? undefined : "absolute"}
        top={isMintPage ? undefined : 3}
        right={{ base: undefined, md: isMintPage ? undefined : "85px" }}
      >
        {account && !isMintPage && renderModalContentView()}
      </ModalContent>
    </Modal>
  );
};
