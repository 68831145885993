import { Tooltip, IconButton } from "@chakra-ui/react";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { useUiText } from "../../system/hooks/useUiText";
import { CopyButtonProps } from "./types";

export const CopyButton = ({
  text,
  altIcon,
  altPlacement,
  ...rest
}: CopyButtonProps) => {
  const { uiText } = useUiText();
  const [copyButtonTooltip, setCopyButtonTooltip] = React.useState<string>(
    uiText.copyButtonTooltip
  );
  const handleCopy = () => {
    setCopyButtonTooltip(uiText.copyButtonSuccessTooltip);
    setTimeout(() => {
      setCopyButtonTooltip(uiText.copyButtonTooltip);
    }, 5000);
  };

  return (
    <Tooltip
      label={copyButtonTooltip}
      closeOnClick={false}
      shouldWrapChildren
      color="white"
      bgColor="black"
      placement={(altPlacement as any) || "bottom"}
    >
      <CopyToClipboard text={text} onCopy={() => handleCopy()}>
        {altIcon ? (
          altIcon
        ) : (
          <IconButton
            aria-label="button"
            icon={<MdContentCopy />}
            _focus={{ border: "none" }}
            _active={{ bgColor: "transparent" }}
            {...rest}
          />
        )}
      </CopyToClipboard>
    </Tooltip>
  );
};
