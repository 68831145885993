import { Button } from "@chakra-ui/button";
import {
  APP_ERROR_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
} from "../../theme/colors";
import { ButtonProps } from "./types";

export const WarningButton = ({ text, ...rest }: ButtonProps) => {
  return (
    <Button
      color={APP_PRIMARY_CONTRAST_COLOR}
      backgroundColor={APP_ERROR_COLOR}
      _hover={{ backgroundColor: APP_ERROR_COLOR }}
      _focus={{ border: "none" }}
      p="25px 40px 25px 40px"
      fontFamily={"heading"}
      fontWeight={"bold"}
      fontSize={"12px"}
      {...rest}
    >
      {text}
    </Button>
  );
};
