import React from "react";

enum AppModals {
  walletModal = "walletModal",
  noReceiptsModal = "noReceiptsModal",
  transactionPendingModal = "transactionPendingModal",
  transactionSuccessful = "transactionPendingModal",
}

export type ModalController = {
  [Name in AppModals]: {
    isOpen: boolean;
    onOpen: (
      tabIndex?: number,
      collectionId?: string,
      removeBrandingMintPage?: boolean
    ) => void;
    onClose: () => void;
  };
} & {
  walletModalTabIndex: number;
  collectionId: string;
};

export const useModalController = (): ModalController => {
  const [walletModalIsOpen, setWalletModalIsOpen] =
    React.useState<boolean>(false);

  const [noReceiptsModalIsOpen, setNoReceiptsModalIsOpen] =
    React.useState<boolean>(false);

  const [transactionPendingModalIsOpen, setTransactionPendingModalIsOpen] =
    React.useState<boolean>(false);

  const [walletModalTabIndex, setWalletTabIndex] = React.useState<number>(0);
  const [collectionId, setCollectionId] = React.useState<string>("");

  return {
    walletModal: {
      isOpen: walletModalIsOpen,
      onOpen: (tabIndex?: number, collectionId?: string) => {
        setWalletModalIsOpen(true);
        setWalletTabIndex(tabIndex || 0);
        setCollectionId(collectionId || "");
      },
      onClose: () => setWalletModalIsOpen(false),
    },
    noReceiptsModal: {
      isOpen: noReceiptsModalIsOpen,
      onOpen: (tabIndex?: number, collectionId?: string) => {
        setNoReceiptsModalIsOpen(true);
      },
      onClose: () => setNoReceiptsModalIsOpen(false),
    },
    transactionPendingModal: {
      isOpen: transactionPendingModalIsOpen,
      onOpen: (
        tabIndex?: number,
        collectionId?: string,
        removeBrandingMintPage?: boolean
      ) => {
        setTransactionPendingModalIsOpen(true);
      },
      onClose: () => setTransactionPendingModalIsOpen(false),
    },
    walletModalTabIndex,
    collectionId,
  };
};
