import { Button, Image } from "@chakra-ui/react";
import { APP_PRIMARY_CONTRAST_COLOR } from "../../theme/colors";
import { RoundIconImageButtonProps } from "./types";

export const RoundIconImageButton = ({
  imgSource,
  alt,
  ...rest
}: RoundIconImageButtonProps) => {
  return (
    <Button
      alt={alt}
      height={"39px"}
      width={"30px"}
      borderRadius={"40px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      p="0"
      bgColor={APP_PRIMARY_CONTRAST_COLOR}
      {...rest}
    >
      <Image w="30px" h="29px" objectFit={"contain"} src={imgSource} />
    </Button>
  );
};
