import { FormLabel as FrmLabel } from "@chakra-ui/react";
import { FormLabelProps } from "./types";

function FormLabel(props: FormLabelProps) {
  const { text, name, fontSize } = props;

  return (
    <FrmLabel
      {...props}
      color={"#121212"}
      fontSize={fontSize || "20px"}
      fontFamily={"heading"}
      fontWeight={"semibold"}
    >
      {text}
    </FrmLabel>
  );
}

export default FormLabel;
