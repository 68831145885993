import React from "react";
import { Abi } from "./types";

export const useSetAbiFunctions = (abi: Abi) => {
  const [functions, setFunctions] = React.useState<Abi>([]);
  React.useEffect(() => {
    if (abi) {
      const funcs = abi.filter(
        (property) =>
          property.type === "function" && property.stateMutability !== "view"
      );
      setFunctions(funcs);
    }
  }, [abi]);

  return {
    functions,
  };
};
