import {
  ModalContent,
  Modal,
  ModalOverlay,
  useDisclosure,
  BoxProps,
} from "@chakra-ui/react";
import React from "react";
import { ErrorCard } from "../../../components/cards/ErrorCard";
import { CollectorContext } from "../../../system/context/CollectorContext";

type ErrorNotificationModalProps = {
  onCloseAction: () => void;
  errorStatus: string;
  removeBranding?: boolean;
} & BoxProps;

export const ErrorNotificationModal = ({
  removeBranding,
  onCloseAction,
  errorStatus,
  ...rest
}: ErrorNotificationModalProps) => {
  const { isMintPage } = React.useContext(CollectorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onCloseAction();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          mb={0}
          w="388px"
          height="663px"
          bg="transparent"
          boxShadow="none"
          position={isMintPage ? undefined : "absolute"}
          top={isMintPage ? undefined : 3}
          right={{ base: undefined, md: isMintPage ? undefined : "85px" }}
        >
          <ErrorCard
            onClose={handleClose}
            removeBranding={removeBranding}
            errorStatus={errorStatus}
            {...rest}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
