import { Button } from "@chakra-ui/button";
import {
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../theme/colors";
import { HeaderButtonProps } from "./types";

export const HeaderButton = ({ text, profile, ...rest }: HeaderButtonProps) => {
  return (
    <Button
      color={profile ? "#fff" : APP_SECONDARY_COLOR}
      _focus={{ borderColor: profile ? APP_SECONDARY_COLOR : "none" }}
      _disabled={{ cursor: "default", color: APP_TEXT_INACTIVE_COLOR }}
      _hover={{ bgColor: "#282828" }}
      _active={{ bgColor: "none" }}
      fontFamily={profile ? "heading" : "body"}
      fontSize={{ base: "14px", xl: "16px" }}
      fontWeight={profile ? "bold" : "normal"}
      borderColor={profile ? APP_SECONDARY_COLOR : "none"}
      variant={profile ? "outline" : "ghost"}
      ml={profile ? "36px" : 0}
      {...rest}
    >
      {text}
    </Button>
  );
};
