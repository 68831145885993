import { Flex, Text, Image } from "@chakra-ui/react";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";

export type TeamProfileData = {
  firstName: string;
  lastName: string;
  title: string;
  blurb: string;
  image: string;
  twitter: string;
};
type TeamProfileCardProps = {
  profile: TeamProfileData;
};

export const TeamProfileCard = ({ profile }: TeamProfileCardProps) => {
  return (
    <Flex mx="60px" my="10px" flexDir="column" p="10px" w="220px">
      <Image src={profile.image} mb="-22px" zIndex={1} />

      <Flex flexDir="column" backgroundColor="#262626" p="10px" h="330px">
        <Flex flexDir="column">
          <Text
            fontSize="16px"
            fontFamily="heading"
            fontWeight="bold"
            mt="10px"
            color={APP_PRIMARY_CONTRAST_COLOR}
          >
            {profile.firstName}
          </Text>
          <Text
            fontSize="16px"
            fontFamily="heading"
            fontWeight="bold"
            color={APP_PRIMARY_CONTRAST_COLOR}
          >
            {profile.lastName}
          </Text>
        </Flex>

        <Text
          fontSize="14px"
          fontFamily="heading"
          fontWeight="bold"
          mt="10px"
          color="#AAAAAA"
        >
          {profile.title}
        </Text>
        <Text
          fontSize="14px"
          fontFamily="heading"
          fontWeight="bold"
          mt="10px"
          color={APP_SECONDARY_COLOR}
        >
          {profile.twitter}
        </Text>

        <Text
          fontSize="12px"
          fontFamily="heading"
          mt="10px"
          color="#AAAAAA"
          maxW="182px"
        >
          {profile.blurb}
        </Text>
      </Flex>
    </Flex>
  );
};
