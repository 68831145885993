import {
  ModalCloseButton,
  ModalContent,
  Modal,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import UpdatedCollectionCard from "../../../components/cards/UpdatedCollectionCard";
import { CollectorContext } from "../../../system/context/CollectorContext";
import { APP_PRIMARY_CONTRAST_COLOR } from "../../../theme/colors";

type UpdatedNotificationModalProps = {
  updatingPlaceholderData: boolean;
  clearStatus: () => void;
  onViewMintPage: () => void;
};

export const UpdatedNotificationModal = ({
  clearStatus,
  onViewMintPage,
  updatingPlaceholderData,
}: UpdatedNotificationModalProps) => {
  const { isMintPage } = React.useContext(CollectorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    clearStatus();
    onClose();
  };

  React.useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          mb={0}
          h="663px"
          w="388px"
          position={isMintPage ? undefined : "absolute"}
          top={isMintPage ? undefined : 3}
          right={{ base: undefined, md: isMintPage ? undefined : "85px" }}
        >
          <ModalCloseButton
            color={APP_PRIMARY_CONTRAST_COLOR}
            onClick={handleClose}
          />
          <UpdatedCollectionCard
            updatingPlaceholderData={updatingPlaceholderData}
            onClose={handleClose}
            onViewMintPage={onViewMintPage}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
