import React from "react";
import { useLocation } from "react-router-dom";
import { ApiContext } from "../api/ApiContext";
import { MintPageContent } from "../api/types";

export type CollectorControllerType = {
  isMintPage: boolean;
  content: MintPageContent;
};

export const useCollectorController = (): CollectorControllerType => {
  const { pathname } = useLocation();
  const { getMintPageContent } = React.useContext(ApiContext);
  const isMintPage = pathname.includes("/mint") || pathname.includes("/bandel") || pathname.includes("/eighties-babies");

  const [collection, setCollection] = React.useState<MintPageContent>(
    {} as MintPageContent
  );

  React.useEffect(() => {
    if (isMintPage) {
      getMintPageContent(pathname.split("/mint/")[1])
        .then((collection: MintPageContent) => {
          setCollection(collection);
        })
        .catch(() => {
          console.log("could not find that collection");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isMintPage]);

  return {
    isMintPage,
    content: collection,
  };
};
