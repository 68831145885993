import {
  Box,
  Button,
  Collapse,
  Flex,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { BigNumber } from "ethers";
import moment from "moment";
import React from "react";
import { PrimaryButton } from "../../components/buttons";
import { TransactionInfoCard } from "./TransactionInfoCard";
import { Abi } from "./types";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { APP_SECONDARY_COLOR } from "../../theme/colors";
import { useAccount } from "wagmi";

export type TableData = {
  comment: string;
  isActive: boolean;
  timestamp: BigNumber;
  timeLeft: string;
  sign: () => void;
};

type DaoDashboardTableRowProps = {
  rowData: {
    comment: string;
    contractAddress: string;
    data: string;
    index: BigNumber;
    isActivated: boolean;
    signers: string[];
    timestamp: BigNumber;
    value: BigNumber;
  };
  votingTime: number;
  abi: Abi;
  signVoting: (index: number) => Promise<any>;
  activateVoting: (index: number) => Promise<any>;
  members: number;
  percentToVote: number;
  membersLoading: boolean;
};

export const DaoDashboardTableRow = ({
  rowData,
  votingTime,
  abi,
  signVoting,
  members,
  percentToVote,
  activateVoting,
  membersLoading,
}: DaoDashboardTableRowProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { address: account } = useAccount();

  const [timeRemaining, setTimeRemaining] = React.useState(0);
  const [canActivate, setCanActivate] = React.useState(false);

  const timeLeft = (createdTime: number, votingTime: number) => {
    const end = createdTime + votingTime;
    const today = Math.round(new Date().getTime() / 1000);
    const diff = end - today;

    return (diff / 3600 / 24).toPrecision(2);
  };

  const handleSignVoting = () => {
    if (rowData.signers.length / members >= percentToVote / 100) {
      activateVoting(rowData.index.toNumber());
      return;
    }
    signVoting(rowData.index.toNumber());
  };

  const renderStatus = () => {
    if (!rowData.timestamp || !votingTime) return;

    const timeExpired =
      parseFloat(timeLeft(rowData.timestamp.toNumber(), votingTime)) < 0;

    const calcStatus = () => {
      if (rowData.isActivated) {
        return { text: "ACTIVATED", color: "green" };
      }
      if (!timeExpired) {
        return { text: "IN PROGRESS", color: "#CEAF79" };
      }
      if (timeExpired) {
        return { text: "VOTING OVER", color: "#C87F7E" };
      }
    };

    const status = calcStatus();

    return (
      <Text
        borderColor={status?.color}
        color={status?.color}
        borderWidth="1px"
        borderRadius="4px"
        fontSize="12px"
        padding="2px"
      >
        {status?.text}
      </Text>
    );
  };

  React.useEffect(() => {
    setTimeRemaining(
      parseFloat(timeLeft(rowData.timestamp.toNumber(), votingTime))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, votingTime]);

  React.useEffect(() => {
    setCanActivate(rowData.signers.length / members >= percentToVote / 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, members, percentToVote]);

  return (
    <>
      <Tr>
        <Td cursor="pointer" onClick={onToggle} _hover={{ opacity: "0.7" }}>
          {isOpen ? (
            <Button
              boxShadow={"md"}
              bgColor={APP_SECONDARY_COLOR}
              color="black"
              _focus={{ border: "none" }}
              _hover={{ bgColor: "appSecondaryColor" }}
              _active={{ bgColor: "black" }}
            >
              <BsChevronDown />
            </Button>
          ) : (
            <Button
              boxShadow={"md"}
              bgColor="black"
              _focus={{ border: "none" }}
              _hover={{ bgColor: "black" }}
              _active={{ bgColor: "black" }}
            >
              <BsChevronRight />
            </Button>
          )}
        </Td>
        <Td cursor="pointer" onClick={onToggle} _hover={{ opacity: "0.7" }}>
          <Flex alignItems="center">
            <Text ml="10px"> {rowData.comment}</Text>
          </Flex>
        </Td>
        <Td>
          <Box w="fit-content">{renderStatus()}</Box>
        </Td>
        <Td>
          {moment
            .unix(rowData?.timestamp.toNumber())
            .format("YYYY-MM-DD h:mma")}
        </Td>
        <Td>
          {timeRemaining > 0 ? (
            <Text>{timeRemaining} Days</Text>
          ) : (
            <Text>-</Text>
          )}
        </Td>
        <Td>
          {timeRemaining > 0 && (
            <PrimaryButton
              text={canActivate ? "Activate" : "Sign"}
              onClick={() => handleSignVoting()}
              isLoading={membersLoading}
              disabled={
                (rowData.signers.some((s) => s === account) && !canActivate) ||
                rowData.isActivated
              }
            />
          )}
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={7} p="0px" border="none">
          <Collapse in={isOpen} animateOpacity>
            <TransactionInfoCard
              target={rowData.contractAddress}
              value={rowData.value._hex}
              data={rowData.data}
              signatures={rowData.signers}
              onClose={onClose}
              abi={abi}
            />
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};
