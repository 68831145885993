import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const apiGet = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
  responseType: "json",
});

export const apiCreate = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
  responseType: "blob",
});

export const apiMake = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
  responseType: "json",
});

export const generateSigAllowList = async (
  contractAddress: string,
  validation: string,
  success: () => void,
  failure: () => void
) => {
  const data = {
    contractAddress,
    validation,
  };
  const result = await apiGet
    .post<{ messageHash: string; signature: string }>(
      "/generateSigAllowList",
      data
    )
    .then((res) => {
      success();
      return res.data;
    });
  // .catch((err) => {
  //   console.log(err?.response?.data);
  //   return err?.response?.data;
  // });

  return result;
};
