import { createContext } from "react";
import { useLicenceRestriction } from "../hooks/useLicenceRestriction";

interface IAuthenticateFreeContext {
  hasFreeLicence: boolean;
  handleValidateAndAuth: () => void;
}

export const AuthenticateFreeContext = createContext<IAuthenticateFreeContext>(
  {} as IAuthenticateFreeContext
);

export const AuthenticateFreeProvider: React.FC<{children: any}> = ({ children }) => {
  const { hasFreeLicence, handleValidateAndAuth } = useLicenceRestriction();

  return (
    <AuthenticateFreeContext.Provider
      value={{ hasFreeLicence, handleValidateAndAuth }}
    >
      {children}
    </AuthenticateFreeContext.Provider>
  );
};
