import React from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { AuthenticateFreeContext } from "../../../system/context/AuthenticateFreeContext";
import { LEGACYContractContext } from "../../../system/context/LEGACYContractContext";
import { ModalContext } from "../../../system/context/ModalContext";

export const useNoReceipt = () => {
  const { hasReceipt } = React.useContext(LEGACYContractContext);
  const { hasFreeLicence } = React.useContext(AuthenticateFreeContext);
  const { address: account } = useAccount();
  const { noReceiptsModal, walletModal } = React.useContext(ModalContext);
  let navigate = useNavigate();
  const handleCreateNewCollection = () => {
    if (!account) {
      walletModal.onOpen();
      return;
    }
    if (hasFreeLicence || hasReceipt) {
      navigate("/create-collection");
    } else {
      noReceiptsModal.onOpen();
    }
  };

  return {
    handleCreateNewCollection,
  };
};
