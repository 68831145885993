import React from "react";
import { useNavigate } from "react-router-dom";
import { useNetwork, useSigner } from "wagmi";
import { ApiContext } from "../../../api/ApiContext";
import {
  ContractType,
  requestErrorState,
  requestInitialState,
  RequestState,
  requestSuccessState,
} from "../../../api/types";
import { ModalContext } from "../../../context/ModalContext";
import { WalletContext } from "../../../context/WalletContext";
import {
  deployDaoContract,
  deployJuniorContract,
  deployJuniorXContract,
} from "./utils";

type Deploy = {
  deployAndVerify: (
    collection: {
      name: string;
      symbol: string;
      total: string;
      cost: string;
      allowListMintPrice?: string;
      maxAllowedMints?: string;
    },
    collectionId: string
  ) => void;
  deployRequest: RequestState;
  verifyCollectionRequest: RequestState;
  setDeployRequest: (requestState: RequestState) => void;
};

export const useDeployContract = (contractType: ContractType): Deploy => {
  const {chain} = useNetwork()
  const { account, chainId } = React.useContext(WalletContext);
  const { verifyCollection, verifyCollectionRequest } =
    React.useContext(ApiContext);
  const [deployRequest, setDeployRequest] =
    React.useState<RequestState>(requestInitialState);
  const navigate = useNavigate();
  const { walletModal } = React.useContext(ModalContext);
  const {data: signer} = useSigner();

  const deployAndVerify = async (
    collection: {
      name: string;
      symbol: string;
      total: string;
      cost: string;
      allowListMintPrice?: string;
      maxAllowedMints?: string;
    },
    collectionId: string
  ) => {
    let contract = {} as {
      deployTxHash: string | undefined;
      contractAddress: string | undefined;
    };

    if (!account) {
      return;
    }

    if (contractType === "Erc-721X") {
      contract = await deployJuniorXContract(
        {
          name: collection.name,
          symbol: collection.symbol,
          total: collection.total.toString(),
          cost: collection.cost,
          allowListMintPrice: collection.allowListMintPrice || "",
          maxAllowedMints: collection?.maxAllowedMints?.toString() || "",
        },
        signer,
        account,
        () => setDeployRequest({ ...deployRequest, pending: true })
      ).catch((err) => {
        setDeployRequest(requestErrorState);

        return Promise.reject(err);
      });
    }

    if (contractType === "Erc-721 DAO") {
      contract = await deployDaoContract(
        {
          name: collection.name,
          symbol: collection.symbol,
          total: collection.total.toString(),
          cost: collection.cost,
          allowListMintPrice: collection.allowListMintPrice || "",
          maxAllowedMints: collection?.maxAllowedMints?.toString() || "",
        },
        signer,
        account,
        () => setDeployRequest({ ...deployRequest, pending: true }),
        chainId
      ).catch((err) => {
        setDeployRequest(requestErrorState);

        return Promise.reject(err);
      });
    }

    if (contractType === "Erc-721") {
      contract = await deployJuniorContract(
        {
          name: collection.name,
          symbol: collection.symbol,
          total: collection.total.toString(),
          cost: collection.cost,
          allowListMintPrice: collection.allowListMintPrice || "",
          maxAllowedMints: collection?.maxAllowedMints?.toString() || "",
        },
        signer,
        account,
        () => setDeployRequest({ ...deployRequest, pending: true })
      ).catch((err) => {
        setDeployRequest(requestErrorState);

        return Promise.reject(err);
      });
    }

    const onSuccess = () => {
      walletModal.onClose();
      navigate("/success-collection", {
        replace: true,
        state: { isFromReview: true, id: collectionId, deployed: true },
      });
    };

    if (contract?.deployTxHash && contract?.contractAddress) {
      setDeployRequest(requestSuccessState);

      verifyCollection(
        {
          collectionId,
          deployTxHash: contract?.deployTxHash,
          contractAddress: contract?.contractAddress,
          network: chain?.id
        },
        onSuccess,
        () => null
      );
    }
  };

  return {
    deployAndVerify,
    deployRequest,
    verifyCollectionRequest,
    setDeployRequest: (requestState: RequestState) =>
      setDeployRequest(requestState),
  };
};
