import { usePrevious } from "@chakra-ui/react";
import React from "react";
import { v4 } from "uuid";
import { ApiContext } from "../api/ApiContext";
import { LEGACYContractContext } from "../context/LEGACYContractContext";
import { WalletContext } from "../context/WalletContext";

export const useLicenceRestriction = (): {
  hasFreeLicence: boolean;
  handleValidateAndAuth: () => void;
} => {
  const { account, validation, sign, validationPending, chainId } =
    React.useContext(WalletContext);
  const { hasReceipt, initializing } = React.useContext(LEGACYContractContext);
  const { authenticateFreeUser, authenticateFreeUserRequest } =
    React.useContext(ApiContext);
  const prevInit = usePrevious(initializing);
  const prevValidationPending = usePrevious(validationPending);

  const handleValidateAndAuth = async () => {
    const signMessage = async () => {
      const id = v4();
      const message = id;
      const result = await sign(account, message, "null");
      return {
        message,
        result,
        id,
      };
    };
    await signMessage()
      .then(async ({ message, result, id }) => {
        authenticateFreeUser(JSON.stringify({ message, result, id }));
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  };

  React.useEffect(() => {
    if (prevValidationPending && !validationPending && !hasReceipt) {
      authenticateFreeUser(validation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationPending, prevValidationPending, hasReceipt]);

  React.useEffect(() => {
    if (chainId) {
      if (validation && chainId! !== 1 && chainId! !== 3) {
        authenticateFreeUser(validation);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, validation]);

  React.useEffect(() => {
    if (prevInit !== initializing) {
      if (account && !hasReceipt && !initializing) {
        if (validation && !validationPending) {
          authenticateFreeUser(validation);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    validation,
    hasReceipt,
    initializing,
    prevInit,
    validationPending,
  ]);

  return {
    hasFreeLicence: authenticateFreeUserRequest.success,
    handleValidateAndAuth,
  };
};
