import React from "react";

export const useCsvReader = () => {
  const [csvArray, setCsvArray] = React.useState<string[]>([]);
  const [isError, setIsError] = React.useState(false);

  const processCSV = (str: string, delim = ",") => {
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row, i) => {
      const values = row.split(delim);
      return values[0].split("\r")[0];
    });
    setCsvArray(newArray);
  };

  const readCsv = (csvFile: File) => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      e.preventDefault();
      const text = e?.target?.result;
      if (text) {
        try {
          processCSV(text.toString());
        } catch (err: any) {
          setIsError(true);
        }
      }
    };
    file && reader.readAsText(file);
  };

  return {
    readCsv,
    processCSV,
    csvArray,
    isError,
  };
};
