import { Box, Text } from "@chakra-ui/react";
import { useUiText } from "../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_WARNING_COLOR,
} from "../../theme/colors";

function MintButtonArea() {
  const { uiText } = useUiText();
  return (
    <Box
      position={"absolute"}
      bottom={74}
      left={0}
      right={0}
      margin={`0 auto`}
      w="116px"
      h="36px"
      backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
      backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='red' stroke-width='4' stroke-dasharray='6%2c 10'  stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
      visibility={{ base: "hidden", md: "visible" }}
    >
      <Text
        fontFamily={"heading"}
        fontWeight="semibold"
        fontSize={"10px"}
        color={APP_WARNING_COLOR}
      >
        {uiText.customizeMintButtonArea}
      </Text>
    </Box>
  );
}

export default MintButtonArea;
