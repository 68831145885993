import React from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useStorageState } from "react-storage-hooks";
import { v4 } from "uuid";
import {
    useAccount,
    useBalance,
    useNetwork,
    useProvider,
    useSigner,
    useSwitchNetwork,
} from "wagmi";
import { usePrevious } from "../usePrevious";

export type EthereumWallet = {
    account: string | undefined;
    accountChangePending: boolean;
    balance: string;
    ethereum: Web3Provider | undefined;
    switchNetwork: (chainId: string) => void;
    sign: (
        account: string | undefined,
        msg: string,
        pass: string
    ) => Promise<any>;
    validation: string;
    validationPending: boolean;
    chainId: number;
};

export const useEthereumWallet = (): EthereumWallet => {
    const [provider, setProvider] = React.useState<any>();
    const [currentAccount, setCurrentAccount] = React.useState<string>();
    const [validationPending, setValidationPending] = React.useState(false);

    /** Cache validation */
    const [validation, setValidation] = useStorageState<string>(
        localStorage,
        "validation",
        ""
    );

    const { address, status } = useAccount();
    const wagmiProvider = useProvider();
    const balance = useBalance();
    const { chain } = useNetwork();
    const { data: signer } = useSigner();
    const { switchNetwork } = useSwitchNetwork();
    const prevSigner = usePrevious(signer);
    const handleSwitchNetwork = async (chainId: string) => {
        if (!switchNetwork) return;
        switchNetwork(parseInt(chainId));
    };

    const sign = async () => {
        const id = v4();
        const message = `${id}`;

        const data = await signer
            ?.signMessage(message)
            .then(async (result: string) => {
                setValidation(
                    JSON.stringify({
                        message,
                        result,
                        id,
                    })
                );
                setValidationPending(false);
            })
            .catch((e) => {
                setValidationPending(false);
                console.log(e);
                return;
            });
        return data;
    };

    React.useEffect(() => {
        if (wagmiProvider) {
            setProvider(wagmiProvider);
        }
    }, [wagmiProvider]);

    React.useEffect(() => {
        if (address) {
            setCurrentAccount(address);
        }
    }, [address]);

    /**
     * If account has been defined set it to state and get balance.
     * add event listener for network and account changes.
     */
    React.useEffect(() => {
        if (provider) {
            provider.on("disconnect", () => {
                window.location.reload();
                setValidation("");
            });
            provider.on("accountsChanged", () => {
                setValidation("");
                window.location.reload();
            });

            const filter = {
                address: currentAccount,
            };
            provider.on(filter, (log: any, event: any) => {
                // Emitted whenever a DAI token transfer occurs
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, currentAccount, validation]);

    /**
     * If no cached validation, sign message on page load.
     */
    React.useEffect(() => {
        if (address && signer && !validation && !prevSigner) {
            if (window.location.pathname.split("/")[1] === "mint") {
                return;
            }
            if (window.location.pathname.split("/")[1] === "bandel") {
                return;
            }
            if (window.location.pathname.split("/")[1] === "eighties-babies") {
                return;
            }
            setValidationPending(true);
            sign();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, signer, prevSigner]);

    return {
        account: currentAccount,
        accountChangePending:
            status === "connecting" || status === "reconnecting",
        balance: balance.data?.formatted || "0",
        ethereum: provider,
        switchNetwork: handleSwitchNetwork,
        sign,
        validation,
        validationPending,
        chainId: chain?.id!,
    };
};
