import { Flex, Heading, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, Input, IconButton } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";
import { PrimaryButton } from "../../../components/buttons";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CollectionPreMintViewProps } from "./types";
import { CollectionContractContext } from "./CollectionContractContext";
import { ModalContext } from "../../../system/context/ModalContext";
import CollectorSuccessCard from "../../../components/cards/CollectorSuccessCard";
import { useEthereumWallet } from "../../../system/hooks/ethereum/useEthereumWallet";
import { ApiContext } from "../../../system/api/ApiContext";

export const CollectionPreMintView = ({
  goBack,
  goToEnableMinting,
  goToAllowList,
  collection,
}: CollectionPreMintViewProps) => {
  const { uiText } = useUiText();
  const {
    preMint,
    transactionPending,
    transactionSuccessful,
    contract,
    resetTransactionState,
    baseUri,
    totalSupply,
  } = React.useContext(CollectionContractContext);
  const { validation } = useEthereumWallet();
  const [amount, setAmount] = React.useState(0);
  const [preMintPending, setPreMintPending] = React.useState(false);
  const [images, setImages] = useState<string[]>();

  const { transactionPendingModal } = React.useContext(ModalContext);
  const { getNftImageById } = React.useContext(ApiContext);

  const handlePreMint = async () => {
    setPreMintPending(true);
    await preMint(amount);

    setPreMintPending(false);
  };

  const getNftImages = async () => {
    let ids: string[] = [];
    await contract
      ?.totalSupply()
      .then((s: number) => {
        for (let i = s - amount; i < s; i++) {
          ids.push(i.toString());
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    getNftImageById(contract?.address || "", ids, validation)
      .then((res: string[]) => {
        const sources = res.map((s) => `data:image/png;base64,${s}`);
        setImages(sources);
      })
      .catch((err: any) => console.log(err));
  };

  React.useEffect(() => {
    if (transactionPending) {
      transactionPendingModal.onOpen();
    }
    if (transactionSuccessful) {
      if (!!baseUri || collection.revealOnMint) getNftImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionPending, transactionPendingModal, transactionSuccessful]);

  React.useEffect(() => {
    return () => {
      resetTransactionState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPreMintSuccess = () => {
    return (
      <CollectorSuccessCard
        onClose={goBack}
        imageSources={images || []}
        amount={amount}
        totalSupply={totalSupply}
      />
    );
  };

  if (transactionSuccessful) {
    return renderPreMintSuccess();
  }

  return (
    <Box
      h="100%"
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      overflow={"auto"}
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex flexDir="column" p="30px" mt="100px">
          <Heading fontWeight={"bold"} fontSize={"28px"}>
            {uiText.preMint}
          </Heading>
          <Text
            fontWeight={"normal"}
            fontSize={"16px"}
            color={APP_PRIMARY_CONTRAST_COLOR}
          >
            {uiText.readyToStartCollection}
          </Text>
          <Text
            fontWeight={"normal"}
            fontSize={"16px"}
            color={APP_TEXT_INACTIVE_COLOR}
          >
            {uiText.preMintMessage}
          </Text>
          <Input
            mt="50px"
            fontSize={"14px"}
            placeholder="Enter Amount"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAmount(parseInt(e.target.value));
            }}
          />
        </Flex>
      </Box>
      <Flex placeSelf={"center"} flexDir={"column"} w={"205px"} mb={"25px"}>
        <PrimaryButton
          isLoading={preMintPending}
          mb="10px"
          text={uiText.preMint}
          onClick={handlePreMint}
          disabled={!amount}
        />
        <SecondaryButton
          isLoading={transactionPending}
          text={uiText.skip}
          border="none"
          onClick={() => {
            goToAllowList();
            goBack();
          }}
        />
      </Flex>
    </Box>
  );
};
