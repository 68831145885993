import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { TextInput } from "../../components/inputs/TextInput";
import { useUiText } from "../../system/hooks/useUiText";
import Select from "react-select";
import {
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";
import { PrimaryButton } from "../../components/buttons";
import { Abi } from "./types";
import { CreateProposalValidationSchema } from "./utils";
import { SecondaryButton } from "../../components/buttons/SecondaryButton";
import { TransactionPendingModal } from "../Modals/TransactionPendingModal/TransactionPendingModal";
import axios from "axios";
import React from "react";
import {
  ETHERSCAN_API_KEY,
  MAINNET_ETHERSCAN_API_URL,
  ROPSTEN_ETHERSCAN_API_URL,
} from "../../system/constants";
import { useNetwork } from "wagmi";

const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#242424",
    borderColor: "white",
    borderWidth: "1px",
    borderRadius: "4px",
    tex: "nowrap",
  }),
  control: () => ({
    display: "flex",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};

export type CreateProposalFormType = {
  title: string;
  targetAddress: string;
  function: string;
  params: any[];
  transactionValue: string;
};

type CreateProposalFormProps = {
  goBack: () => void;
  functions: Abi;
  abi: Abi;
  daoAddress: string;
  createVoting: (values: CreateProposalFormType, abi: Abi) => Promise<any>;
  transactionPending: boolean;
};

export const CreateProposalForm = ({
  goBack,
  functions,
  createVoting,
  transactionPending,
}: CreateProposalFormProps) => {
  const { uiText } = useUiText();
  const { chain } = useNetwork();
  const chainId = chain?.id
  const [options, setOptions] =
    React.useState<Array<{ value: string; label: string }>>();
  const [targetAbi, setTargetAbi] = React.useState<Abi>();

  const handleCreateVoting = async (values: CreateProposalFormType) => {
    if (targetAbi) {
      createVoting(values, targetAbi).finally(() => goBack());
    }
  };

  const getAbi = async (address: string) => {
    if (!address) {
      setTargetAbi(functions);
      setOptions(functions.map((f) => ({ value: f.name, label: f.name })));
      return;
    }

    const data: Abi = await axios
      .get(
        `https://${
          chainId === 3 ? ROPSTEN_ETHERSCAN_API_URL : MAINNET_ETHERSCAN_API_URL
        }/api?module=contract&action=getabi&address=${address}&apikey=${ETHERSCAN_API_KEY}`
      )
      .then((res) => {
        return JSON.parse(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          console.log("getAbi", err.response);
        }
      });
    const abi =
      data?.filter(
        (property) =>
          property.type === "function" && property.stateMutability !== "view"
      ) || [];
    setTargetAbi(abi);
    setOptions(abi.map((f) => ({ value: f.name, label: f.name })));
  };

  React.useEffect(() => {
    if (functions) {
      setTargetAbi(functions);
      setOptions(functions.map((f) => ({ value: f.name, label: f.name })));
    }
  }, [functions]);

  return (
    <Flex
      backgroundColor={APP_PRIMARY_COLOR}
      borderColor={APP_SECONDARY_COLOR}
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="8px"
      margin="auto"
      p="30px"
      flexDir="column"
      mt="50px"
    >
      {transactionPending && (
        <TransactionPendingModal
          isOpen={transactionPending}
          onClose={() => null}
        />
      )}
      <Heading textAlign="left" color={APP_PRIMARY_CONTRAST_COLOR}>
        Create Proposal
      </Heading>
      <Text textAlign="left" color={APP_PRIMARY_CONTRAST_COLOR}>
        After Creating a Proposal, you can activate it when the quorum is
        reached
      </Text>
      <Flex>
        <Formik
          initialValues={{
            title: "",
            targetAddress: "",
            function: "",
            params: [],
            transactionValue: "",
          }}
          onSubmit={(values, action) => {
            handleCreateVoting(values);
          }}
          validationSchema={CreateProposalValidationSchema}
        >
          {(props) => (
            <Form
              onSubmit={props.handleSubmit}
              style={{ width: "70%", marginTop: "20px" }}
            >
              <TextInput
                name="title"
                type="text"
                labelColor="white"
                placeholder={uiText.createCollectionPlaceholder30Characters}
                label={"Title"}
                labelFontSize="18px"
                labelFontWeight="normal"
                inputMarginBottom="50px"
                backgroundColor="#242424"
                color="white"
              />
              <TextInput
                name="targetAddress"
                type="text"
                labelColor="white"
                placeholder={uiText.createCollectionPlaceholder30Characters}
                label={"Target Address"}
                labelFontSize="18px"
                labelFontWeight="normal"
                inputMarginBottom="50px"
                backgroundColor="#242424"
                color="white"
                onBlur={(e: any) => getAbi(e.target.value)}
              />
              <Text
                textAlign={"left"}
                fontSize="18px"
                color={APP_PRIMARY_CONTRAST_COLOR}
                mt="50px"
              >
                Functions
              </Text>

              <Field name={"function"}>
                {(props: FieldProps) => (
                  <Select
                    value={{
                      label: props.field.value,
                      value: props.field.value,
                    }}
                    styles={customStyles}
                    options={options}
                    onChange={(o) => {
                      props.form.setFieldValue("function", o?.value);
                    }}
                  />
                )}
              </Field>
              <Text
                textAlign={"left"}
                fontSize="18px"
                color={APP_PRIMARY_CONTRAST_COLOR}
                mt="50px"
              >
                Function Parameters
              </Text>
              <Flex flexDir="column" pl="50px">
                <FieldArray
                  name="params"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {targetAbi &&
                          targetAbi
                            .find(
                              (f) =>
                                f.name === arrayHelpers.form.values.function
                            )
                            ?.inputs.map((input, index) => (
                              <Field
                                key={`params[${index}]`}
                                name={`params[${index}]`}
                              >
                                {(props: FieldProps) => (
                                  <TextInput
                                    {...props.field}
                                    type={
                                      input.type === "uint256"
                                        ? "number"
                                        : "text"
                                    }
                                    labelColor="white"
                                    label={input.name}
                                    labelFontSize="18px"
                                    labelFontWeight="normal"
                                    inputMarginBottom="20px"
                                    backgroundColor="#242424"
                                    color="white"
                                  />
                                )}
                              </Field>
                            ))}
                      </>
                    );
                  }}
                />
              </Flex>
              <Box mt="10px"></Box>
              <TextInput
                name="transactionValue"
                labelColor="white"
                label={"Value"}
                labelFontSize="18px"
                labelFontWeight="normal"
                inputMarginBottom="50px"
                backgroundColor="#242424"
                placeholder={"ETH value to send with the transaction."}
                color="white"
              />
              <Flex flexWrap="wrap">
                <SecondaryButton
                  color="white"
                  onClick={() => goBack()}
                  text="Go Back"
                  mr="10px"
                  mb="10px"
                />
                <PrimaryButton type="submit" text="Create Proposal" mb="10px" />
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};
