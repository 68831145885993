import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import {
  APP_PRIMARY_COLOR,
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
} from "../../theme/colors";
import { StatusCardProps } from "./types";
import { AiOutlineClose } from "react-icons/ai";

function UpdatedCollectionCard({
  onClose,
  onViewMintPage,
  updatingPlaceholderData,
}: StatusCardProps & {
  onViewMintPage: () => void;
  updatingPlaceholderData: boolean;
}) {
  return (
    <Box
      borderRadius="12px"
      borderWidth="1px"
      borderColor="transparent"
      w="100%"
      h="100%"
      bgColor={APP_PRIMARY_CONTRAST_COLOR}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      position="relative"
    >
      <Button
        backgroundColor={APP_PRIMARY_CONTRAST_COLOR}
        position="absolute"
        top={1}
        right={1}
        _hover={{ backgroundColor: "transparent" }}
        onClick={onClose}
        rightIcon={<AiOutlineClose />}
      />
      <Image
        src="/collector-success.png"
        w="180px"
        h="180px"
        objectFit="contain"
        mb="42px"
      />
      <Text
        fontFamily="heading"
        fontWeight="semibold"
        fontSize="22px"
        mb="78px"
      >
        Mint Page Updated
      </Text>
      {updatingPlaceholderData && (
        <Flex>
          <Text fontSize="16px" mb="18px" mr="5px">
            Updating Placeholder Images
          </Text>
          <Spinner color={APP_SECONDARY_COLOR} />
        </Flex>
      )}
      {!updatingPlaceholderData && (
        <Text fontSize="16px" mb="18px">
          Placeholder Images Updated
        </Text>
      )}

      <Button
        position="absolute"
        fontFamily="heading"
        fontWeight="bold"
        fontSize="16px"
        color={APP_PRIMARY_CONTRAST_COLOR}
        backgroundColor={APP_PRIMARY_COLOR}
        w="205px"
        h="44px"
        bottom="43"
        onClick={onViewMintPage}
      >
        View Mint Page
      </Button>
    </Box>
  );
}

export default UpdatedCollectionCard;
