import { FormLabel as FrmLabel } from "@chakra-ui/react";
import { FormLabelProps } from "./types";

function FormDescription(props: FormLabelProps) {
  const { text, name, width, fontSize, textAlign } = props;
  return (
    <FrmLabel
      {...props}
      color={"#939393"}
      fontSize={fontSize || "16px"}
      w={width}
      fontFamily={"body"}
      textAlign={textAlign}
    >
      {text}
    </FrmLabel>
  );
}

export default FormDescription;
