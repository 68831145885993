import { FileWithPath } from "react-dropzone";
import { v4 } from "uuid";
import { Buffer } from "buffer";

export type CustomizeMintFormData = {
    twitter: string;
    discord: string;
    instagram: string;
    opensea: string;
    desktopImage: FileWithPath;
    mobileImage: FileWithPath;
    placeholderImages: FileWithPath[];
    removeBrandingMintPage: boolean;
};

export const submitCustomizeMintForm = async (
    form: CustomizeMintFormData,
    account: string,
    address: string,
    placeholderImages: FileWithPath[],
    updateMintPage: (
        data: any,
        success: () => void,
        failure: () => void
    ) => Promise<any>,
    sign: (account: string, message: string, pass: any) => Promise<any>,
    updatePlaceholderMetaData: (data: any) => Promise<string>,
    revealCollection: (uri: string) => void
) => {
    const {
        twitter,
        discord,
        instagram,
        opensea,
        mobileImage,
        desktopImage,
        removeBrandingMintPage,
    } = form;
    const signMessage = async () => {
        const id = v4();
        const message = `0x${Buffer.from(id, "utf8").toString("hex")}`;
        const result = await sign(account, message, "null");
        return {
            message,
            result,
        };
    };

    const uploadPlaceholder = async (res: any) => {
        const filteredPlaceholders = placeholderImages.filter(
            (f) => Object.keys(f).length > 0
        );

        if (!filteredPlaceholders.length) return;

        let formData = new FormData();
        formData.append("validation", JSON.stringify(res));
        formData.append("contractAddress", address || "");
        filteredPlaceholders.map((file) =>
            formData.append("placeholders", file)
        );
        const uri = await updatePlaceholderMetaData(formData);
        revealCollection(uri);
    };

    const uploadPageData = (res: any) => {
        let formData = new FormData();

        formData.append("validation", JSON.stringify(res));
        formData.append("contractAddress", address || "");
        formData.append(
            "removeBrandingMintPage",
            JSON.stringify(removeBrandingMintPage)
        );
        formData.append(
            "socialLinks",
            JSON.stringify({
                twitter,
                discord,
                instagram,
                opensea,
                telegram: "",
            })
        );
        formData.append("desktopImage", desktopImage);
        formData.append("mobileImage", mobileImage);

        updateMintPage(
            formData,
            () => null,
            () => null
        );
    };

    const id = v4();
    const message = `0x${Buffer.from(id, "utf8").toString("hex")}`;
    if (!window.ethereum) {
        alert("Metamask not detected");
        return;
    }
    const ethereum = window.ethereum as any;
    ethereum
        .request({
            method: "personal_sign",
            params: [message, account, "null"],
        })
        .then((result: string) => {
            uploadPageData({
                message,
                result,
            });
            uploadPlaceholder({
                message,
                result,
                id,
            });
        })
        .catch((e: any) => {
            console.log(e);
            return;
        });
};

export const validateCustomizeMintForm = (values: CustomizeMintFormData) => {
    const errors = {} as any;
    const regex = new RegExp(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (values.instagram && !regex.test(values.instagram)) {
        errors.instagram = "Invalid Url";
    }
    if (values.twitter && !regex.test(values.twitter)) {
        errors.twitter = "Invalid Url";
    }
    if (values.opensea && !regex.test(values.opensea)) {
        errors.opensea = "Invalid Url";
    }
    if (values.discord && !regex.test(values.discord)) {
        errors.discord = "Invalid Url";
    }
    return errors;
};
