import { Flex, Heading, Text, Box } from "@chakra-ui/layout";
import { ModalHeader, IconButton } from "@chakra-ui/react";
import { PrimaryButton } from "../../../components/buttons";
import { useUiText } from "../../../system/hooks/useUiText";
import { APP_TEXT_INACTIVE_COLOR } from "../../../theme/colors";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CollectionCreationErrorViewProps } from "./types";
import { FiAlertCircle } from "react-icons/fi";
import { colors } from "../../../theme/theme";
import { Link } from "react-router-dom";
import React from "react";
import { ModalContext } from "../../../system/context/ModalContext";

export const CollectionCreationErrorView = ({
  goBack,
}: CollectionCreationErrorViewProps) => {
  const { uiText } = useUiText();
  const { walletModal } = React.useContext(ModalContext);

  React.useEffect(() => {
    return () => {
      goBack();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      h="100%"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="auto"
    >
      <Box>
        <ModalHeader>
          <IconButton
            aria-label="button"
            position="absolute"
            top="5px"
            right="5px"
            onClick={() => goBack()}
            size="lg"
            bg="transparent"
            _hover={{ cursor: "pointer" }}
            _focus={{ border: "none" }}
            icon={<AiOutlineArrowLeft />}
          />
        </ModalHeader>
        <Flex
          flexDir="column"
          p="30px"
          mt="100px"
         justifyContent="center"
         alignItems="center"
        >
          <FiAlertCircle size="104px" color={colors.appWarningColor} />

          <Heading fontSize="28px" mb="10px" textAlign="center">
            An error occurred during the creation process.
          </Heading>
          <Text color={APP_TEXT_INACTIVE_COLOR} textAlign="center">
            Please try creating your collection again.
          </Text>
        </Flex>
      </Box>
      <Flex placeSelf="center" flexDir="column" w="205px" mb="25px">
        <Link
          to="/create-collection"
          style={{ textDecoration: "none" }}
          onClick={() => walletModal.onClose()}
        >
          <PrimaryButton text={uiText.startNewCollection} />
        </Link>
      </Flex>
    </Box>
  );
};
