import { useLocation } from "react-router-dom";
import { DaoDashboard } from "./DaoDashboard";

export const DaoContainer = () => {
  const { pathname } = useLocation();
  if (pathname?.split("/dao/")[1]) {
    return <DaoDashboard contractAddress={pathname?.split("/dao/")[1]} />;
  }
  return <></>;
};
