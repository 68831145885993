import { Flex, Heading, Input, Text } from "@chakra-ui/react";
import React from "react";
import { v4 } from "uuid";
import { PrimaryButton } from "../../components/buttons";
import { ApiContext } from "../../system/api/ApiContext";
import { requestInitialState } from "../../system/api/types";
import { WalletContext } from "../../system/context/WalletContext";
import { APP_SECONDARY_COLOR } from "../../theme/colors";
import { Buffer } from "buffer";

export const AddUser = () => {
    const { validation } = React.useContext(WalletContext);
    const [account, setAccount] = React.useState<string>("");
    const {
        addAddressToFreeList,
        addToFreeListRequest,
        setAddToFreeListRequest,
    } = React.useContext(ApiContext);

    const handleAddAddressToList = () => {
        setAddToFreeListRequest(requestInitialState);
        if (account && validation) {
            const id = v4();
            const message = `0x${Buffer.from(id, "utf8").toString("hex")}`;
            if (!window.ethereum) {
                alert("Metamask not detected");
                return;
            }
            const ethereum = window.ethereum as any;
            ethereum
                .request({
                    method: "personal_sign",
                    params: [message, account, "null"],
                })
                .then((result: string) => {
                    addAddressToFreeList(
                        account,
                        JSON.stringify({
                            message,
                            result,
                        }),
                        () => null
                    );
                })
                .catch((err: any) => console.log(err));
        }
    };

    return (
        <Flex h="100vh">
            <Flex flexDir="column">
                <Flex
                    maxH="300px"
                    flexDir="column"
                    alignContent="center"
                    alignItems="flex-start"
                    ml="150px"
                    mt="50px"
                >
                    <Text
                        fontSize="16px"
                        fontWeight="semibold"
                        fontFamily="heading"
                    >
                        Wallet Address
                    </Text>
                    <Input
                        minW="400px"
                        onChange={(e) => setAccount(e.target.value)}
                    />
                    {addToFreeListRequest.success && (
                        <Heading color={APP_SECONDARY_COLOR}>Success</Heading>
                    )}
                </Flex>
                <PrimaryButton
                    ml="150px"
                    mt="50px"
                    text="Add User to Freemium"
                    maxW="300px"
                    onClick={handleAddAddressToList}
                />
            </Flex>
        </Flex>
    );
};
