import { Flex, Heading, Text } from "@chakra-ui/layout";
import { ModalCloseButton, Image } from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { ContractContext } from "../../../system/context/ContractContext";
import { LEGACYContractContext } from "../../../system/context/LEGACYContractContext";
import { ModalContext } from "../../../system/context/ModalContext";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_PRIMARY_CONTRAST_COLOR,
  APP_SECONDARY_COLOR,
  WALLET_MODAL_ALT_BG_COLOR,
} from "../../../theme/colors";

export const WalletModalTransactionSuccessfulView = () => {
  const { uiText } = useUiText();
  const { walletModal } = useContext(ModalContext);
  const { resetTransactionState } = useContext(LEGACYContractContext);
  const { resetTransactionState: reset } = useContext(ContractContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      resetTransactionState();
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalCloseButton color={APP_PRIMARY_CONTRAST_COLOR} />
      <Flex
        flexDir="column"
        h="100%"
       alignItems="center"
       justifyContent="space-around"
        bgColor={WALLET_MODAL_ALT_BG_COLOR}
        p={10}
        borderRadius={8}
      >
        <Flex flexDir="column"alignItems="center">
          <Heading color={APP_SECONDARY_COLOR} textAlign={"center"}>
            {uiText.receiptHasBeenMinted}
          </Heading>
          <Text color={APP_PRIMARY_CONTRAST_COLOR}>
            {uiText.readyToStartCollection}
          </Text>
        </Flex>
        <Image src="/receipt.webp" />
        <SecondaryButton
          text={uiText.startNewCollection}
          dark
          onClick={() => {
            navigate("create-collection");
            walletModal.onClose();
          }}
        />
      </Flex>
    </>
  );
};
