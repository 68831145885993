import {
  Box,
  Heading,
  Flex,
  Text,
  Tooltip,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";
import { useUiText } from "../../../system/hooks/useUiText";
import {
  APP_ERROR_COLOR,
  APP_IMAGE_PLACEHOLDER_COLOR,
  APP_PRIMARY_TEXT_COLOR,
  APP_SECONDARY_COLOR,
  APP_TEXT_INACTIVE_COLOR,
} from "../../../theme/colors";
import { MyReceiptsSectionProps } from "./types";
import { IoMdAlert } from "react-icons/io";
import { PrimaryButton } from "../../../components/buttons";
import React from "react";
import { LEGACYContractContext } from "../../../system/context/LEGACYContractContext";
import { scrollBarStyles } from "../../../theme/theme";

export const MyReceiptsSection = ({
  balance,
  insufficientFunds,
}: MyReceiptsSectionProps) => {
  const {
    mintToken,
    currentTokenBalance,
    tokenIds,
    tokenBalancePending,
    mintPrice,
  } = React.useContext(LEGACYContractContext);
  const { uiText } = useUiText();

  return (
    <Flex h="100%" flexDir={"column"} justifyContent={"space-between"}>
      <Box>
        <Box>
          <Heading fontSize={"14px"} fontWeight={"semibold"} mb={"16px"}>
            {uiText.balance}
          </Heading>
          <Flex alignItems="center"justifyContent="space-between">
            <Flex flexDir="row-reverse"justifyContent="space-evenly">
              <Text
                fontFamily={"body"}
                fontSize={"16px"}
                fontWeight={"regular"}
              >
                {uiText.ethereum}
              </Text>
              <Flex alignItems="center">
                <FaEthereum style={{ marginRight: 10 }} />
              </Flex>
            </Flex>
            <Tooltip
              label={uiText.insufficientFundsMessage}
              hasArrow
              bg={APP_ERROR_COLOR}
              isDisabled={!insufficientFunds}
            >
              <Flex
                justifyContent="center"
               alignItems="center"
                color={
                  insufficientFunds ? APP_ERROR_COLOR : APP_PRIMARY_TEXT_COLOR
                }
              >
                <Text
                  fontFamily={"body"}
                  fontSize={"16px"}
                  fontWeight={"regular"}
                  color={insufficientFunds ? APP_ERROR_COLOR : "#292727"}
                  cursor="pointer"
                  mr={1}
                >
                  {balance} {uiText.ethereumAbbr}
                </Text>
                {insufficientFunds && <IoMdAlert />}
              </Flex>
            </Tooltip>
          </Flex>
        </Box>
        {tokenBalancePending ? (
          <Flex h="100%">
            <Spinner m="auto" color={APP_SECONDARY_COLOR} />
          </Flex>
        ) : (
          <Box mt={"51px"}>
            <Heading fontSize={"14px"} fontWeight={"semibold"} mb={5}>
              {uiText.receipts} {`(${currentTokenBalance || 0})`}
            </Heading>
            <Flex alignItems="center"justifyContent="center" flexDir="column">
              {!currentTokenBalance && (
                <Text color={APP_TEXT_INACTIVE_COLOR} m="20px 0px 20px 0px">
                  {uiText.noReceiptMessage}
                </Text>
              )}
              {tokenIds && (
                <HStack
                  overflow="auto"
                  maxW="100%"
                  sx={scrollBarStyles}
                  paddingBottom={5}
                  pl="15px"
                >
                  {tokenIds.map((r, i) => (
                    <Flex
                      key={`receipt-${i}`}
                      flexDir="column"
                      _hover={{ opacity: 0.7, cursor: "pointer" }}
                    >
                      <Box
                        h={"166px"}
                        w={"131px"}
                        borderRadius="4px"
                        bgColor={APP_IMAGE_PLACEHOLDER_COLOR}
                        boxShadow="md"
                        mr="12px"
                      ></Box>
                    </Flex>
                  ))}
                </HStack>
              )}
            </Flex>
          </Box>
        )}
      </Box>
      <Flex flexDir="column"alignItems="center">
        <Text
          fontFamily={"heading"}
          fontSize={"12px"}
          fontWeight={"medium"}
          mb={"14px"}
        >
          {uiText.receiptCostMessage(mintPrice)}
        </Text>
        <PrimaryButton
          text={uiText.mintNewReceipt}
          onClick={() => mintToken(1)}
        />
      </Flex>
    </Flex>
  );
};
