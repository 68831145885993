import { Button, Flex, Image, Text, Link } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CollectorContext } from "../../system/context/CollectorContext";
import useDeviceDetect from "../../system/hooks/useDeviceDetect";
import { useUiText } from "../../system/hooks/useUiText";
import {
    APP_HEADER_BACKGROUND_COLOR,
    APP_PRIMARY_CONTRAST_COLOR,
} from "../../theme/colors";

export const AppFooter = () => {
    const { isMobile } = useDeviceDetect();
    const { uiText } = useUiText();
    const { isMintPage, content } = useContext(CollectorContext);
    const [socialLinks, setSocialLinks] =
        useState<Array<{ img: string; link: string }>>();

    const renderLinkButton = (imageSource: string, link: string) => {
        if (!link) {
            return <></>;
        }

        return (
            <Link href={link}>
                <Button
                    bgColor={APP_HEADER_BACKGROUND_COLOR}
                    _hover={{ opacity: "0.8" }}
                    mr={isMobile ? "3px" : "5px"}
                    p={0}
                    size={isMobile ? "xs" : "lg"}
                    as="a"
                >
                    <Image
                        src={imageSource}
                        h={isMobile ? "24px" : "34px"}
                        w={isMobile ? "20px" : "30px"}
                        objectFit={"contain"}
                    />
                </Button>
            </Link>
        );
    };

    useEffect(() => {
        if (content) {
            setSocialLinks([
                { img: "/discord-white.png", link: content.discord },
                { img: "/twitter-white.png", link: content.twitter },
                {
                    img: "/instagram-white.png",
                    link: content.instagram,
                },
                {
                    img: "/opensea-white.png",
                    link: content.opensea,
                },
            ]);
        }
    }, [content]);

    return !isMintPage ? (
        <Flex
            h="200px"
            background={`linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),  url(footer.webp)`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundColor={APP_HEADER_BACKGROUND_COLOR}
            justifyContent="center"
            alignItems="center"
        >
            <Image src="/omakaseasymbol.png" h="100px" />
        </Flex>
    ) : (
        <Flex
            h="80px"
            bgColor={APP_HEADER_BACKGROUND_COLOR}
            flexDir="row"
            justifyContent="space-between"
            alignItems={"center"}
            px={isMobile ? "25px" : "70px"}
        >
            <Flex>
                {!content?.removeBrandingMintPage && (
                    <>
                        <Image src="/omakaseasymbol.png" h="15px" mr="8px" />
                        <Text
                            fontFamily={"body"}
                            fontSize={"10px"}
                            color={APP_PRIMARY_CONTRAST_COLOR}
                        >
                            {uiText.createdWith}
                        </Text>
                    </>
                )}
            </Flex>
            <Flex>
                {socialLinks?.map((s, i) => (
                    <div key={i}>{renderLinkButton(s.img, s.link)}</div>
                ))}
            </Flex>
        </Flex>
    );
};
