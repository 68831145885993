import { ethers } from "ethers";
import React, { useState } from "react";
import {
  MASATOSHI_CONTRACT_ADDRESS,
  whitelistedAddresses,
} from "../../constants";
import { WalletContext } from "../../context/WalletContext";
import {
  MasterchefMasatoshi,
  MasterchefMasatoshiJunior,
  MasterchefMasatoshiV2,
} from "../../../contracts/abiTypes";
import { v4 } from "uuid";
import { useStorageState } from "react-storage-hooks";
import { EthereumRequests } from "./ethereumRequests";
import { generateSigAllowList } from "../../api/api";
import { CreateProposalFormType } from "../../../pages/DaoDashboard/CreateProposalForm";
import { parseEther } from "ethers/lib/utils";
import { Abi } from "../../../pages/DaoDashboard/types";
import { useAccount, useSigner } from "wagmi";

const masterchefMasatoshi_json = require("../../../contracts/MasterchefMasatoshi.json");
const masterchefMasatoshiV2_json = require("../../../contracts/MasterchefMasatoshi-v2.json");
const masterchefMasatoshiJunior_json = require("../../../contracts/MasterchefMasatoshiJunior.json");

export enum MintStatus {
  // premint - default
  premint = 0,

  // change when user enables allow list mint
  allow = 1,

  // change when user enables public mint
  public = 2,

  // changes automatically when last item is minted
  finished = 3,
}

export type Contract = {
  contract: ethers.Contract | undefined;
  hasReceipt: boolean;
  mintToken: (amount: number) => Promise<any>;
  preMint: (amount: number) => void;
  transactionPending: boolean;
  transactionSuccessful: boolean;
  transactionUnSuccessful: boolean;
  resetTransactionState: () => void;
  tokenIds: number[];
  enableMinting: () => void;
  saleIsActive: boolean;
  errorStatus: any;
  mintStatus: MintStatus;
  changeMintStatus: (status: MintStatus) => Promise<void>;
  allowListMintPrice: number;
  tokenBalancePending: boolean;
  currentTokenBalance: number;
  withDrawEth: (address: string) => Promise<any>;
  getTotalSupply: () => void;
  totalSupply: number;
  revealCollection: (uri: string) => Promise<any>;
  baseUri: string;
  setProvenance: (provenanceHash: string) => void;
  provenance: string;
  initializing: boolean;
  isLegacy: boolean;
  mintPrice: number;
  maxTotalSupply: number;
  validation?: string;
  init: () => Promise<any>;
  contractError: boolean;
  maxAllowedMints: number;
  contractBalance: number;
  getAllVotings: () => Promise<any>;
  getName: () => Promise<any>;
  votings: any[];
  name: string;
  percentToVote: number;
  signVoting: (index: number) => Promise<any>;
  activateVoting: (index: number) => Promise<any>;
  convertToDao: () => Promise<any>;
  getIsDao: () => Promise<any>;
  isConvertedToDao: boolean;
  createVoting: (values: CreateProposalFormType, abi: Abi) => Promise<any>;
};

/**
 * Hook that returns contract variables and methods for interacting with contracts.
 * @param contractAddress   Optional parameter. Defaults to masterchef contract if not used.
 */
export const useContract = (
  contractAddress?: string,
  isDao?: boolean
): Contract => {
  const signer = useSigner().data as ethers.Signer;
  const { account } = React.useContext(WalletContext);
  const { address } = useAccount();
  const [contract, setContract] = React.useState<
    MasterchefMasatoshiV2 | MasterchefMasatoshiJunior | MasterchefMasatoshi
  >();
  const [currentTokenBalance, setCurrentTokenBalance] = React.useState<number>(
    undefined as unknown as number
  );
  const [isWhitelistedAddress, setIsWhitelistedAddress] = React.useState(false);
  const [totalSupply, setTotalSupply] = React.useState<number>(
    undefined as unknown as number
  );
  const [transactionPending, setTransactionPending] = React.useState(false);
  const [transactionSuccessful, setTransactionSuccessful] =
    React.useState(false);
  const [transactionUnSuccessful, setTransactionUnSuccessful] =
    React.useState(false);
  const [tokenIds, setTokenIds] = React.useState<number[]>([]);
  const [contractBalance, setContractBalance] = React.useState<number>(0);
  const [saleIsActive, setSaleIsActive] = React.useState<boolean>(false);
  const [mintStatus, setMintStatus] = React.useState<MintStatus>(
    undefined as unknown as number
  );
  const [currentBaseUri, setCurrentBaseUri] = React.useState<string>(
    undefined as unknown as string
  );
  const [currentProvenance, setCurrentProvenance] = React.useState<string>(
    undefined as unknown as string
  );

  const [tokenBalancePending, setTokenBalancePending] =
    React.useState<boolean>(false);
  const [initializing, setInitializing] = React.useState(false);
  const [isLegacy, setIsLegacy] = React.useState(false);
  const [mintPrice, setMintPrice] = React.useState<number>(0);
  const [allowListPrice, setAllowListPrice] = React.useState<number>(0);
  const [maxTotalSupply, setMaxTotalSupply] = React.useState<0>();
  const [maxAllowedMints, setMaxAllowedMints] = React.useState<0>(0);
  const [errorStatus, setErrorStatus] = useState("");
  const [validation, setValidation] = useStorageState<string>(
    localStorage,
    "mintValidation",
    ""
  );
  const [contractError, setContractError] = useState(false);
  const [votings, setVotings] = React.useState<
    MasterchefMasatoshi.VotingStructOutput[]
  >([]);
  const [name, setName] = React.useState("");
  const [percentToVote, setPercentToVote] = React.useState(0);
  const [isConvertedToDao, setIsConvertedToDao] = React.useState(false);

  const getTokenBalance = async (
    contract: ethers.Contract,
    account: string
  ) => {
    setTokenBalancePending(true);
    if (contract && account)
      for (let address of whitelistedAddresses) {
        if (account === address) {
          setIsWhitelistedAddress(true);
        }
      }
    await contract
      .balanceOf(account)
      .then((b: any) => {
        setCurrentTokenBalance(b.toNumber());
      })
      .catch((err: any) => console.log("getTokenBalance", err));
  };

  const getContractBalance = async () => {
    if (!contractAddress || !ethers) return;
    const ethereum = window.ethereum as any;
    const provider = new ethers.providers.Web3Provider(ethereum as any, "any");
    const requests = new EthereumRequests(provider);
    const balance = await requests
      .getBalance(contractAddress)
      .then((balance) => {
        const value = parseInt(balance) / Math.pow(10, 18) || 0;
        return value;
      });
    setContractBalance(balance);
    return balance;
  };

  const getTotalSupply = async () => {
    if (contract) {
      await contract
        .totalSupply()
        .then((b: any) => {
          setTotalSupply(b.toNumber());
        })
        .catch((err: any) => console.log("getTotalSupply", err));
    }
  };

  const getBaseUri = async (contract: ethers.Contract) => {
    if (contract)
      await contract
        .baseURI()
        .then((b: any) => {
          setCurrentBaseUri(b);
        })
        .catch((err: any) => console.log("getBaseUri", err));
  };

  const getProvenance = async () => {
    if (contract)
      await contract
        .PROVENANCE()
        .then((p: any) => {
          setCurrentProvenance(p);
        })
        .catch((err: any) => console.log("provenance", err));
  };

  const getTokenIds = async (tokenBalance: number) => {
    setTokenBalancePending(true);

    if (tokenBalance && account && contract) {
      let ids: number[] = [];
      for (let i = 0; i < tokenBalance; i++) {
        await contract
          .tokenOfOwnerByIndex(account, i)
          .then((id: any) => ids.push(id.toNumber()))
          .then(() => setTokenIds(ids))
          .catch((err: any) => {
            setTokenIds([]);
            console.log("getReceipts", err);
          });
      }
    }
    setTokenBalancePending(false);
  };

  const getSalesState = async () => {
    let currentContract = contract as MasterchefMasatoshiV2;
    await currentContract
      ?.paused()
      .then((isPaused) => {
        setSaleIsActive(!isPaused);
      })
      .catch((err: any) => console.log("getSalesState", err));
  };

  const getMintStatus = async () => {
    let currentContract = contract as MasterchefMasatoshiV2;

    await currentContract
      ?.mintStatus()
      .then((status) => {
        setMintStatus(status);
      })
      .catch((err: any) => console.log("mintStatus", err));
  };

  const getMintPrice = async () => {
    await contract
      ?.mintPrice()
      .then((p: any) => {
        const price = parseInt(p) / Math.pow(10, 18) || 0;
        setMintPrice(price);
      })
      .catch((err: any) => console.log("getMintPrice", err));
  };

  const getMaxTotalSupply = async () => {
    await contract
      ?.maxPossibleSupply()
      .then((s: any) => {
        setMaxTotalSupply(s.toNumber());
      })
      .catch((err: any) => console.log("getMaxTotalSupply", err));
  };

  const getAllowListPrice = async () => {
    let currentContract = contract as MasterchefMasatoshiJunior;
    await currentContract
      ?.allowListMintPrice()
      .then((s: any) => {
        const price = parseInt(s) / Math.pow(10, 18) || 0;
        setAllowListPrice(price);
      })
      .catch((err: any) => console.log("allowListMintPrice", err));
  };

  const getMaxAllowedMints = async () => {
    let currentContract = contract as MasterchefMasatoshiJunior;

    await currentContract
      ?.maxAllowedMints()
      .then((m: any) => {
        setMaxAllowedMints(m.toNumber());
      })
      .catch((err: any) => console.log("maxAllowedMints", err));
  };

  const getAllVotings = async () => {
    if (contract) {
      let currentContract = contract as MasterchefMasatoshi;

      await currentContract
        .getAllVotings()
        .then((votings) => {
          setVotings(votings);
        })
        .catch((err) => console.log("getAllVotings", err));
    }
  };

  const getName = async () => {
    if (contract) {
      contract
        .name()
        .then((name) => {
          setName(name);
        })
        .catch((err) => console.log("getName", err));
    }
  };

  const getPercentToVote = async () => {
    let currentContract = contract as MasterchefMasatoshi;
    if (contract) {
      currentContract
        .percentToVote()
        .then((percentToVote) => {
          setPercentToVote(percentToVote.toNumber());
        })
        .catch((err) => console.log("getPercentToVote", err));
    }
  };

  const getIsDao = async () => {
    let currentContract = contract as MasterchefMasatoshi;
    if (contract) {
      currentContract
        .isDao()
        .then((isDao) => {
          setIsConvertedToDao(isDao);
        })
        .catch((err) => console.log("getPercentToVote", err));
    }
  };

  const resetTransactionState = () => {
    setTransactionSuccessful(false);
    setTransactionUnSuccessful(false);
  };

  const init = async () => {
    if (!contract) return;

    const totalSupply = await contract
      .totalSupply()
      .then()
      .catch(() => null);

    const canQueryContract = !!totalSupply;

    if (!canQueryContract) {
      setContractError(true);
    }

    let currentContract = contract as MasterchefMasatoshiJunior;

    const status = await currentContract
      ?.mintStatus()
      .then((status) => {
        return status;
      })
      .catch((err: any) => console.log("mintStatus", err));

    const isLegacyContract = status === undefined;
    setIsLegacy(isLegacyContract);

    if (isLegacyContract) {
      setInitializing(false);
      return;
    }

    if (isDao) {
      setInitializing(true);
      await Promise.all([getAllVotings(), getName(), getPercentToVote()])
        .catch((err) => console.log(err))
        .finally(() => setInitializing(false));
      return;
    }

    setInitializing(true);
    await Promise.all([
      getTokenIds(currentTokenBalance),
      getAllowListPrice(),
      getMintPrice(),
      getSalesState(),
      getTotalSupply(),
      getContractBalance(),
      getBaseUri(contract),
      getProvenance(),
      getMaxTotalSupply(),
      getMintStatus(),
      getMaxAllowedMints(),
    ])
      .catch((err) => console.log(err))
      .finally(() => setInitializing(false));
  };

  React.useEffect(() => {
    if (signer && address && !isDao) {
      const contract = new ethers.Contract(
        contractAddress || MASATOSHI_CONTRACT_ADDRESS!,
        masterchefMasatoshiV2_json["abi"],
        signer
      );
      setContract(contract as MasterchefMasatoshiV2);
      getTokenBalance(contract!, address);
    }

    if (account && isDao && contractAddress) {
      const dao = new ethers.Contract(
        contractAddress,
        masterchefMasatoshiV2_json["abi"],
        signer
      ) as MasterchefMasatoshiV2;

      setContract(dao as MasterchefMasatoshiV2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, account, contractAddress, address]);

  React.useEffect(() => {
    if (transactionSuccessful && contract && account) {
      getTokenBalance(contract, account); // TODO - Might be able to remove this...not sure what needs this to happen. (RF)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSuccessful, contract, account]);

  React.useEffect(() => {
    if (contract) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, contractAddress]);

  const mintToken = async (amount: number) => {
    if (mintStatus === 2) {
      setTokenBalancePending(true);
      const price = await contract?.mintPrice();

      if (price && account) {
        let currentContract = contract as MasterchefMasatoshiJunior;
        await currentContract
          ?.mintPublic(amount, { value: price.mul(amount.toString()) })
          .then(() => {
            setTransactionPending(true);

            let tokenBalance: number;

            const pollTransactionState = setInterval(() => {
              contract?.balanceOf(account).then((b: any) => {
                tokenBalance = b.toNumber();
              });
              if (tokenBalance === currentTokenBalance + amount) {
                clearInterval(pollTransactionState);
                getTotalSupply();
                setCurrentTokenBalance(tokenBalance);
                setTransactionPending(false);
                setTransactionSuccessful(true);
              }
            }, 5000);
          })
          .catch((err: any) => {
            if (err.code === 4001) {
              setErrorStatus("transaction rejected");
              setTransactionPending(false);
              return;
            }
            console.log("mintToken", err.error.message);
            setErrorStatus(err.error.message);
            setTransactionUnSuccessful(true);
          });
      }
    }

    if (mintStatus === 1) {
      mintAllowList(amount);
    }
  };

  const mintAllowList = async (amount: number) => {
    let currentContract = contract as MasterchefMasatoshiJunior;

    const allowList = await currentContract?.allowListMintPrice();

    const id = v4();
    const message = `${id}`;
    const result = await signer.signMessage(message);

    if (contract?.address) {
      setValidation(
        JSON.stringify({
          message,
          result,
          id,
        })
      );
      generateSigAllowList(
        contract?.address,
        JSON.stringify({
          message,
          result,
          id,
        }),
        () => null,
        () => null
      )
        .then(async (data) => {
          if (allowList && account) {
            let currentContract = contract as MasterchefMasatoshiJunior;

            await currentContract
              ?.mintAllowList(data.messageHash, data.signature, amount, {
                value: allowList.mul(amount.toString()),
              })
              .then(() => {
                setTransactionPending(true);

                let tokenBalance: number;

                const pollTransactionState = setInterval(() => {
                  contract?.balanceOf(account).then((b: any) => {
                    tokenBalance = b.toNumber();
                  });
                  if (tokenBalance === currentTokenBalance + amount) {
                    clearInterval(pollTransactionState);
                    getTotalSupply();
                    setCurrentTokenBalance(tokenBalance);
                    setTransactionPending(false);
                    setTransactionSuccessful(true);
                  }
                }, 5000);
              })
              .catch((err: { code: number; message: string; error: any }) => {
                if (err.code === 4001) {
                  setErrorStatus("transaction rejected");
                  setTransactionPending(false);
                  return;
                }
                console.log("mintToken", err);
                setErrorStatus(err?.error?.message);

                setTransactionUnSuccessful(true);
              });
          }
        })
        .catch((err) => {
          console.log(err?.response);
          setTransactionUnSuccessful(true);
          setErrorStatus(err?.response?.data?.error);
        });
    }
  };

  const preMint = async (amount: number) => {
    if (account) {
      let licenceContract = contract as MasterchefMasatoshiV2;
      await licenceContract
        ?.preMint(amount)
        .then(() => {
          setTransactionPending(true);

          let tokenBalance: number;

          const pollTransactionState = setInterval(() => {
            contract?.balanceOf(account).then((b: any) => {
              tokenBalance = b.toNumber();
            });

            if (tokenBalance === currentTokenBalance + amount) {
              clearInterval(pollTransactionState);
              setCurrentTokenBalance(tokenBalance);
              setTransactionPending(false);
              setTransactionSuccessful(true);
            }
          }, 5000);
        })
        .catch((err: { code: number }) => {
          if (err.code === 4001) {
            setTransactionPending(false);
            return;
          }
          setTransactionUnSuccessful(true);
        });
    }
  };

  const enableMinting = async () => {
    const currentContract = new ethers.Contract(
      contractAddress || MASATOSHI_CONTRACT_ADDRESS!,
      masterchefMasatoshiV2_json["abi"],
      signer
    ) as MasterchefMasatoshiJunior;

    await currentContract
      ?.flipPaused()
      .then(() => {
        setTransactionPending(true);

        let saleState: boolean;

        const pollSaleState = setInterval(() => {
          currentContract?.paused().then((isPaused) => (saleState = !isPaused));

          if (saleState === !saleIsActive) {
            clearInterval(pollSaleState);
            setSaleIsActive(saleState);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const changeStatus = async (newStatus: MintStatus) => {
    let currentContract = contract as MasterchefMasatoshiJunior;

    await currentContract
      ?.changeMintStatus(newStatus)
      .then(() => {
        setTransactionPending(true);

        let status: MintStatus;

        const pollSaleState = setInterval(() => {
          currentContract?.mintStatus().then((m) => (status = m));

          if (status === newStatus) {
            clearInterval(pollSaleState);
            setMintStatus(status);
            setTransactionPending(false);
            setTransactionSuccessful(true);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const revealCollection = async (baseUri: string) => {
    await contract
      ?.setBaseURI(baseUri)
      .then(() => {
        setTransactionPending(true);
        let newUri: string;
        const pollBaseUriState = setInterval(() => {
          contract?.baseURI().then((uri: string) => (newUri = uri));
          if (newUri === baseUri) {
            clearInterval(pollBaseUriState);
            setCurrentBaseUri(baseUri);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        console.log("revealCollection", err);
        setTransactionUnSuccessful(true);
      });
  };

  const withDrawEth = async (address: string) => {
    const contractWithWithdraw = new ethers.Contract(
      address,
      masterchefMasatoshiJunior_json["abi"],
      signer
    );

    await contractWithWithdraw
      ?.withdraw()
      .then(() => {
        setTransactionPending(true);
        let balance: number;
        const pollSaleState = setInterval(() => {
          const ethereum = window.ethereum as any;
          const provider = new ethers.providers.Web3Provider(
            ethereum as any,
            "any"
          );
          const requests = new EthereumRequests(provider);
          requests.getBalance(address).then((b) => {
            const value = parseInt(b) / Math.pow(10, 18) || 0;
            balance = value;
          });
          if (balance === 0) {
            clearInterval(pollSaleState);
            setContractBalance(0);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          setTransactionPending(false);
          return;
        }
        setTransactionUnSuccessful(true);
      });
  };

  const setProvenance = async (provenanceHash: string) => {
    await contract
      ?.setProvenanceHash(provenanceHash)
      .then(() => {
        setTransactionPending(true);

        let provenance: string;

        const pollProvenanceState = setInterval(() => {
          contract
            .PROVENANCE()
            .then((p: any) => {
              provenance = p;
            })
            .catch((err: any) => console.log(err));
          if (!!provenance) {
            clearInterval(pollProvenanceState);
            setCurrentProvenance(provenance);
            setTransactionPending(false);
          }
        }, 5000);
      })
      .catch((err: { code: number }) => {
        if (err.code === 4001) {
          console.log("setProvenance", err);
          setTransactionPending(false);
          return;
        }
        console.log("setProvenance", err);
        setTransactionUnSuccessful(true);
      });
  };

  const signVoting = async (index: number) => {
    let currentContract =
      contractAddress &&
      (new ethers.Contract(
        contractAddress,
        masterchefMasatoshi_json["abi"],
        signer
      ) as MasterchefMasatoshi);

    if (!currentContract) return;
    setTransactionPending(true);
    if (contract) {
      currentContract
        .signVoting(index)
        .then(() => {
          setTransactionPending(true);

          let newVoting: MasterchefMasatoshi.VotingStructOutput;
          let newVotings: MasterchefMasatoshi.VotingStructOutput[];
          const dao =
            contractAddress &&
            (new ethers.Contract(
              contractAddress,
              masterchefMasatoshi_json["abi"],
              signer
            ) as MasterchefMasatoshi);
          if (!dao) return;

          const pollVotingsState = setInterval(() => {
            // Get all votings.
            dao
              .getAllVotings()
              .then((allVotings) => {
                const currentVoting = allVotings.find(
                  (a) => a.index.toNumber() === index
                );
                if (!currentVoting?.length) return;
                newVoting = currentVoting;
                newVotings = allVotings;
              })
              .catch((err: any) => console.log(err));

            // Find the current element being signed.
            const votingNow = votings.find((a) => a.index.toNumber() === index);

            // If the voting signers has increased operation is done and ui can be updated.
            if (
              newVoting?.signers?.length >
              (votingNow ? votingNow?.signers.length : 0)
            ) {
              clearInterval(pollVotingsState);
              setVotings(newVotings);
              setTransactionPending(false);
            }
          }, 5000);
        })
        .catch((err: any) => {
          console.log("signVoting", err);
          setTransactionUnSuccessful(true);
          setTransactionPending(false);
        });
    }
  };

  const activateVoting = async (index: number) => {
    let currentContract =
      contractAddress &&
      (new ethers.Contract(
        contractAddress,
        masterchefMasatoshi_json["abi"],
        signer
      ) as MasterchefMasatoshi);

    if (!currentContract) return;
    setTransactionPending(true);
    if (contract) {
      currentContract
        .activateVoting(index)
        .then(() => {
          setTransactionPending(true);

          let newVoting: MasterchefMasatoshi.VotingStructOutput;
          let newVotings: MasterchefMasatoshi.VotingStructOutput[];

          const pollVotingsState = setInterval(() => {
            if (!currentContract) return;

            // Get all votings.
            currentContract
              .getAllVotings()
              .then((allVotings) => {
                const currentVoting = allVotings.find(
                  (a) => a.index.toNumber() === index
                );
                if (!currentVoting?.length) return;
                newVoting = currentVoting;
                newVotings = allVotings;
              })
              .catch((err: any) => console.log(err));

            // If the voting isActivated property is true the operation is done and ui can be updated.
            if (newVoting?.isActivated) {
              clearInterval(pollVotingsState);
              setVotings(newVotings);
              setTransactionPending(false);
            }
          }, 5000);
        })
        .catch((err: any) => {
          console.log("activateVoting", err);
          setTransactionPending(false);
          setTransactionUnSuccessful(true);
        });
    }
  };

  const convertToDao = async () => {
    let currentContract =
      contractAddress &&
      (new ethers.Contract(
        contractAddress,
        masterchefMasatoshi_json["abi"],
        signer
      ) as MasterchefMasatoshi);

    if (!currentContract) return;
    setTransactionPending(true);
    if (contract) {
      currentContract
        .permanentlyConvertToDao()
        .then(() => {
          setTransactionPending(false);
          setTransactionSuccessful(true);
        })
        .catch((err: any) => {
          console.log("convertToDao", err);
          setTransactionPending(false);
          setTransactionUnSuccessful(true);
        });
    }
  };

  const createVoting = async (values: CreateProposalFormType, abi: Abi) => {
    setTransactionPending(true);
    const nftInterface = new ethers.utils.Interface(abi);
    // Encode function data (function params in array format)
    const mintFunctionData = nftInterface.encodeFunctionData(
      values.function,
      values.params
    );

    if (account && signer && contractAddress) {
      const dao = new ethers.Contract(
        contractAddress,
        masterchefMasatoshi_json["abi"],
        signer
      ) as MasterchefMasatoshi;

      await dao
        .createVoting(
          contractAddress,
          mintFunctionData,
          parseEther(values.transactionValue || "0"),
          values.title
        )
        .then(() => {
          setTransactionPending(true);

          let newVotings: any[] = [];

          const pollVotingsState = setInterval(() => {
            dao
              .getAllVotings()
              .then((v: any[]) => {
                newVotings = v;
              })
              .catch((err: any) => console.log(err));
            if (newVotings.length > votings.length) {
              clearInterval(pollVotingsState);
              setVotings(newVotings);
              setTransactionPending(false);
              setTransactionSuccessful(true);
            }
          }, 5000);
        })
        .catch((err) => {
          console.log("createVoting", err);
          setTransactionUnSuccessful(true);
          setTransactionPending(false);
        });
    }
  };

  return {
    errorStatus,
    baseUri: currentBaseUri,
    contract,
    currentTokenBalance,
    enableMinting,
    getTotalSupply,
    hasReceipt: !!currentTokenBalance || !!isWhitelistedAddress,
    initializing,
    maxTotalSupply: maxTotalSupply || 0,
    mintPrice,
    mintToken,
    mintStatus,
    preMint,
    provenance: currentProvenance,
    resetTransactionState,
    revealCollection,
    saleIsActive,
    setProvenance,
    tokenBalancePending,
    tokenIds,
    totalSupply,
    transactionPending,
    transactionSuccessful,
    transactionUnSuccessful,
    init,
    withDrawEth,
    changeMintStatus: changeStatus,
    allowListMintPrice: allowListPrice,
    isLegacy,
    validation,
    contractError,
    maxAllowedMints,
    contractBalance,
    getAllVotings,
    getName,
    votings,
    name,
    percentToVote,
    signVoting,
    activateVoting,
    isConvertedToDao,
    convertToDao,
    getIsDao,
    createVoting,
  };
};
