export const APP_HEADER_BACKGROUND_COLOR = "appHeaderBackgroundColor";
export const APP_HEADER_TEXT_COLOR = "appHeaderTextColor";
export const APP_PRIMARY_COLOR = "appPrimaryColor";
export const APP_PRIMARY_CONTRAST_COLOR = "appPrimaryContrastColor";
export const APP_PRIMARY_BUTTON_TEXT = "appPrimaryButtonTextColor";
export const APP_SECONDARY_COLOR = "appSecondaryColor";
export const APP_SECONDARY_COLOR_DARK = "appSecondaryColorDark";
export const APP_PRIMARY_TEXT_COLOR = "appPrimaryTextColor";
export const APP_DIVIDER_COLOR = "appDividerColor";
export const APP_DESCRIPTION_COLOR = "appDescriptionColor";
export const APP_TEXT_INACTIVE_COLOR = "appTextInactiveColor";
export const APP_BORDER_COLOR_DARK = "appBorderColorDark";
export const APP_ERROR_COLOR = "appErrorColor";
export const APP_INFO_BACKGROUND_COLOR = "appInfoBackgroundColor";
export const APP_TAB_LIST_SELECTED_COLOR = "appTabListSelectedColor";
export const WALLET_MODAL_HEADER_BG_COLOR = "walletModalHeaderBackgroundColor";
export const HOMEPAGE_BACKGROUND_COLOR = "homePageBackgroundColor";
export const WALLET_MODAL_BODY_BG_COLOR = "walletModalBodyBackgroundColor";
export const APP_IMAGE_PLACEHOLDER_COLOR = "appImagePlaceholderColor";
export const WALLET_MODAL_ALT_BG_COLOR = "walletModalAltBackgroundColor";
export const APP_WARNING_COLOR = "appWarningColor";
export const BUTTON_BORDER_COLOR = "buttonBorderColor";
export const APP_SECONDARY_NEON_COLOR = "appSecondaryNeonColor";
export const DELETE_BUTTON_COLOR = "deleteButtonColor";
export const FILE_ERROR_COLOR = "fileErrorColor";
export const DESCRIPTION_COLOR = "descriptionColor";
export const COLLECTOR_BORDER_COLOR = "collectorBorderColor";
export const UPLOAD_BOX_TEXT_COLOR = "uploadBoxTextColor";
export const COLLECTION_IMAGE_BACKGROUND = "collectionImageBackground";
export const COLLECTION_IMAGE_TEXT = "collectionImageText";
