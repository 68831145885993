import { ethers } from "ethers";
import React from "react";
import {
  Contract,
  useContract,
} from "../../../system/hooks/ethereum/useContract";

interface IContractContext extends Contract {}

export const MintPageContractContext = React.createContext<IContractContext>({
  errorStatus: "",
  isLegacy: false,
  contract: {} as ethers.Contract,
  hasReceipt: false,
  mintToken: (amount: number) => Promise.resolve({} as any),
  preMint: (amount: number) => null,
  transactionPending: false,
  transactionSuccessful: false,
  transactionUnSuccessful: false,
  resetTransactionState: () => null,
  tokenIds: [],
  enableMinting: () => null,
  saleIsActive: false,
  tokenBalancePending: false,
  currentTokenBalance: 0,
  withDrawEth: (address: string) =>
    Promise.resolve({} as any),
  totalSupply: 0,
  mintStatus: undefined as unknown as number,
  changeMintStatus: () => Promise.resolve(0 as any),
  allowListMintPrice: 0,
  getTotalSupply: () => null,
  revealCollection: (uri: string) => Promise.resolve("" as any),
  baseUri: undefined as unknown as string,
  setProvenance: (provenanceHash: string) => null,
  provenance: undefined as unknown as string,
  initializing: false,
  mintPrice: undefined as unknown as number,
  maxTotalSupply: 0,
  // eslint-disable-next-line
  init: () => Promise.resolve({} as any),
  contractError: false,
  maxAllowedMints: 0,
  contractBalance: 0,
  getAllVotings: () => Promise.resolve({} as any),
  getName: () => Promise.resolve({} as any),
  votings: [],
  name: "",
  percentToVote: 0,
  signVoting: (index: number) => Promise.resolve({} as any),
  activateVoting: (index: number) => Promise.resolve({} as any),
  convertToDao: () => Promise.resolve({} as any),
  getIsDao: () => Promise.resolve({} as any),
  createVoting: () => Promise.resolve({} as any),
  isConvertedToDao: false,
});

export const MintPageContractProvider: React.FC<{children: any,
  contractAddress: string;
}> = ({ children, contractAddress }) => {
  const contract = useContract(contractAddress);

  return (
    <MintPageContractContext.Provider
      value={{ ...contract, isLegacy: contract.isLegacy }}
    >
      {children}
    </MintPageContractContext.Provider>
  );
};
