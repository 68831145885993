import { ApiCollection, Collection, MintPageContent } from "./types";
import moment from "moment";

export const mapApiCollectionToCollection = (
  apiCollection: ApiCollection
): Collection => {
  return {
    id: apiCollection._id,
    symbol: apiCollection.symbol,
    name: apiCollection.collectionName,
    collectionImage: apiCollection.imgBaseUrl
      ? `${convertIpfsToIpfsUrl(apiCollection.imgBaseUrl)}/0`
      : "",
    contractAddress: apiCollection.contractAddress,
    contractType: apiCollection.contractType,
    creatorAddress: apiCollection.creatorAddress,
    contractBalance: "0",
    dateCreated: moment(apiCollection.date).format(),
    total: apiCollection.maxSupply,
    cost: apiCollection.price,
    status: apiCollection.status,
    deployStatus: apiCollection.contractAddress ? "DEPLOYED" : "NOTDEPLOYED",
    metaDataBaseUrl: apiCollection.metaDataBaseUrl,
    provenanceHash: apiCollection.provenanceHash,
    mintPageImgBaseUrl: apiCollection.mintPageImgBaseUrl,
    mintPageDesktopImageUrl: apiCollection.mintPageDesktopImageUrl
      ? convertIpfsUrl(apiCollection.mintPageDesktopImageUrl)
      : "",
    mintPageMobileImageUrl: apiCollection.mintPageMobileImageUrl
      ? convertIpfsUrl(apiCollection.mintPageMobileImageUrl)
      : "",
    network: apiCollection.network,
    discord: apiCollection.discord,
    instagram: apiCollection.instagram,
    opensea: apiCollection.opensea,
    telegram: apiCollection.telegram,
    twitter: apiCollection.twitter,
    allowListMintPrice: apiCollection.allowListMintPrice,
    maxAllowedMints: apiCollection.maxAllowedMints,
    allowListWallets: apiCollection.allowListWallets,
    revealOnMint: apiCollection.revealOnMint,
    tempImageUrl:
      apiCollection.tempImageUrl &&
      convertIpfsToIpfsUrl(apiCollection.tempImageUrl),
  };
};

export const mintPageContentMapper = (mintPageContent: MintPageContent) => {
  if (!mintPageContent) return;
  return {
    mintPageDesktopImageUrl: mintPageContent.mintPageDesktopImageUrl
      ? convertIpfsUrl(mintPageContent.mintPageDesktopImageUrl)
      : "",
    mintPageMobileImageUrl: mintPageContent.mintPageMobileImageUrl
      ? convertIpfsUrl(mintPageContent.mintPageMobileImageUrl)
      : "",
    discord: mintPageContent.discord,
    instagram: mintPageContent.instagram,
    opensea: mintPageContent.opensea,
    telegram: mintPageContent.telegram,
    twitter: mintPageContent.twitter,
    removeBrandingMintPage: mintPageContent.removeBrandingMintPage,
    revealOnMint: mintPageContent.revealOnMint,
  };
};

const convertIpfsUrl = (url: string) => {
  const id = url.split("//")[1];
  const imageUrl = `https://hightable.mypinata.cloud/ipfs/${id}`;
  return imageUrl;
};

export const convertIpfsToIpfsUrl = (url: string) => {
  if (url.includes("hightable")) return url;
  const id = url.split("//")[1];
  const imageUrl = `https://ipfs.io//ipfs/${id}`;
  return imageUrl;
};
