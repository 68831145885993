import { createContext } from "react";
import {
  CollectorControllerType,
  useCollectorController,
} from "../hooks/useCollectorController";

interface ICollectorContext extends CollectorControllerType {}

export const CollectorContext = createContext<ICollectorContext>(
  {} as ICollectorContext
);

export const CollectorProvider: React.FC<{children: any}> = ({ children }) => {
  const collectorController = useCollectorController();

  return (
    <CollectorContext.Provider value={{ ...collectorController }}>
      {children}
    </CollectorContext.Provider>
  );
};
